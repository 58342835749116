import React from "react";
import "./FAQ.css";
import { Link } from "react-router-dom";

//logos
import GetHelp_PerApp from "../assets/GetHelp_PerApp.svg";
import GetHelp_PerBusinessType from "../assets/GetHelp_PerBusinessType.svg";
import GetHelp_PerUser from "../assets/GetHelp_PerUser.svg";
import { useTranslation } from "react-i18next";

import FAQperAPP from "../assets/FAQperAPP.png";
import fAQperBUsiness from "../assets/fAQperBUsiness.png";
import FAQperUSer from "../assets/FAQperUSer.png";

function FAQ() {
  const { t } = useTranslation();

  const FAQs = [
    {
      topic: t("faqPerApp"),
      logo: FAQperAPP,
      link: "/get-help/faq-app",
    },
    {
      topic: t("faqPerBusiness"),
      logo: fAQperBUsiness,
      link: "/get-help/faq-app",
    },
    {
      topic: t("faqPerUser"),
      logo: FAQperUSer,
      link: "/get-help/faq-app",
    },
  ];
  return (
    <div className="FAQ bg-white">
      <span className="font-[Raleway] font-[700] text-[#00215D] text-[35px]">
        {t("Topics")}
      </span>
      <div
        className=" grid 
      grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-4"
      >
        {FAQs.map((faq) => {
          return (
            <div className="mt-4" key={faq.topic}>
              <Link
                to={faq.link}
                className="h-[75px] bg-[#F6F6FF] w-full rounded-md shadow-md flex items-center no-underline"
              >
                <div className="pl-4">
                  <img
                    src={faq.logo}
                    alt={faq.topic}
                    className="h-[45px] w-auto"
                  />
                </div>
                <h6 className="faq-content-topic m-0 pl-4 font-[Raleway] text-[17px] text-[#00215D] no-underline font-[700]">
                  {faq.topic}
                </h6>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FAQ;
