import React from "react";
import cx from "clsx";
import { useTranslation } from "react-i18next";

import addienas from "../assets/trusted_by_clients/addienas.png";
import arjunafarm from "../assets/trusted_by_clients/arjunafarm.png";
import asianaMarket1 from "../assets/trusted_by_clients/asianaMarket 1.png";
import bakso_klangenan from "../assets/trusted_by_clients/bakso_klangenan.png";
import bostajir from "../assets/trusted_by_clients/bostajir.png";
import botanika from "../assets/trusted_by_clients/botanika.png";
import boudika from "../assets/trusted_by_clients/boudika.png";
import buna from "../assets/trusted_by_clients/buna.png";
import chinooks from "../assets/trusted_by_clients/chinooks.png";
import curva from "../assets/trusted_by_clients/curva.png";
import drolop from "../assets/trusted_by_clients/drolop.png";
import econsave from "../assets/trusted_by_clients/econsave.png";
import expose from "../assets/trusted_by_clients/expose.png";
import fixitpro from "../assets/trusted_by_clients/fixitpro.png";
import goldenBamboo from "../assets/trusted_by_clients/goldenBamboo.png";
import jayamart from "../assets/trusted_by_clients/jayamart.png";
import jiWoon from "../assets/trusted_by_clients/ji-woon.png";
import kapetanCafe from "../assets/trusted_by_clients/kapetanCafe.png";
import kgo from "../assets/trusted_by_clients/kgo.png";
import koki from "../assets/trusted_by_clients/koki.png";
import kris from "../assets/trusted_by_clients/kris.png";
import limited from "../assets/trusted_by_clients/limited.png";
import luar from "../assets/trusted_by_clients/luar.png";
import min from "../assets/trusted_by_clients/min.png";
import moss_manila_home from "../assets/trusted_by_clients/moss_manila_home.png";
import mypetshome from "../assets/trusted_by_clients/mypetshome.png";
import nestmax from "../assets/trusted_by_clients/nestmax.png";
import noobar from "../assets/trusted_by_clients/noobar.png";
import nsgr_global from "../assets/trusted_by_clients/nsgr_global.png";
import partspro from "../assets/trusted_by_clients/partspro.png";
import punjabitadka from "../assets/trusted_by_clients/punjabitadka.png";
import saung_pananjung from "../assets/trusted_by_clients/saung_pananjung.png";
import siakap_menangis from "../assets/trusted_by_clients/siakap_menangis.png";
import starlight from "../assets/trusted_by_clients/starlight.png";
import surge from "../assets/trusted_by_clients/surge.png";
import takoyakiDon from "../assets/trusted_by_clients/takoyakiDon.png";
import tenang_technologi from "../assets/trusted_by_clients/tenang_technologi.png";
import tetsujin from "../assets/trusted_by_clients/tetsujin.png";
import vaporit from "../assets/trusted_by_clients/vaporit.png";
import wartel from "../assets/trusted_by_clients/wartel.png";
import zapa from "../assets/trusted_by_clients/zapa.png";

const clientsLogos = [
  curva,
  buna,
  luar,
  takoyakiDon,
  econsave,
  kapetanCafe,
  punjabitadka,
  botanika,
  surge,
  vaporit,
  tetsujin,
  noobar,
  nestmax,
  chinooks,
  drolop,
  starlight,
  goldenBamboo,
  zapa,
  kgo,
  boudika,
  expose,
  asianaMarket1,
  wartel,
  min,
  nsgr_global,
  moss_manila_home,
  addienas,
  jiWoon,
  partspro,
  kris,
  arjunafarm,
  saung_pananjung,
  jayamart,
  fixitpro,
  bakso_klangenan,
  limited,
  siakap_menangis,
  tenang_technologi,
  mypetshome,
  wartel,
  bostajir,
  koki,
];

function TrustedClientList({ className }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={cx("bg-transparent flex flex-col justify-center", className)}
    >
      <div className="flex flex-col items-center">
        <span className="font-[Raleway] font-[700] text-[22px] xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] text-[#00215D]">
          {t("trusted_by")}
        </span>
      </div>

      <div className="flex flex-col items-center gap-y-10 w-full my-10 max-w-[1166px] mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 items-center gap-[11px] w-full place-items-center">
          {clientsLogos.map((logo) => (
            <div className="w-[187px] h-[111px] rounded-[12px] shadow-md shadow-gray-500/30 flex items-center justify-center bg-white p-1">
              <img
                className="w-full h-full object-contain"
                src={logo}
                alt="client logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TrustedClientList;
