import circleBluePlusWhite from "../assets/circleBluePlusWhite.png";
import React, { useState } from "react";
import fiveStar from "../assets/fiveStar.png";
import parse from 'html-react-parser';
import { t } from "i18next";

// const testimonialsData = [
// image:wallaceKulas,
// name:'Wallace Kulas',
// position:'CEO, Shopee Philippines',
// contentTitle:"",
// rating:5,
// content:""
// ]


const TestimonialsComponent = ({ testimonials }) => {
  const [activeTestimonal, setactiveTestimonal] = useState(0);

  return (
    <section id="section9">
      <div
        className="min-h-[548px] py-4
     bg-[#004AAD]    
     flex
     flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row
     w-full"
      >
        <div
          className="flex 
      w-auto xl:flex-1 lg:flex-1 md:flex-1 sm:flex-1 
      items-center xl:items-end lg:items-end md:items-end sm:items-end
      justify-center flex-col"
        >
          <span className="w-[90%] xl:w-[550px] lg:w-[300px] md:w-[300px] sm:w-[270px] mb-3 font-[Raleway] font-[700] text-[42px] text-white">
            {t("Testimonials")}
          </span>
          {testimonials?.map((item, idx) => (
            <div
              onClick={() => setactiveTestimonal(idx)}
              className="bg-white mb-2 rounded-md h-[100px] 
            w-[90%] xl:w-[550px] lg:w-[300px] md:w-[300px] sm:w-[270px]
            cursor-pointer
            flex"
            >
              <div className="w-[90px] flex items-center justify-end">
                <img src={item.image} className="w-[59px] h-[59px] rounded-full" />
              </div>
              <div className="flex flex-1 w-full flex-col justify-center pl-4 ">
                <span
                  className={`font-[Raleway] font-[700] text-[17px] text-[#00215D] ${
                    idx == activeTestimonal
                      ? "border-r-8 border-[#F09000]"
                      : null
                  }`}
                >
                  {item.name}
                </span>
                <span
                  className={`font-[Raleway] font-[600] text-[15px] pt text-[#6B6B6B] ${
                    idx == activeTestimonal
                      ? "border-r-8 border-[#F09000]"
                      : null
                  }`}
                >
                  {item.position}
                </span>
              </div>
            </div>
          ))}
        </div>
        {testimonials[activeTestimonal] ? (
          <div className="flex flex-1 justify-center flex-col items-start pl-6">
            <span className="font-[Raleway] text-[26px] text-white font-[700] w-[90%]">
              {testimonials[activeTestimonal].contentTitle}
            </span>
            <img src={fiveStar} className="h-3 w-auto" />
            <span
              className="font-[Roboto] text-[400] text-white text-[15px] 
 w-[90%] xl:w-[90%]  lg:w-[90%]  md:w-[90%] sm:w-[90%]   
 leading-[22px] mt-4 tracking-[0.46875px]"
            >
              {parse(testimonials[activeTestimonal].content)}
            </span>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default TestimonialsComponent;
