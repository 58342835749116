import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PartnerProgram.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from "react-modal";
import footer_home from "../assets/Footer_Home@2x.png";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "../lib/reactPhoneInput.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import dizlogAction from "../assets/dizlogAction.png";
import contactUs from "../assets/contactUs.png";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import singapore from "../assets/singapore.svg";
import india from "../assets/india.svg";
import kenya from "../assets/kenya.svg";
import malaysia from "../assets/malaysia.svg";
import philippines from "../assets/philippines.svg";
import mobileICON from "../assets/mobileICON.png";
import indonesia from "../assets/indonesia.png";
import emailICON from "../assets/emailICON.png";
import { useNavigate } from 'react-router';
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#F6F6FF",
    height: "95%",
    padding: "20px !important",
    width: "95%",
  },
};

Modal.setAppElement("#root");
function ContactUsPage() {
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  let navigate = useNavigate();

	useEffect(() => {
    showIntercom()
		navigate(-1)
	}, [])

  const showIntercom = () => {
    window.Intercom && window.Intercom("showNewMessage");
  };



  return (
    <>
      <Header i18n={i18n} screen="support" />
      <section>
        <div className="bg-[#FEEDD3] min-h-[360px] flex">
          <div className="flex flex-1 items-center justify-center">
            <div className="w-[80%] flex flex-col ">
              <span className="text-[#F09000] font-[Roboto] font-[600]  text-[20px]">
                Contact Us
              </span>
              <span className="text-[#00215D] font-[Raleway] font-[700] leading-[40px]  text-[35px] mt-2">
                Have any Questions? We Love to Hear from You!
              </span>
              <span className="text-[#6B6B6B] font-[Roboto] font-[400] leading-[18px]  text-[13px] mt-2">
                Our topmost priority is to provide you with unwavering support!
                We consistently aim to surpass expectations and offer
                exceptional customer support. Whenever you require assistance,
                you can rely on us for immediate live help.
              </span>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-center">
            <img src={contactUs} className="h-[320px]  w-auto" />
          </div>
        </div>
      </section>
      <section>
        <div className="bg-[#F6F6FF] min-h-[715px] flex py-4">
          {/* <div className="flex flex-1 justify-center items-center">
            <div className="w-[80%] flex flex-col">
              <span
                className="font-[Raleway] text-[#00215D] font-[700] 
                text-[35px] leading-[40px] mt-2"
              >
                Contact Form
              </span>
              <span
                className="w-[80%] font-[Roboto] text-[#6B6B6B] font-[400] 
                text-[13px] leading-[18px] mt-2"
              >
                If you require additional details regarding our products and
                services, don't hesitate to reach out to us by either giving us
                a call or completing the contact form below. Once received, our
                team of eHopper Specialists will promptly get in touch with you.
              </span>
              <div className="flex mt-4">
                <div className="flex flex-col w-full mr-2">
                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={singapore} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        Singapore
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        +6586082071
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>

                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={india} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        India
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        +9191955063318
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>

                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={kenya} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        India
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        +254722491846
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full ml-2">
                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={malaysia} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        Malaysia
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        0122143822
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>

                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={philippines} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        Philippines
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        +639054368502
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>

                  <div className="mb-2 min-h-[110px] bg-white rounded-md flex flex-col shadow-sm p-2">
                    <div className="flex items-center p-1">
                      <img src={indonesia} className="h-[24px] w-[24px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#00215D] text-[19px]
                        font-[700]"
                      >
                        Indonesia
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img
                        src={mobileICON}
                        className="h-[16.5px] w-[10.5px] "
                      />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        +9191955063318
                      </span>
                    </div>
                    <div className="flex items-center p-1">
                      <img src={emailICON} className="h-[12px] w-[15px] " />
                      <span
                        className="ml-2 font-[Roboto] text-[#6B6B6B] text-[13px]
                        font-[400]"
                      >
                        support@dizlog.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex flex-1 items-center justify-start flex-col">
          <span
                className="font-[Raleway] text-[#00215D] font-[700] 
                text-[35px] leading-[40px] mt-2"
              >
                Contact Form
              </span>
              <span
                className="w-[80%] font-[Roboto] text-[#6B6B6B] font-[400] 
                text-[13px] leading-[18px] mt-2 text-center w-[60%]"
              >
                If you require additional details regarding our products and
                services, don't hesitate to reach out to us by either giving us
                a call or completing the contact form below. Once received, our
                team of eHopper Specialists will promptly get in touch with you.
              </span>
            <div className="min-h-[410px] w-[60%] bg-white rounded-md flex flex-col mt-4 p-3 shadow-sm">
              <div className="mt-3 flex">
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959] mb-2 ">
                    Country <span className="text-red-500">*</span>
                  </span>
                  <Select
                    options={options}
                    value={value}
                    className="text-[16px] h-[40px]  rounded-sm w-full"
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="flex flex-col w-full mr-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    First Name <span className="text-red-500">*</span>
                  </span>
                  <input
                    placeholder="Enter First Name"
                    className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div>
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    Last Name <span className="text-red-500">*</span>
                  </span>
                  <input
                    placeholder="Enter Last Name"
                    className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div>
              </div>
              <div className="mt-3 flex">
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    Email Address <span className="text-red-500">*</span>
                  </span>
                  <input
                    placeholder="email@address.com"
                    className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div>
              </div>
              <div className="mt-3 flex">
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    Mobile Number <span className="text-red-500 ">*</span>
                  </span>
                  <PhoneInput
                    country="us"
                    containerClass="mt-2"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
              </div>
              <div className="mt-3 flex">
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    Address <span className="text-red-500">*</span>
                  </span>
                  <input
                    placeholder="Address"
                    className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div>
              </div>
              <div className="mt-3 flex">
                <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[18px] font-[500] text-[#595959]">
                    Message <span className="text-red-500">*</span>
                  </span>
                  <textarea
                    placeholder="Enter message"
                    className="w-full text-[16px] p-2 outline-none min-h-[90px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div>
              </div>
              <div className="w-full flex mt-4 justify-end">
                <button className="outline-none rounded-md bg-[#F09000] h-[38px] w-[126px] text-center flex items-center justify-center">
                  <span className="font-[Roboto] font-[700] text-[12px] text-white ">
                    SUBMIT
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default ContactUsPage;
