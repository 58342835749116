import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import holisticBeauty from "../assets/holisticBeauty.png";
import bookingICON from "../assets/bookingICON.png";
import adjustStockWellness from "../assets/adjustStockWellness.png";
import checkoutSLimming from "../assets/checkoutSLimming.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import wellnessCustomDIsc from "../assets/wellnessCustomDIsc.png";
import efficientICon from "../assets/efficientICon.png";
import newInvoiceWellness from "../assets/newInvoiceWellness.png";
import enhancedIcon from "../assets/enhancedIcon.png";
import topItemWelness from "../assets/topItemWelness.png";
import welnessPOS from "../assets/welnessPOS.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import MylaCorpuz from "../assets/MylaCorpuz.png";
import DamianAlvarez from "../assets/DamianAlvarez.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function WellnessAndHolisticBeauty() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const ourTestimonials = [
    {
      content: t(
        "“As a Wellness and Holistic Beauty Salon owner, I can wholeheartedly vouch for the incredible value that DizLog POS has brought to our business. This system has been revolutionizing the way we manage our salon. The appointment management feature has significantly improved our scheduling process, ensuring smooth client bookings.”"
      ),
      name: t("Myla Corpuz"),
      designation: t("Beauty Salon Owner"),
      imageSrc: MylaCorpuz,
    },
    {
      content: t(
        "“DizLog POS's inventory management has been a lifesaver, keeping us well-stocked with organic products, essential oils, and wellness items, ensuring we never run out of the products our clients love. The client database feature has been a true gem. It has deepened our understanding of their needs, fostering stronger connections and trust.”"
      ),
      name: t("Damian Alvarez"),
      designation: t("Beauty Salon Owner"),
      imageSrc: DamianAlvarez,
    },
  ];

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "475px",
      image: {
        src: checkoutSLimming,
        height: "252.66px",
      },
      title: t("Holistic Salon Management with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: efficientICon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Client Checkout"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for smooth and seamless payment processing, allowing clients to complete their transformative holistic experiences with ease."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Precise Service Pricing and Specials"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure accurate service pricing and automated application of specials through Dizlog, enhancing transparency and client satisfaction during transactions."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Flexible Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog supports a variety of payment methods, including credit cards and digital wallets, providing clients with flexible and convenient payment choices."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustStockWellness,
        height: "318px",
      },
      title: t("Optimal Appointment and Product Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Appointment Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to manage appointments in real-time, optimizing scheduling and ensuring clients have a serene and timely holistic experience."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Product Notifications"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog generates automatic notifications for low product inventory, ensuring timely restocking of holistic beauty products and preventing shortages."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Centralized Product Control"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain a centralized view of holistic beauty products across your salon and online platform using Dizlog, enhancing product availability."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "475px",
      image: {
        src: welnessPOS,
        height: "289px",
      },
      title: t("Seamlessly Integrated Online Booking"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Services Catalog"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create an extensive online catalog of holistic services with Dizlog, allowing clients to explore and conveniently book their desired transformative experiences."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("User-friendly Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog simplifies the online booking process, providing clients with an intuitive platform to select and schedule their preferred holistic treatments."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Booking Synchronization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure real-time synchronization of appointments between your wellness salon and online platform using Dizlog, enhancing convenience for clients."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: newInvoiceWellness,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate accurate and automated invoices with Dizlog, offering clients clear documentation of services and streamlining the invoicing process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Flexible Payment Methods"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog supports various payment options, allowing clients to pay using their preferred methods and enhancing the ease of payment."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to track payment statuses and send automated reminders, reducing outstanding balances and ensuring effective cash flow management."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Payroll and Staff Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Employee Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for optimized staff scheduling, ensuring proper coverage during peak hours and enhancing the holistic beauty salon's operations."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog accurately records employee work hours, simplifying payroll processing and ensuring fair compensation for your dedicated holistic beauty staff."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Analyze employee performance data within Dizlog to make informed decisions about training, rewards, and professional growth opportunities."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topItemWelness,
        height: "323.38px",
      },
      title: t("Data-driven Insights for Informed Decisions"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Service Analytics and Trends"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog analytics to gain insights into popular holistic services and client preferences, guiding service offerings and marketing strategies."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Product Reports and Forecasting"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate comprehensive product reports and forecasts with Dizlog, aiding in inventory management and ensuring product availability."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Productivity Analysis"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog data to assess employee productivity and performance, facilitating performance evaluations and enhancing team efficiency."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: wellnessCustomDIsc,
        height: "256.95px",
      },
      title: t("Enhanced Client Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: enhancedIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Client Interactions"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize client data within Dizlog to provide personalized recommendations and tailored services, enhancing the holistic salon experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Rewards"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement holistic loyalty programs through Dizlog to reward loyal clients, fostering client retention and increasing engagement."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col md:flex-row gap-2 md:gap-0">
            <div className="flex flex-1 items-center justify-center">
              <img src={holisticBeauty} className="h-[284px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Wellness and Holistic Beauty Salons")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS emerges as an indispensable ally to Wellness and Holistic Beauty Salons, offering a comprehensive and tailored solution to meet their unique needs. This advanced point-of-sale system streamlines the salon's daily operations, optimizing appointment management, and ensuring smooth client scheduling for a harmonious and relaxing experience. The system's intuitive interface allows therapists and practitioners to access detailed client profiles, recording individual preferences, allergies, and holistic treatment histories, thus fostering personalized and attentive care."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default WellnessAndHolisticBeauty;
