import React from "react";

export const CircleCheckOutlineIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99838 18.7175C11.0929 18.7189 12.177 18.504 13.1882 18.0851C14.1994 17.6662 15.1179 17.0516 15.8909 16.2766C16.6659 15.5037 17.2805 14.5852 17.6993 13.574C18.1182 12.5627 18.3331 11.4787 18.3317 10.3841C18.3331 9.28957 18.1182 8.20553 17.6993 7.1943C17.2804 6.18307 16.6658 5.26458 15.8909 4.49162C15.1179 3.71663 14.1994 3.10204 13.1882 2.68316C12.177 2.26428 11.0929 2.04937 9.99838 2.05079C8.90383 2.04939 7.81979 2.26431 6.80856 2.68319C5.79733 3.10207 4.87884 3.71665 4.10588 4.49162C3.33091 5.26458 2.71633 6.18307 2.29745 7.1943C1.87857 8.20553 1.66365 9.28957 1.66505 10.3841C1.66363 11.4787 1.87853 12.5627 2.29741 13.574C2.7163 14.5852 3.33089 15.5037 4.10588 16.2766C4.87884 17.0516 5.79733 17.6662 6.80856 18.0851C7.81979 18.5039 8.90383 18.7189 9.99838 18.7175Z"
      stroke="#F09000"
      strokeWidth="1.66667"
      strokeLinejoin="round"
    />
    <path
      d="M6.66504 10.3828L9.16504 12.8828L14.165 7.88281"
      stroke="#F09000"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
