import React, { useState } from "react";
import "./DownloadLinks.css";
import GooglePlay from "../assets/gplay.png";
import Huawei from "../assets/huaweistore.png";
import AppStore from "../assets/appstore.png";
import WebSignup from "../assets/webss.png";
import QrSIgnup from "../assets/signupQR.png";
import QR from "../assets/QR_Code@2x.png";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

function DownloadLinks({ center, className }) {
  const { t } = useTranslation();

  const [showQr, setShowQr] = useState(false);
  const handleClick = () => {
    sendEvent();
    setShowQr(!showQr);
  };
  const sendEvent = () => {
    window.gtag("event", "conversion", {
      send_to: "AW-10829576507/_9BzCNm0yIsDELvy-Kso",
    });
  };
  return (
    <div
      className="download-links py-2"
      style={{
        justifyContent: center ? "center" : "",
      }}
    >
      <div className={clsx("containerDownloadLinks w-[20rem] xl:w-[26rem] lg:w-[26rem] md:w-[26rem] sm:w-[26rem]", className)}>
        <div 
          //className="grid grid-cols-2 pb-7 xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 sm:grid-cols-3 gap-2 max-w-[488px]"
          className="flex flex-row gap-2 flex-wrap justify-center sm:justify-start"
        >
          {/* <div className="col-7 align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center mb-1">
                  <a id="google-store-btn" href="https://play.google.com/store/apps/details?id=com.epaytime.diglog" target={'_blank'} rel="noreferrer" onClick={sendEvent}>
                    <img src={GooglePlay} className="rounded-md img-fluid" alt="googleplay" />
                  </a>
                </div>
                <div className="col-12 text-center mb-1">
                  <a id="apple-store-btn" href="https://apps.apple.com/sg/app/diglog-pos-inventory-tracker/id1577649880" target={'_blank'} rel="noreferrer" onClick={sendEvent}>
                    <img src={AppStore} className="rounded-md img-fluid" alt="appstore" />
                  </a>
                </div>
                
                <div className="col-12 text-center mt-1">
                </div>
              </div>
            </div>
          </div> */}
          <a
            id="google-store-btn"
            href="https://play.google.com/store/apps/details?id=com.epaytime.diglog"
            target={"_blank"}
            className="w-[155.5px] h-[43.92px]"
            rel="noreferrer"
            onClick={sendEvent}
          >
            <img
              src={GooglePlay}
              className="rounded-md img-fluid"
              alt="googleplay"
            />
          </a>

          <a
            id="apple-store-btn"
            // className="no-underline"
            href="https://apps.apple.com/sg/app/diglog-pos-inventory-tracker/id1577649880"
            target={"_blank"}
            className="w-[155.5px] h-[43.92px]"
            rel="noreferrer"
            onClick={sendEvent}
          >
            <img src={AppStore} className="img-fluid" alt="huawei" />
          </a>

          <a
            id="huawei-store-btn"
            href="https://web.dizlog.com/sign-up"
            //  href="https://web.diglog.ph/sign-up"
            target={"_blank"}
            className="w-[155.5px] h-[43.92px]"
            rel="noreferrer"
            onClick={sendEvent}
          >
            <img
              src={WebSignup}
              className="rounded-md img-fluid"
              alt="huawei"
            />
          </a>

          <a
            id="websignup-store-btn"
            // className="no-underline"
            href="https://appgallery.huawei.com/#/app/C104607965"
            target={"_blank"}
            className="w-[155.5px] h-[43.92px]"
            rel="noreferrer"
            onClick={sendEvent}
          >
            <img src={Huawei} className="img-fluid" alt="huawei" />
          </a>

          {!showQr && (
  <span
            // className="no-underline"
            className="w-[155.5px] h-[43.92px]"
            onClick={handleClick}
            alt="googleplay"
          >
            {/* <img src={Huawei} className="img-fluid" alt="huawei" /> */}

               <img src={QrSIgnup} className="img-fluid"  />
          </span>
)}


          {/* {!showQr && (
            <img
              onClick={handleClick}
              src={QrSIgnup}
              className="  rounded-md cursor-pointer no-underline h-[43.92px] w-[155.5px]"
              alt="googleplay"
            />
         
          )} */}
        </div>

        <div className="flex justify-center mt-2 items-center pb-2">
          {showQr && (
            <img src={QR} className=" mt-2 mb-2 h-16 w-auto " alt="qr" />
          )}




        </div>
      </div>
    </div>
  );
}

export default DownloadLinks;
