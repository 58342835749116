import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import eventPlanning from "../assets/eventPlanning.png";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import bookingICON from "../assets/bookingICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import hairCareSalonTechh from "../assets/hairCareSalonTechh.png";
import bookinggTrays from "../assets/bookinggTrays.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import invoiceIT from "../assets/invoiceIT.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import topCatItemIT from "../assets/topCatItemIT.png";
import centralisedOPS from "../assets/centralisedOPS.png";
import adjustEvents from "../assets/adjustEvents.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import HazelMendoza from "../assets/HazelMendoza.png";
import WestonTorres from "../assets/WestonTorres.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function EventPlanningEntertainmentService() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const ourTestimonials = [
    {
      content: t(
        "“Having been in the Event Planning and Entertainment Services industry for years, I can confidently say that DizLog POS has been a game-changer for our business. This remarkable system has streamlined every aspect of our event management process, from initial bookings to the final execution. With its user-friendly interface, we can effortlessly coordinate with vendors, allocate resources, and ensure that every detail is meticulously taken care of.”"
      ),
      name: t("Hazel Mendoza"),
      designation: t("Event Planner"),
      imageSrc: HazelMendoza,
    },
    {
      content: t(
        "“DizLog POS presents an invaluable solution tailored to cater to the unique demands of the Event Planning and Entertainment Services industry. With its exceptional features and intuitive interface, this state-of-the-art system empowers event organizers and entertainment service providers to orchestrate flawless experiences for their clients. From initial planning to execution, DizLog POS streamlines the entire process, allowing seamless management of event bookings”"
      ),
      name: t("Weston Torres"),
      designation: t("Event Planner"),
      imageSrc: WestonTorres,
    },
  ];

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "650.77px",
      image: {
        src: bookinggTrays,
        height: "490.77px",
      },
      title: t("Seamless Event Coordination with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Event Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for smooth and easy event booking, enabling clients to secure event planning and entertainment services hassle-free."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog provides real-time visibility into availability, allowing clients to select suitable dates and times based on preferences and your event schedule."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Event Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Set up automated event reminders through Dizlog, reducing oversights and ensuring a well-coordinated scheduling process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustEvents,
        height: "318px",
      },
      title: t("Optimal Resource and Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient Resource Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to monitor and manage crucial event resources, ensuring your event planning and entertainment services have the necessary supplies."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Low Inventory Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Receive automated notifications for low inventory levels via Dizlog, allowing timely restocking and preventing service disruptions."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Reports and Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate comprehensive resource reports and insights using Dizlog data, assisting in resource allocation decisions and enhancing service execution."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "449px",
      image: {
        src: hairCareSalonTechh,
        height: "289px",
      },
      title: t("User-friendly Online Event Access"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Intuitive Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create a user-friendly online store with Dizlog, enabling clients to easily explore and book event planning and entertainment services at their convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Payment Processing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog ensures secure online payment processing, empowering clients to make advance payments and enhancing the overall event experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("24/7 Service Availability"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With Dizlog, your services are accessible for booking round the clock, providing clients with flexibility and enhancing accessibility."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: invoiceIT,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Handling"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to generate automated event invoices, providing clients with transparent and accurate billing for the event planning and entertainment services."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Flexibility"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog supports a variety of payment methods, enabling clients to choose their preferred options and enhancing convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to track payment statuses and send automated reminders, ensuring prompt payments and improving financial management."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Team Coordination and Payroll"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Staff Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for efficient team scheduling, ensuring your event planning and entertainment services are well-coordinated for successful events."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog accurately records staff work hours, simplifying payroll processing and ensuring equitable compensation for team members."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Tracking and Growth"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog data to assess team performance and identify opportunities for growth and enhancement, fostering a motivated workforce."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topCatItemIT,
        height: "323.38px",
      },
      title: t("Insights-driven Excellence in Event Services"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Event Analytics"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog analytics to gain insights into event performance, customer preferences, and trends, guiding informed decisions to enhance your offerings."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Resource Utilization Reports"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate resource utilization reports using Dizlog data, enabling you to optimize resource allocation and streamline event execution."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Financial Insights"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Gain financial insights through Dizlog data analysis, empowering strategic financial decisions for your event planning and entertainment services."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "270px",
      image: {
        src: payrollSettingsBus,
        height: "323.38px",
      },
      title: t("Centralized Operations and Accessibility"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: centralisedOPS,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Integrated Business Hub"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog serves as a central hub for managing event appointments, resources, invoicing, payroll, and employee information, simplifying your operations."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Cloud-based Access"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Access Dizlog remotely from anywhere with an internet connection, enabling remote management and real-time oversight of your event business."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Scalable Support"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "As your event planning and entertainment business grows, Dizlog scales seamlessly to accommodate increased demand, ensuring continued support for your success."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[300px] bg-[#FEF7EC] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end justify-center pt-4">
              <img
                src={eventPlanning}
                className="h-[95%] max-h-[500px] w-auto"
              />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
                   text-[#00215D]"
                >
                  {t("DizLog for Event Planning and Entertainment Services")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS presents an invaluable solution tailored to cater to the unique demands of the Event Planning and Entertainment Services industry. With its exceptional features and intuitive interface, this state-of-the-art system empowers event organizers and entertainment service providers to orchestrate flawless experiences for their clients. From initial planning to execution, DizLog POS streamlines the entire process, allowing seamless management of event bookings, vendor coordination, and resource allocation."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default EventPlanningEntertainmentService;
