import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCreative } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "../screens/custom_css/swiper_navigation.css";

/**
 *
 * Testimonial Carousel
 *
 * @param {object} props
 * @returns
 */
function TestimonialsCarousel({ testimonials }) {
  return (
    <section>
      <div className="p-4 md:p-8 bg-[#F6F6FF] min-h-[525px] w-full flex flex-col justify-center items-center gap-4">
        <div className="flex justify-center items-center flex-col gap-2">
          <span className="font-[600] font-[Roboto] text-[20px] leading-[20px] tracking-[0.47px] text-[#F09000] mt-2">
            Our Testimonials
          </span>
          <span className="font-[Raleway] font-[700] text-[45px] leading-[53px] tracking-[0.2px] text-[#00215D] text-center">
            What Our Clients Say?
          </span>
        </div>
        <Swiper
          direction={"horizontal"}
          pagination={{
            clickable: false,
            loop: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpointsBase={1024}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
          navigation={true}
          slidesPerView={3}
          modules={[Pagination, Autoplay, Navigation, EffectCreative]}
          className="myTestimonySwiper"
        >
          {testimonials.map(
            ({ content, name, designation, imageSrc }, index) => {
              return (
                <SwiperSlide key={index} className="max-w-[380px]">
                  <div className="flex flex-col flex-1 justify-end items-center bg-white w-[565px] h-[300px] shadow-sm rounded-sm p-4">
                    <div className="flex-1  text-[14px] text-[#6B6B6B] text-left tracking-[0.2px]  font-[400] font-[Roboto] leading-[20px]">
                      {content}
                    </div>
                    <div className="flex w-full pt-3">
                      <div className="flex flex-col w-full gap-[3px]">
                        <span
                          className="font-[Raleway] font-[700] 
                      text-[21px] leading-[24px] tracking-[0.2px] text-[#00215D] text-left
                        "
                        >
                          {name}
                        </span>
                        <span className="font-[Roboto] font-[600] text-[15px] text-[#F09000] text-left leading-[25px] tracking-[0.2px]">
                          {designation}
                        </span>
                      </div>
                      <div className="flex justify-end w-full">
                        <img src={imageSrc} className="h-auto w-[80px] " />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
    </section>
  );
}

export default TestimonialsCarousel;
