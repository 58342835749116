import React from 'react'
import './Topics.css'
import { Link } from 'react-router-dom'

//logos
import GetHelp_GettingStarted from '../assets/GetHelp_GettingStarted.svg'
import GetHelp_mPOS from '../assets/GetHelp_Help_mPOS.svg'
import GetHelp_Inventory from '../assets/GetHelp_Inventory.svg'
import GetHelp_Employees from '../assets/GetHelp_Employees.svg'
import GetHelp_Payroll from '../assets/GetHelp_Payroll.svg'
import GetHelp_CreditBook from '../assets/GetHelp_CreditBook.svg'
import GetHelp_Payment from '../assets/GetHelp_Payment.svg'
import GetHelp_Report from '../assets/GetHelp_Report.svg'

import gettingStartedFAQ from '../assets/gettingStartedFAQ.png'
import mPOSFAQ from '../assets/mPOSFAQ.png'
import inventoryFAQ from '../assets/inventoryFAQ.png'
import employeesFAQ from '../assets/employeesFAQ.png'
import payrollFAQ from '../assets/payrollFAQ.png'
import cashAndCreditBookFAQ from '../assets/cashAndCreditBookFAQ.png'
import paymentsFAQ from '../assets/paymentsFAQ.png'
import reportsFAQ from '../assets/reportsFAQ.png'
import { t } from 'i18next'

function Topics({query}) {
  const topics = [
    {
      topic: t("Getting Started"),
      logo: gettingStartedFAQ,
      link: "/get-help/getting-started"
    },
    {
      topic: t("mPOS"),
      logo: mPOSFAQ,
      link: "/get-help/mpos"
    },
    {
      topic: t("Inventory"),
      logo: inventoryFAQ,
      link: "/get-help/getting-started"
    },
    {
      topic: t("Employees"),
      logo: employeesFAQ,
      link: "/get-help/employee"
    },
    {
      topic: t("Payroll"),
      logo: payrollFAQ,
      link: "/get-help/payroll"
    },
    {
      topic: t("Cash & Credit Book"),
      logo: cashAndCreditBookFAQ,
      link: "/get-help/cash-credit"
    },
    {
      topic: t("Payments"),
      logo: paymentsFAQ,
      link: "/get-help/payments"
    },
    {
      topic: t("Reports"),
      logo: reportsFAQ,
      link: "/get-help/reports"
    }
  ]

  function filterTopics (topics, query) {
    console.log(topics.length, query)
    if (!query) {
        return topics;
    }

    return topics.filter((topic) => {
        const topicName = topic.topic.toLowerCase();
        return topicName.includes(query);
    });
  };

  return (
    <div className="topics bg-[#F6F6FF]">
      <span className='text-[#00215D] font-[Raleway] text-[35px] font-[700]'>FAQs</span>
      <div className="grid 
      grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-4 mt-4">
        {filterTopics(topics, query).map(topic => {
          return <div className="topic-boxx flex h-[75px] w-full" key={topic.link}>
            <Link to={topic.link} className="w-full bg-white rounded-md items-center shadow-md bg-light-purple flex
              no-underline font-[Raleway] text-[#00215D] text-[17px]">
              <div className="pl-3">
                <img src={topic.logo}  className='h-[45px] w-auto'/>
              </div>
              <h6 className='m-0 pl-4'>{topic.topic}</h6>
            </Link> 
          </div>
        })}
      </div>
    </div>
  )
}

export default Topics
