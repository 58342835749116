import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import subsidariesMap from "../assets/subsidariesMap.svg";
import BLOG from "../assets/BLOG.png";

import india from "../assets/india.svg";
import malaysia from "../assets/malaysia.svg";

import singapore from "../assets/singapore.svg";
import philippines from "../assets/philippines.svg";
import indiaa from "../assets/indiaa.png";
import kenya from "../assets/kenya.svg";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import blogsLists from "../utils/blogsList";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
function BlogsComponent(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();

  const { index } = useParams();
  useEffect(() => {
    console.log("paramName", index);
  }, []);

  let blogsListing = blogsLists[index];
  return (
    <>
      {/* one props */}
      <div className="bg-[#F5F8FB] min-h-[200px] p-3 xl:p-6 lg:p-6 md:p-6 sm:p-6 w-full flex  justify-center">
        <div className="w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-full flex flex-col">
          {/* title */}
          <span
            className="font-[Raleway] font-[700] text-lg xl:text-[30px] lg:text-[30px] md:text-[30px] sm:text-[30px] text-[#00215D] 
          leading-[22px] xl:leading-[40px] lg:leading-[40px] md:leading-[40px] sm:leading-[40px]"
          >
            {props.section.title}
          </span>
          {/* descripiton */}
          <span className="mt-2 font-[Roboto] font-[400] text-[14px] text-[#6B6B6B] leading-[20px] tracking-[0.47px]">
            {ReactHtmlParser(props.section.description)}
          </span>
          {props.section.contentsBeforeImage != undefined
            ? props.section.contentsBeforeImage?.map((item) => (
                <span className="mt-2 font-[Roboto] font-[400] text-[14px] text-[#6B6B6B] leading-[18px] tracking-[0.47px]">
                  {item.image != undefined ? (
                    <div className="w-full flex justify-center  my-4">
                      <img src={item.image} className="w-auto h-[321.79px]" />
                    </div>
                  ) : (
                    ReactHtmlParser(item)
                  )}
                </span>
              ))
            : null}

          {props.section.image != "" ? (
            <div className="w-full flex justify-center  my-4">
              {/* image */}
              <img src={props.section.image} className="w-auto h-[321.79px]" />
            </div>
          ) : null}

          {/* contents */}
          {props.section.contents?.map((item) => (
            <span className="mt-2 font-[Roboto] font-[400] text-[14px] text-[#6B6B6B] leading-[18px] tracking-[0.47px]">
              {item.image != undefined ? (
                <div className="w-full flex justify-center  my-4">
                  <img src={item.image} className="w-auto h-[321.79px]" />
                </div>
              ) : null}

              {item.strong != undefined ? (
                <strong>{ReactHtmlParser(item.strong)}</strong>
              ) : null}

              {item.title != undefined ? (
                <span
                  className="font-[Raleway] font-[700] text-lg xl:text-[30px] lg:text-[30px] md:text-[30px] sm:text-[30px] text-[#00215D] 
              leading-[22px] xl:leading-[40px] lg:leading-[40px] md:leading-[40px] sm:leading-[40px]"
                >
                  {ReactHtmlParser(item.title)}
                </span>
              ) : null}

              {item.title == undefined &&
              item.strong == undefined &&
              item.image == undefined
                ? ReactHtmlParser(item)
                : null}
            </span>
          ))}
        </div>
      </div>
      {/* one props */}
    </>
  );
}

export default BlogsComponent;
