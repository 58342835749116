import "./PartnerProgram.css";
import "../lib/reactPhoneInput.css";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import Modal from "react-modal";

import Header from "../components/Header";
import Footer from "../components/Footer";
import businessTypes from "../utils/businessTypes";
import TestimonialsCarousel from "../components/TestimonialsCarousel";

// assets
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import cashier from "../assets/cashierv2.png";
import footer_home from "../assets/Footer_Home@2x.png";
import dizlogAction from "../assets/dizlogAction.png";
import noCreditRequired from "../assets/noCreditRequired.png";
import reserveSpot from "../assets/reserveSpot.png";

import AndresSeanReyes from "../assets/AndresSeanReyes@3x.png";
import PabloSantos from "../assets/PabloSantos@3x.png";
import IsabelJoyFlores from "../assets/IsabelJoyFlores@3x.png";
import MaeVelasquez from "../assets/MaeVelasquez@3x.png";
import RobbieVitancol from "../assets/RobbieVitancol@3x.png";
import AndrewDavid from "../assets/AndrewDavid@3x.png";
import footerBannerLiveDemo from "../assets/footerBannerLiveDemo.jpeg";
import { CircleCheckOutlineIcon } from "./CircleCheckOutlineIcon";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#F6F6FF",
    height: "95%",
    padding: "20px !important",
    width: "95%",
  },
};

const bookPlanOptions = [
  "ASAP",
  "Within this month",
  "Within this quarter",
  "Not so soon",
];

Modal.setAppElement("#root");
function LiveDemo() {
  const { t, i18n } = useTranslation();
  const typess = businessTypes.allTypes;

  const origin_diglog = window.location.host;
  const bizurl = "www.dizlog.com";
  // const url_webportal_api =  'https://api-payroll2.diglog.sg'
  const url_webportal_api =
    origin_diglog == bizurl || origin_diglog == "dizlog.com"
      ? "https://api-payroll2.dizlog.com"
      : "https://dev-payroll.dizlog.com";

  const url_webportal_web =
    origin_diglog == bizurl || origin_diglog == "dizlog.com"
      ? "https://web.dizlog.com"
      : "https://devweb.diglog.ph";

  const [firstName, setfirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");
  const [lastName, setlastName] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [businessType, setbusinessType] = useState("");
  const [bookPlan, setBookPlan] = useState(null);

  const clearStates = () => {
    setfirstName("");
    setPhone("");
    setemail("");
    setlastName("");
    setbusinessName("");
    setbusinessType("");
  };

  const submitContactForm = () => {
    if (firstName == "" || email == "") {
      Swal.fire({
        icon: "error",
        text: "Please fill in all the fields",
      });
      return;
    }
    axios
      .post(`${url_webportal_api}/contact-us`, {
        businessType: businessType,
        firstName: firstName,
        lastName: lastName,
        emailAddress: email,
        phone: phone,
        description: "",
        subject: "",
        businessName: businessName,
        bookPlan,
      })
      .then((r) => {
        clearStates();
        Swal.fire({
          text: "Schedule your Demo",
          icon: "success",
        });
        window.location.href = "https://meetings.hubspot.com/contact2355";
      });
  };

  const handleScrollToView = () => {
    const target = document.getElementById("form-demo");
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header i18n={i18n} screen="support" />
      <section>
        <div
          className="bg-[#FEEDD3] min-h-[468px] flex py-4
        flex-col md:flex-row "
        >
          <div className="flex flex-1 justify-center items-start w-full md:w-1/2">
            <div className="w-full px-5 flex flex-col">
              <span
                className="font-[Roboto] text-[#F09000] font-[600] 
                text-[20px]"
              >
                {t("Demo")}
              </span>
              <span
                className="font-[Raleway] text-[#00215D] font-[700] 
                text-[20px] xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[35px] leading-[25px] xl:leading-[40px] lg:leading-[40px] md:leading-[40px] sm:leading-[40px] mt-2"
              >
                {t(
                  "Start, Grow, and Manage  your entire Business Effortlessly with Dizlog"
                )}
              </span>
              <span
                className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] font-[Roboto] text-[#6B6B6B] font-[400] 
                text-[13px] leading-[15px] tracking-[0.49px] mt-2"
              >
                {t(
                  "The only business solution you need to operate your entire business from managing sales, customers, partners, and employees on one platform that helps you increase sales and reduce costs."
                )}
              </span>
              <div className="my-5">
                <img src={cashier} className="w-full" />
              </div>

              <div>
                <div className="text-[21px] leading-5 text-[#00215D] font-bold font-[Roboto]">
                  {t("What can you expect?")}
                </div>

                <ul className="space-y-1 my-3 wrap">
                  <li className="flex items-center justify-start gap-[10px]">
                    <div>
                      <CircleCheckOutlineIcon />
                    </div>
                    <span className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-[#6B6B6B] tracking-[0.49px] text-[13px] font-[Roboto] font-[400] whitespace-break-spaces">
                      {t(
                        "Zero-commitment demo of the DizLog all-in-one POS system tailored to your business needs."
                      )}
                    </span>
                  </li>
                  <li className="flex items-center justify-start gap-[10px]">
                    <div>
                      <CircleCheckOutlineIcon />
                    </div>
                    <span className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-[#6B6B6B] tracking-[0.49px] text-[13px] font-[Roboto] font-[400] whitespace-break-spaces">
                      {t("Information on the latest promotions and offers")}
                    </span>
                  </li>
                  <li className="flex items-center justify-start gap-[10px]">
                    <div>
                      <CircleCheckOutlineIcon />
                    </div>
                    <span className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-[#6B6B6B] tracking-[0.49px] text-[13px] font-[Roboto] font-[400] whitespace-break-spaces">
                      {t(
                        "Live walkthrough of all the powerful features to help you grow your business."
                      )}
                    </span>
                  </li>
                  <li className="flex items-center justify-start gap-[10px]">
                    <div>
                      <CircleCheckOutlineIcon />
                    </div>
                    <span className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-[#6B6B6B] tracking-[0.49px] text-[13px] font-[Roboto] font-[400] whitespace-break-spaces">
                      {t(
                        "Personalized session to answer all your questions and concerns."
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            id="form-demo"
            className="flex flex-1 items-center justify-start w-full md:w-1/2 relative mx-auto"
          >
            <div className="flex flex-col min-h-[410px] max-w-[510px] relative mx-auto px-2">
              <div className="bg-white rounded-md !mb-[58px] p-3 shadow-sm relative flex flex-col">
                <span className="font-[Raleway] font-[700]  text-[#00215D] text-[26px] text-center">
                  {t("Schedule a FREE demo")}
                </span>
                <div className="flex mt-3">
                  <div className="flex flex-col w-full mr-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("Name")} <span className="text-red-500">*</span>
                    </span>
                    <input
                      value={firstName}
                      onChange={(e) => setfirstName(e.target.value)}
                      placeholder={t("Enter Name")}
                      className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                    />
                  </div>
                  {/* <div className="flex flex-col w-full ml-1">
                  <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                    {t("Last Name")} <span className="text-red-500">*</span>
                  </span>
                  <input
                    value={lastName}
                    onChange={(e) => setlastName(e.target.value)}
                    placeholder={t("Enter Last Name")}
                    className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                  />
                </div> */}
                </div>
                <div className="mt-3 flex">
                  <div className="flex flex-col w-full ml-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("Business name")}{" "}
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      value={businessName}
                      onChange={(e) => setbusinessName(e.target.value)}
                      placeholder={t("Enter business name")}
                      className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                    />
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex flex-col w-full ml-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("Business Type")}{" "}
                      <span className="text-red-500">*</span>
                    </span>
                    <select
                      value={businessType}
                      onChange={(e) => setbusinessType(e.target.value)}
                      className="w-full text-[16px] p-2 outline-none h-[45px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                    >
                      {typess.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex flex-col w-full ml-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("Email Address")}{" "}
                      <span className="text-red-500">*</span>
                    </span>
                    <input
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      placeholder={t("email@address.com")}
                      className="w-full text-[16px] p-2 outline-none h-[40px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                    />
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex flex-col w-full ml-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("Mobile Number")}{" "}
                      <span className="text-red-500 ">*</span>
                    </span>
                    <PhoneInput
                      country="us"
                      containerClass="mt-2"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex flex-col w-full ml-1">
                    <span className="font-[Roboto] text-[15px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]  font-[500] text-[#595959]">
                      {t("When do you plan to have a POS system?")}{" "}
                      <span className="text-red-500">*</span>
                    </span>
                    <select
                      value={bookPlan}
                      onChange={(e) => setBookPlan(e.target.value)}
                      className="w-full text-[16px] p-2 outline-none h-[45px] border mt-2 border-2-[#B8B8B8] rounded-sm"
                    >
                      {bookPlanOptions.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="w-full flex mt-4 justify-end">
                  <button
                    id="submit-contact"
                    onClick={() => submitContactForm()}
                    className="outline-none rounded-md bg-[#F09000] h-[50px] w-[126px] text-center flex items-center justify-center"
                  >
                    <span className="font-[Roboto] font-[700] text-[15px] text-white ">
                      {t("Book my Demo")}
                    </span>
                  </button>
                </div>
              </div>
              <div className="absolute bottom-0 left-0 z-50 px-2">
                <img className="object-cover" src={noCreditRequired} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurTestimonialsV2 />
      {/* {renderClientTestimonials()} */}

      {/* <section>
        <div className="min-h-[805px] bg-[#FFFFFF] flex flex-col p-6 justify-center">
          <div className="flex flex-col items-center">
            <span className="font-[Raleway] font-[700] text-[22px] xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] text-[#00215D]">
              {t("See Dizlog in Action")}
            </span>
            <span className="font-[Roboto] font-[400] text-xs text-[#6B6B6B] text-center w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] mt-2">
              {t(
                "Experience the power of our flexible platform firsthand and discover how it can revolutionize your business operations, providing you with unparalleled spend visibility and process efficiency. Join us for an exclusive opportunity to learn more about our transformative solutions and witness the tangible benefits they can bring to your organization. Remember, seeing is believing! Don't miss out on this chance to witness the impact our platform can have on your business."
              )}
            </span>
          </div>
          <div className="flex  mt-8 flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1">
              <img
                src={dizlogAction}
                className="h-[300px] xl:h-[508px] lg:h-[508px] md:h-[508px] sm:h-[508px] w-auto"
              />
            </div>
            <div className="flex flex-1 w-full flex-col">
              <span className="text-[#00215D] font-[Roboto] font-[700]  text-[20px]">
                {t("What you will learn:")}
              </span>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Discover how our POS system simplifies and accelerates the transaction process, ensuring a smooth and efficient customer experience."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Learn how the app effectively manages your inventory by tracking stock levels, automating reorder processes, and providing real-time updates on product availability."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Explore the robust reporting capabilities of our POS system, allowing you to generate comprehensive reports on sales, inventory, and customer behavior. Gain valuable insights to make data-driven business decisions."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Understand how our POS system can be customized to meet your specific business needs and seamlessly integrate with other software solutions, such as accounting or e-commerce platforms."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Experience the intuitive and user-friendly interface of our app, designed to simplify operations and reduce training time for your staff."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Discover how our POS system supports mobility, enabling you to process transactions and access vital business information from anywhere, anytime, on various devices."
                    )}
                  </span>
                </div>
              </div>

              <div className="flex py-2 min-h-[36px]">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                  <span
                    className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B] `}
                  >
                    {t(
                      "Learn about the robust security measures implemented in our POS system to protect sensitive customer data and ensure compliance with industry standards."
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="bg-[#004AAD] min-h-[356px] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
          <div className="flex flex-1 items-end justify-center">
            <img
              src={reserveSpot}
              className="h-[200px] xl:h-[320px] lg:h-[320px] md:h-[320px] sm:h-[320px] mt-2  w-auto"
            />
          </div>
          <div className="flex flex-1 items-center py-4 justify-center xl:justify-start lg:justify-start md:justify-start sm:justify-start">
            <div className="w-[95%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col ">
              <span className="text-[#FFFFFF] font-[Raleway] font-[700]  text-[22px] xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[35px]">
                {t("Reserve your Spot Now!")}
              </span>
              <span className="text-[#FFFFFF] font-[Roboto] font-[400]  text-xs mt-2">
                {t(
                  "During this exclusive session, you will have the opportunity to experience firsthand the power of our state-of-the-art Point of Sale (POS) system. Learn how our intuitive interface simplifies transactions, tracks inventory, generates insightful reports, and improves overall efficiency. Discover the seamless integration capabilities, customizable features, and advanced security measures that set our POS system apart. Gain valuable insights into customer behavior, optimize inventory management, and make data-driven decisions that drive your business forward. Don't miss this chance to revolutionize the way you do business."
                )}
              </span>
            </div>
          </div>
        </div>
      </section> */}

      {renderAboveFooterSection()}

      <Footer footerImage={footer_home} className="z-0" />
    </>
  );

  function renderAboveFooterSection() {
    return (
      <div
        className="relative bg-cover bg-center min-h-64 w-full"
        style={{ backgroundImage: `url(${footerBannerLiveDemo})` }}
      >
        <div className="absolute inset-0 flex flex-col gap-4 items-center justify-center bg-[linear-gradient(84.74deg,_rgba(217,104,0,0.8)_-11.76%,_rgba(240,144,0,0.8)_41.52%)] bg-opacity-80">
          <div className="font-[Raleway] font-bold text-xl md:text-[54px] text-white text-center md:tracking-[0.27px] md:leading-[64px] max-w-[856px]">
            {t("Grow and Manage your Business Effortlessly with DizLog")}
          </div>
          <button
            className="rounded-[5px] text-[18px] font-bold text-[#F09000] inline-flex bg-white px-4 py-[10px] hover:opacity-80"
            onClick={handleScrollToView}
          >
            {t("Schedule a demo")}
          </button>
        </div>
      </div>
    );
  }

  function renderClientTestimonials() {
    const clientList = [
      {
        content: t(
          "DizLog's All-in-One POS System is a game-changer for our restaurant! It streamlined our operations, improved order accuracy, and made inventory tracking a breeze. Our efficiency and customer satisfaction have soared!"
        ),
        name: "Andres Sean Reyes",
        designation: "Restaurant Owner",
        imageSrc: AndresSeanReyes,
      },
      {
        content: t(
          "This POS product is very fantastic for our grocery store! It's user-friendly, speeds up operations, and makes payments a breeze. Our Our customers are happier than ever!"
        ),
        name: "Pablo Santos",
        designation: "Grocery Store Owner",
        imageSrc: PabloSantos,
      },
      {
        content: t(
          "DizLog transformed our hair salon! Booking appointments is now effortless, and managing schedules has never been easier. Our clients love the convenience, and so do we!"
        ),
        name: "Isabel Joy Flores",
        designation: "Hair Salon Owner",
        imageSrc: IsabelJoyFlores,
      },
      {
        content: t(
          "DizLog's All-in-One POS System transformed our coffee shop! It made ordering fast and hassle-free, and the payment process is easy and secure. Our efficiency has skyrocketed, and customers appreciate the swift service!"
        ),
        name: "Mae Velasquez",
        designation: "Coffee Shop Owner",
        imageSrc: MaeVelasquez,
      },
      {
        content: t(
          "Using DizLog revolutionized our furniture shop! Ordering is spot-on, the online store showcases diverse options, and inventory management is a breeze. We’re thrilled with the efficiency!"
        ),
        name: "Robbie Vitancol",
        designation: "Furniture Shop Owner",
        imageSrc: RobbieVitancol,
      },
      {
        content: t(
          "DizLog Ain has transformed our autoshop! Setting up appointments is a snap, and managing employee timesheets and payroll is incredibly user-friendly."
        ),
        name: "Andrew David",
        designation: "Autoshop",
        imageSrc: AndrewDavid,
      },
    ];

    return <TestimonialsCarousel testimonials={clientList} />;
  }
}

export default LiveDemo;
