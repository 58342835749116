import React from 'react'
import { useTranslation } from 'react-i18next'
import Assistance1 from '../../assets/assistance1.png'
import Assistance2 from '../../assets/assistance2.png'
import Assistance3 from '../../assets/assistance3.png'
import ArrowRight from '../../assets/arrow-right.png'
import { Link } from 'react-router-dom'

const ReceiveAssistance = () => {
  const { t } = useTranslation('common')

  const data = {
    title: t("Receive Assistance"),
    description: t("Receive help with any questions you may have about ."),
    items: [
      {
        image: Assistance1,
        title: t("For 24/7 product support."),
        link: "/get-help",
      },
      {
        image: Assistance2,
        title: t("Access exclusive insights from 's business experts."),
        link: "/get-help",
      },
      {
        image: Assistance3,
        title: t("To engage with fellow business proprietors."),
        link: "/get-help",
      }
    ]
  }

  return (
    <section className='py-8 px-4 flex items-center justify-center bg-[#F09000]'>
      <div className='max-w-4xl flex flex-col text-white'>
        <div className='font-bold font-[Raleway] text-2xl mb-2'>
          {data.title}
        </div>
        <div className='mb-4 text-sm font-[Roboto]'>
          {data.description}
        </div>
        <div className='flex flex-col sm:flex-row gap-4'>
          {data.items.map((item, index) => (
            <div key={index} className='w-full sm:w-1/3 flex flex-col items-start p-4 shadow-sm rounded-md drop-shadow-md bg-white/10'>
              <div className='h-32 flex flex-row items-start'>
                <img src={item.image} alt={item.title} className='w-12 h-12' />
              </div>
              
              <div className='flex flex-col'>
                <div className='font-bold font-[Raleway] text-white text-md'>
                  {item.title}
                </div>
                <Link to={item.link} className='no-underline text-white text-sm'>
                  {t("Learn More")} <img src={ArrowRight} alt='arrow-right' className='w-4 h-4 inline' />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ReceiveAssistance