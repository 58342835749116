import React from "react";
import "./VideoModal.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import closeSvg from '../assets/close.svg'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function VideoModalDynamic({ showModal, toggle,c }) {
  console.log("modal",showModal);

  return (
    <Modal isOpen={showModal} toggle={toggle} dialogClassName="custom-modal-style" centered size="xl">
      <div className="h-3  pt-3 flex justify-end items-center">
        <span  onClick={() => toggle()} className="text-[12px] mr-5 text-white font-bold cursor-pointer
        p-1 w-6 text-center rounded-full bg-red-500">X</span>
      </div>
      <ModalBody style={{ width: "100%" }}>
        <div className="videoWrapper">
          {/* {children} */}
          {ReactHtmlParser(c)}
          {c}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default VideoModalDynamic;
