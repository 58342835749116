import React, { useState } from "react";
import "./Header.css";
import "./headerModal.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/Logo_DigLog.png";
import diglog_name from "../assets/diglog_name.png";
import logoRoundless from "../assets/LogoRoundless.png";
import DizLog_Horizontal from "../assets/DizLog_Horizontal_01.png";
import dizlogLogo from "../assets/dizlogLogo.png";
import logo_horizontal from "../assets/logo_horizontal.png";
import Logo_DigLog_original from "../assets/Logo_DigLog_original.png";
import GetStartedModal from "./GetStartedModal";
import config from "../config.json";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { ReactComponent as ChevronDown } from "../assets/chevron-down.svg";
import blogsLists from "../utils/blogsList";
import { ReactComponent as ArrowOrangeRight } from "../assets/arrow-right.svg";
import ReactHtmlParser from "react-html-parser";

function Header({ screen }) {
  let navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [showGetStartedModal, setShowGetStartedModal] = useState(false);
  const { t, i18n } = useTranslation();

  const helpCenterLink =
    config.helpCenter[i18n.language] || config.helpCenter.en;

  function onGetStarted() {
    //setShowGetStartedModal(!showGetStartedModal);
    navigate("/get-started");
  }

  function onSignIn() {
    // window.location.assign('https://web.diglog.ph/');
    window.location.assign("https://web.dizlog.com/sign-up");
  }

  function onSignIn() {
    // window.location.assign('https://web.diglog.ph/');
    window.location.assign("https://web.dizlog.com/sign-up");
  }

  function handleClick() {
    console.log("click");
    setClicked(!clicked);
  }

  function redirectTo(destinaton) {
    window.location = destinaton;
  }

  const showIntercom = () => {
    window.Intercom && window.Intercom("showNewMessage");
  };

  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0, "smooth");
    console.log("jlkjlkj");
  }, [location]);

  useEffect(() => {
    // alert('r')
    console.log(i18n.language);
  }, []);

  const pathName = window.location.pathname;
  useEffect(() => {
    setIsOpen(false);
  }, [pathName]);

  const navsAndLInks = [
    {
      name: t("Products"),
      link: "",
      children: [
        {
          name: t("Point of Sale"),
          link: "/products/pos",
        },
        {
          name: t("Inventory Management"),
          link: "/products/inventory-management",
        },
        {
          name: t("Online Store"),
          link: "/products/online-store",
        },
        {
          name: t("Employee Management"),
          link: "/products/employee-payroll-management",
        },
        {
          name: t("Payroll"),
          link: "/products/payroll",
        },
        // {
        //   name: "Cash & Credit Book",
        //   link: "/products/cash-credit-book",
        // },
        {
          name: t("Invoicing & Quotations"),
          link: "/products/invoicing-quotations",
        },
      ],
    },
    {
      name: t("Business Types"),
      link: "",
      childrenHasLinks: true,
      children: [
        {
          name: t("Restaurant"),
          link: "/restaurant",
          links: [
            {
              name: t("Fastfood and Restaurant"),
              link: "/business-types/fastfood-beverages/fastfood-and-restaurant",
            },
            {
              name: t("Cafe/Cofee Shop"),
              link: "/business-types/fastfood-beverages/cafe-coffee-shop",
            },
            {
              name: t("Bars and Breweries"),
              link: "/business-types/fastfood-beverages/bars-breweries",
            },
            {
              name: t("Food Service/Catering"),
              link: "/business-types/fastfood-beverages/food-service-catering",
            },
          ],
        },
        {
          name: t("Retail"),
          link: "/retail",
          links: [
            {
              name: t("Arts / Carfts / Novelty"),
              link: "/business-types/retail/arts-crafts-novelty",
            },
            {
              name: t("Vehicle Supplies and Parts"),
              link: "/business-types/retail/vehicles-supplies-part",
            },
            {
              name: t("General Merchandise"),
              link: "/business-types/retail/general-merchandise",
            },
            {
              name: t("Grocery Store"),
              link: "/business-types/retail/grocery-store",
            },
            {
              name: t("Furniture"),
              link: "/business-types/retail/furniture",
            },
          ],
        },
        {
          name: t("Beauty"),
          link: "/beauty",
          links: [
            {
              name: t("Hair Care"),
              link: "/business-types/beauty/hair-care",
            },
            {
              name: t("Skincare and Spa"),
              link: "/business-types/beauty/skincare-and-spa",
            },
            {
              name: t("Nail Care"),
              link: "/business-types/beauty/nail-care",
            },
            {
              name: t("Wellness and Holistic Beauty"),
              link: "/business-types/beauty/wellness-holistic",
            },
            {
              name: t("Tattoos and Piercing"),
              link: "/business-types/beauty/tattoo-piercing",
            },
          ],
        },
        {
          name: t("Services"),
          link: "/services",
          links: [
            {
              name: t("Professional Services"),
              link: "/business-types/services/proffesional-services",
            },
            {
              name: t("Home and Maintenance Services"),
              link: "/business-types/services/home-maintenance-services",
            },
            {
              name: t("Technology and IT Services"),
              link: "/business-types/services/technology-and-it-services",
            },
            {
              name: t("Event Planning and Entertainment "),
              link: "/business-types/services/event-planning-entertainment-services",
            },
            {
              name: t("Education and Training Services"),
              link: "/business-types/services/education-training-services",
            },
            {
              name: t("Automotive Services"),
              link: "/business-types/services/automotive-services",
            },
          ],
        },
      ],
    },
    {
      name: t("Accounting"),
      link: "/accounting",
      children: [],
    },
    {
      name: t("B2b E-Commerce"),
      link: "/b2b-e-commerce",
      children: [],
    },
    {
      name: t("Partner"),
      link: "",
      children: [
        {
          name: t("Partner Program"),
          link: "/partner-program",
        },
        {
          name: t("Become a partner"),
          link: "",
          externalLink: "https://dizlog.leaddyno.com/",
        },
      ],
    },
    {
      name: t("Resources"),
      link: "",
      children: [
        {
          name: t("Marketplace"),
          link: "/marketplace",
        },
        {
          name: t("FAQs"),
          link: "/get-help",
        },
        {
          name: t("Helper Center"),
          link: "",
          externalLink: "https://helpcenter.dizlog.com/en/",
        },
        {
          name: t("Logistics"),
          link: "/logistics-partners",
        },
        {
          name: t("Payments"),
          link: "/payment-partners",
        },
        {
          name: t("Regional Offices"),
          link: "/regional-offices",
        },
        {
          name: t("Blogs"),
          link: "/blogs",
        },
        {
          name: t("Live demo"),
          link: "/live-demo",
        },
      ],
    },
    {
      name: t("Pricing"),
      link: "/pricing",
    },
    {
      name: t("Support"),
      link: "",
      children: [
        {
          name: t("Video Support"),
          link: "/marketplace",
        },
      ],
    },
  ];

  const businessTypesNavs = navsAndLInks.find(
    (item) => item.name == t("Business Types")
  );

  let countryLang = [
    {
      country: "USA",
      lang: "English",
      link: "en",
      codeAlpha2: "US",
      currency: "USD",
    },
    {
      country: "Philippines",
      lang: "English",
      link: "en",
      codeAlpha2: "PH",
      currency: "PHP",
    },
    {
      country: "Indonesia",
      lang: "Bahasa",
      link: "id",
      codeAlpha2: "ID",
      currency: "IDR",
    },
    {
      country: "France",
      lang: "French",
      link: "fr",
      codeAlpha2: "FR",
      currency: "EUR",
    },
    {
      country: "Spain",
      lang: "Spanish",
      link: "es",
      codeAlpha2: "ES",
      currency: "EUR",
    },
    {
      country: "Malaysia",
      lang: "Bahasa Melayu",
      link: "ms",
      codeAlpha2: "MY",
      currency: "MYR",
    },
    {
      country: "Japan",
      lang: "Japanese",
      link: "jp",
      codeAlpha2: "JP",
      currency: "JPY",
    },
    {
      country: "China",
      lang: "Chinese",
      link: "cn",
      codeAlpha2: "CN",
      currency: "CNY",
    },
    {
      country: "Vietnam",
      lang: "Vietnamese",
      link: "vi",
      codeAlpha2: "VN",
      currency: "VND",
    },
    {
      country: "India",
      lang: "Hindi",
      link: "hi",
      codeAlpha2: "IN",
      currency: "INR",
    },
    {
      country: "Bengal",
      lang: "Bengali",
      link: "bn",
      codeAlpha2: "BD",
      currency: "BDT",
    },
    // {
    //   country: "Africa",
    //   lang: "English",
    //   link: "af",
    // },
  ];
  const activeLanguage = () => {
    let active = i18n.language;

    let display = null;
    for (let i = 0; i <= countryLang.length - 1; i++) {
      if (countryLang[i].link == active) {
        return countryLang[i];
        break;
      }
    }
  };
  Modal.setAppElement("#root");

  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxHeight: "80%",
      // transition: "opacity 0.3s, transform 0.3s",
      animation: "foldIn 0.3s both",
      overflowY: "auto",
      zIndex: 99999999999999999999,
    },
  };

  const scrollbarStyles = `
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <nav
        id="header"
        className="header yourAppElement items-center"
        style={{ zIndex: "0 !important" }}
      >
        <div className="w-full hidden xl:flex lg:flex h-[75px] flex-row items-center gap-2">
          <Link
            to="/"
            className="no-underline flex p-1 lg:p-2 header-logo flex-row items-center justify-center"
          >
            <div className="no-underline flex justify-center items-center">
              <img
                className="h-7 w-auto object-fill xl:object-contain"
                src={dizlogLogo}
                alt="logo"
              />
            </div>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul
            className={clicked ? "nav-menu-active mr-auto" : "nav-menu mr-auto"}
          >
            <li className="nav-links">
              <span className="nav-link-title">
                {t("Products")}
                <ChevronDown
                  className={{ height: "0.25rem", width: "0.25rem" }}
                />
              </span>
              <ul className="dropdown-list bg-white">
                {navsAndLInks[0].children.map((el) => (
                  <li className="border-b p-2">
                    <Link
                      to={el.link}
                      className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                    >
                      {el.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li
              className="nav-links header-business-types-title"
              id="header-business-types-title"
            >
              <div className="nav-link-title whitespace-normal">
                {t("header.businessTypes")}
                <ChevronDown
                  className={{ height: "0.25rem", width: "0.25rem" }}
                />
              </div>
              <ul className="header-business-types">
                <div className="flex flex-row w-full p-4">
                  <div className="flex flex-row gap-4 px-6 flex-1">
                    {businessTypesNavs.children.map((item) => (
                      <div className="flex flex-col">
                        <Link
                          className="mb-4 text-[#00215D] font-[Roboto] font-[500] no-underline no-underline text-[16px] hover:text-primary-900"
                          to={item.link}
                        >
                          {item.name}
                        </Link>
                        <div className="flex flex-col gap-2">
                          {item.links.map((i) => (
                            <Link
                              to={i.link}
                              className="pl-1 w-full hover:bg-orange-50  font-[Roboto] font-[400] text-[14px] text-[#6B6B6B] no-underline py-2 whitespace-normal"
                            >
                              {i.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="border-l border-[#A2A2A2] px-6 flex flex-col gap-4 w-48">
                    <span className="text-[#00215D] font-[Roboto] font-[500] no-underline no-underline text-[16px]">
                      {t("Blogs")}
                    </span>
                    <div className="flex flex-col gap-4">
                      {blogsLists.slice(0, 2).map((item, index) => (
                        <Link to={`/blogs/${index}`} className="no-underline">
                          <div className="flex flex-col gap-2">
                            <div className="w-full h-[97px]">
                              <img
                                src={item.sections[0].image}
                                className="h-full w-full rounded-sm"
                              />
                            </div>
                            <div className="flex flex-row justify-between">
                              <span className="text-[#00215D] font-[700] font-[Roboto] text-[13px] leading-[22px] whitespace-normal">
                                {ReactHtmlParser(
                                  item.sections[0].title.substring(0, 55)
                                )}
                                ...
                              </span>
                              <ArrowOrangeRight />
                            </div>
                            <span className="text-[#6B6B6B] font-[400] font-[Roboto] text-[13px] whitespace-normal">
                              {item.sections[0].contents[0].length <= 55
                                ? ReactHtmlParser(item.sections[0].contents[0])
                                : ReactHtmlParser(
                                    item.sections[0].contents[0].substring(
                                      0,
                                      55
                                    )
                                  ) + "...."}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li className="nav-links">
              <Link
                to="/accounting"
                className={`${
                  screen === "why-diglog"
                    ? "link-active nav-link-title whitespace-normal"
                    : "link nav-link-title whitespace-normal"
                } text-white`}
              >
                {t("Accounting")}
              </Link>
            </li>
            <li className="nav-links">
              <Link
                to="/b2b-e-commerce"
                className={`${
                  screen === "why-diglog"
                    ? "link-active nav-link-title whitespace-normal"
                    : "link nav-link-title whitespace-normal"
                } text-white`}
              >
                {t("B2B E-Commerce")}
              </Link>
            </li>
            {/* <li className="nav-links">
              <Link
                to="/why-diglog"
                className={`${
                  screen === "why-diglog" ? "link-active nav-link-title whitespace-normal" : "link nav-link-title whitespace-normal"
                } text-white`}
              >
                {t("header.whyDizLog")}
              </Link>
            </li> */}
            {/* <li className="nav-links">
              <div className="nav-link-title">
                {t("header.partner")}
                <ChevronDown
                  className={{ height: "0.25rem", width: "0.25rem" }}
                />
              </div>
              <ul className="dropdown-list bg-white">
                <li className="border-b p-2">
                  <Link
                    to="/partner-program"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("header.parnterProgram")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <a
                    href={"https://dizlog.leaddyno.com"}
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                    onClick={() => {
                      window.gtag("event", "conversion", {
                        send_to: "AW-10829576507/Pb3tCMv4n7UDELvy-Kso",
                      });
                    }}
                  >
                    {t("header.becomePartner")}
                  </a>
                </li>
              </ul>
            </li> */}
            <li className="nav-links">
              <div className="nav-link-title">
                {t("header.resources")}
                <ChevronDown
                  className={{ height: "0.25rem", width: "0.25rem" }}
                />
              </div>
              <ul className="dropdown-list bg-white">
                <li className="border-b p-2">
                  <Link
                    to="/marketplace"
                    className={
                      screen === "marketplace"
                        ? "text-[#6B6B6B] font-sans no-underline no-underline text-[13px] link-active"
                        : "text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                    }
                  >
                    {t("header.marketplace")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/get-help"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("header.faq")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <a
                    href={helpCenterLink}
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("header.helpCenter")}
                  </a>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/logistics-partners"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("Logistics")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/payment-partners"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("Payments")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/regional-offices"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("Regional Offices")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/blogs"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("Blogs")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/live-demo"
                    className="text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                  >
                    {t("Live Demo")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-links">
              <div
                className={
                  screen === "get-help"
                    ? "nav-link-title link-active"
                    : "nav-link-title"
                }
              >
                <Link
                  to="/pricing"
                  className={`${
                    screen === "pricing" ? "link link-active" : "link"
                  } text-white`}
                >
                  {t("header.pricing")}
                </Link>
              </div>
            </li>
            <li className="nav-links">
              <div className="nav-link-title">
                {t("Support")}
                <ChevronDown
                  className={{ height: "0.25rem", width: "0.25rem" }}
                />
              </div>
              <ul className="dropdown-list bg-white">
                <li className="border-b p-2">
                  <Link
                    to="/contact-us"
                    className={
                      screen === "marketplace"
                        ? "text-[#6B6B6B] font-sans no-underline no-underline text-[13px] link-active"
                        : "text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                    }
                  >
                    {t("Contact Us")}
                  </Link>
                </li>
                <li className="border-b p-2">
                  <Link
                    to="/video-support"
                    className={
                      screen === "marketplace"
                        ? "text-[#6B6B6B] font-sans no-underline no-underline text-[13px] link-active"
                        : "text-[#6B6B6B] font-sans no-underline no-underline text-[13px]"
                    }
                  >
                    {t("Video Support")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-links-mobile align-self-center">
              <div className="dropdown pr-[4rem]">
                <div
                  className="get-started-header text-white cursor-pointer dropdown-toggle"
                  data-bs-toggle="dropdown"
                  style={{ marginLeft: "10px" }}
                >
                  {t("header.language")}
                </div>
                <ul
                  className="dropdown-menu language-list bg-white"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button className="link" onClick={() => redirectTo("/id")}>
                      Bahasa
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/")}>
                      English
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/es")}>
                      French
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/fr")}>
                      Spanish
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/ms")}>
                      Bahasa Melayu
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/jp")}>
                      Japanese
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/cn")}>
                      Chinese
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/vi")}>
                      Vietnamese
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/hi")}>
                      Hindi
                    </button>
                  </li>
                  <li>
                    <button className="link" onClick={() => redirectTo("/bn")}>
                      Bengali
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div className="">
            <div className="flex justify-end">
              <Link
                to="https://meetings.hubspot.com/support-diglog"
                target="_blank"
                className="no-underline"
              >
                <div className="mr-2 rounded-md text-[14px] font-[700] font-[Roboto] text-center flex justify-center items-center p-2 px-3 cursor-pointer bg-white text-[#f09000]">
                  {t("Request Free Demo")}
                </div>
              </Link>
              <div
                className="rounded-md text-[14px] font-[700] font-[Roboto] text-center flex justify-center items-center p-2 px-3 cursor-pointer bg-white text-[#f09000]"
                onClick={onSignIn}
              >
                {t("Get Started")}
              </div>

              <div className="flex">
                {!window.location.hostname.endsWith(".my") && (
                  <div className="dropdown">
                    <div
                      className="language-header cursor-pointer flex dropdown-toggle text-white text-[14px] font-[Roboto] font-[700]"
                      data-bs-toggle="dropdown"
                      style={{ marginLeft: "10px", border: "none !important" }}
                    >
                      {activeLanguage()?.country != undefined
                        ? activeLanguage()?.country
                        : "USA"}
                      (
                      {activeLanguage()?.lang != undefined
                        ? activeLanguage().lang
                        : "English"}
                      )
                    </div>
                    <ul
                      className="dropdown-menu language-list bg-white"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {countryLang.map((lang, idx) => (
                        <li>
                          <button
                            className="link"
                            onClick={() => {
                              localStorage.setItem(
                                "codeAlpha2",
                                lang.codeAlpha2
                              );
                              localStorage.setItem("currency", lang.currency);
                              redirectTo(`/${lang.link}`);
                            }}
                          >
                            {lang.country} ({lang.lang})
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showGetStartedModal && (
            <GetStartedModal
              showModal={showGetStartedModal}
              toggle={onGetStarted}
            />
          )}
        </div>
        <div className="flex xl:hidden lg:hidden sm:flex justify-end w-full px-1 items-center">
          {/* mobile nav */}
          <div className="no-underline flex justify-center  cursor-pointer items-center mr-auto">
            <Link to="/">
              <img className="h-7 w-auto pr-2" src={dizlogLogo} alt="logo" />
            </Link>
          </div>

          <svg
            onClick={() => setIsOpen(true)}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#FFFFFF"
            width="38"
            height="38"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zM3 13h18v-2H3v2zM3 6v2h18V6H3z" />
          </svg>

          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Example Modal"
            // className="min-h-[300px]"
          >
            {navsAndLInks.map((item) => (
              <div className="py-2 ">
                {!item.childrenHasLinks ? (
                  <>
                    {" "}
                    {item.link == "" ? (
                      <div>
                        <span className="font-[Raleway] font-[700] text-base text-[#f09000]">
                          {item.name}
                        </span>
                        <div className="pl-3 flex flex-col">
                          {item.children.map((child) => (
                            <>
                              {child.link != "" ? (
                                <span className="font-[Raleway] py-1 font-[400] text-sm ">
                                  <Link
                                    to={child.link}
                                    className="no-underline text-orange-400"
                                  >
                                    - {child.name}
                                  </Link>
                                </span>
                              ) : (
                                <span className="font-[Raleway] py-1 font-[400] text-sm ">
                                  <a
                                    href={child.externalLink}
                                    className="no-underline text-orange-400"
                                  >
                                    - {child.name}
                                  </a>
                                </span>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <span className="font-[Raleway] font-[700] text-base ">
                        <Link
                          className="no-underline text-[#f09000]"
                          to={item.link}
                        >
                          {item.name}
                        </Link>
                      </span>
                    )}
                  </>
                ) : (
                  <div>
                    <span className="font-[Raleway] font-[700] text-base text-[#f09000]">
                      {item.name}
                    </span>
                    <div className="pl-3 flex flex-col ">
                      {item.children.map((child) => (
                        <div className="my-2">
                          <Link
                            className="font-[Raleway] font-[600] text-base text-[#f09000] no-underline"
                            to={child.link}
                          >
                            {child.name}
                          </Link>
                          <div className="flex flex-col">
                            {child.links.map((subChild) => (
                              <span className="font-[Raleway] py-1 font-[400] text-sm ">
                                <Link
                                  to={subChild.link}
                                  className="no-underline text-orange-400"
                                >
                                  - {subChild.name}
                                </Link>
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </Modal>
        </div>
      </nav>
    </>
  );
}

export default Header;
