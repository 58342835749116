import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddSquare from '../../assets/add-square.png'
import MinusSquare from '../../assets/minus-square.png'

const TwoColumnTable = ({data}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState(3);

  const handleClickView = () => {
    setRows(rows < data.rows.length ? data.rows.length : 3);
  }

  return (
    <section className='bg-[#F6F6FF] flex flex-col items-center py-8 px-4'>
      <div className='max-w-4xl flex flex-col'>
        <div className='w-full flex flex-row gap-8 py-4'>
          <div className='w-1/2 text-[#00215D] font-semibold font-[Raleway] text-xl'>
            {data.title}
          </div>
          <div className='w-1/2 text-[#6B6B6B] font-normal font-[Roboto] text-xs'>
            {data.description}
          </div>
        </div>
        <div className='w-full flex flex-row gap-8 border-b-2 border-solid border-[#A2A2A2] py-4'>
          {data.headers.map((header, index) => (
            <div key={index} className='w-1/2 text-sm text-primary-900 font-bold'>
              {header}
            </div>
          ))}
        </div>
        {data.rows.slice(0, rows).map((row, index) => (
          <div key={index} className='w-full flex flex-row gap-8 border-b-2 border-solid border-[#A2A2A2] py-4'>
            <div className='w-1/2 flex flex-row items-center gap-2'>
              <div className='rounded-full bg-white border border-solid w-8 h-8 flex justify-center items-center'>
                {index + 1}
              </div>
              <div className='text-[#00215D] font-semibold font-[Raleway] text-lg'>
                {row.objective}
              </div>
            </div>
            <div className='w-1/2 flex flex-col'>
              {row.solution.map((el, idx) => (
                <div key={idx} className='text-[#6B6B6B] font-normal font-[Roboto] text-xs'>
                  {el}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className='flex flex-row py-4'>
          <div onClick={handleClickView} className='text-primary-900 cursor-pointer text-xs font-bold items-center'>
            {rows < data.rows.length ? t("View More") : t("View Less")}
            <img src={rows < data.rows.length ? AddSquare : MinusSquare} alt="view-more" className='inline-block w-4 h-4 ml-1' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnTable