import React from "react";
import "./ModalComponent.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import closeSvg from '../assets/close.svg'

function ModalComponent({ showModal, toggle,contents }) {
  console.log("modal");

  return (
    <Modal isOpen={showModal} toggle={toggle} dialogClassName="custom-modal-style" centered size="xl">
      {/* <ModalHeader toggle={toggle}>f</ModalHeader> */}
      <div className="h-3  pt-3 flex justify-end items-center">
        <span  onClick={() => toggle()} className="text-[12px] mr-5 text-white font-bold cursor-pointer
        p-1 w-6 text-center rounded-full bg-red-500">X</span>
      </div>
      <ModalBody style={{ width: "100%" }}>
        <div className="modalCont">
         {contents}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalComponent;
