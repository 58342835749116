import React from "react";
import cx from "clsx";
import TrustedClientList from "../TrustedClientList";

const OurTestimonialsV2 = ({ className }) => {
  return (
    <div
      className={cx(
        "p-4 md:p-8 min-h-[600px] w-full flex flex-col justify-center items-center gap-0",
        className
      )}
    >
      <div className="flex justify-center items-center flex-col gap-2 pt-4">
        <span className="font-[600] font-[Roboto] text-[20px] text-[#F09000] mt-2">
          Our Testimonials
        </span>
      </div>
      <TrustedClientList />
    </div>
  );
};

export default OurTestimonialsV2;
