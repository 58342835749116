import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import dizCatering from "../assets/dizCatering.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import discountPizza from "../assets/discountPizza.png";
import garlicButterSteak from "../assets/garlicButterSteak.png";
import shieldIcon from "../assets/shieldIcon.png";
import menuIcon from "../assets/menuIcon.png";
import kitchenIcon from "../assets/kitchenIcon.png";
import topItemCater from "../assets/topItemCater.png";
import paymentSS from "../assets/paymentSS.png";
import checkoutFood from "../assets/checkoutFood.png";
import empShifts from "../assets/empShifts.png";
import thumbStar from "../assets/thumbStar.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import melanieGabriel from "../assets/melanieGabriel.png";
import dominicCastro from "../assets/dominicCastro.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function FoodServiceCatering() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "447px",
      image: {
        src: garlicButterSteak,
        height: "273.41px",
      },
      title: t("Menu Customization and Diversity"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: menuIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Enhanced Menu Variety"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Craft a diverse and appealing menu that caters to different tastes, dietary needs, and preferences."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Easy Item Customization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Allow seamless customization of dishes, including ingredients, sides, and preparation methods, to suit individual customer choices."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Specialized Diet Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create menu options for various dietary requirements, such as vegetarian, vegan, gluten-free, and allergen-sensitive choices."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "473.68px",
      image: {
        src: checkoutFood,
        height: "313.68px",
      },
      title: t("Efficient Kitchen Operations"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: kitchenIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Order Processing"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Optimize the order flow from kitchen to table, reducing waiting times and ensuring prompt service."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Error Reduction in Orders"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Minimize order errors and enhance accuracy by digitally transmitting customer requests to the kitchen."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Kitchen Communication"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Facilitate instant communication between servers and kitchen staff, enhancing coordination and order fulfillment."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "427.19px",
      image: {
        src: discountPizza,
        height: "267.19px",
      },
      title: t("Customer Satisfaction and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: thumbStar,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("ThumbStar"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize customer preferences to offer tailored recommendations and create memorable dining experiences."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Special Offers"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement loyalty programs and exclusive offers to incentivize repeat visits and reward customer loyalty."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "447px",
      image: {
        src: paymentSS,
        height: "284px",
      },
      title: t("Payment Convenience and Security"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: shieldIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Varied Payment Methods"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Accept diverse payment options, including cash, cards, and digital wallets, for customer convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Swift and Secure Transactions"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px]",
          description: t(
            "Ensure quick and secure payment processing, fostering trust and providing a seamless checkout experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Contactless Payment Adoption"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px]",
          description: t(
            "Implement contactless payment solutions to meet changing customer preferences and health considerations."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "##004AAD",
      minHeight: "454px",
      image: {
        src: empShifts,
        height: "290.78px",
      },
      title: t("Staff Efficiency"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Shift Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Track employee shifts and optimize scheduling for efficient staffing during peak and off-peak hours."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Simplified Payroll Handling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline payroll by accurately recording work hours, minimizing administrative complexity."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FEF7EC",
      minHeight: "483.38px",
      image: {
        src: topItemCater,
        height: "323.38px",
      },
      title: t("Insights and Decision-making"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Data-driven Analytics"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Gather insights on popular dishes, peak dining hours, and customer preferences to inform menu adjustments and marketing strategies."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Informed Business Growth"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px]",
          description: t(
            "Utilize data analytics to plan expansion, promotions, and business development initiatives for sustained growth."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Evaluation"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px]",
          description: t(
            "Evaluate key performance metrics to continuously refine operations, enhance customer satisfaction, and drive success."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“Words cannot express how grateful we are for DizLog POS and its impact on our food catering business. This system has truly been a game-changer and has revolutionized the way we manage our catering operations. DizLog POS has significantly improved our order management process.”"
      ),
      name: t("Melanie Gabriel"),
      designation: t("Food Catering Services Owner"),
      imageSrc: melanieGabriel,
    },
    {
      content: t(
        "“Managing our catering staff has become much easier with DizLog POS. The employee management tools allow us to schedule shifts, track performance, and assign specific tasks. This has improved our team's coordination and communication, ensuring smooth operations during catering events.”"
      ),
      name: t("Dominic Castro"),
      designation: t("Food Catering Services Owner"),
      imageSrc: dominicCastro,
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div
            className="min-h-[366px] bg-[#FEF7EC] flex 
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-end justify-center pt-4 px-4 sm:px-0">
              <img src={dizCatering} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Food Services and Catering")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS offers a comprehensive and efficient solution that can significantly benefit food catering businesses in various ways. With its user-friendly interface and mobile compatibility, DizLog POS simplifies the order management process, allowing catering staff to take orders quickly and accurately through tablets or smartphones.This streamlines operations during busy events and ensures a seamless customer experience. The inventory management feature helps catering businesses stay on top of ingredient levels in real-time, preventing stockouts and minimizing food wastage."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default FoodServiceCatering;
