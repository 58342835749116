import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import blogsLists from "../utils/blogsList";

function SingleContentComponentWithBulletList(props) {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();

  const { index } = useParams();

  //   dataprops
  let sampleProps = {
    isImageRight: true,
    backgroundColor: "",
    minHeight: "",
    image: {
      src: "",
      height: "",
    },
    title: "",
    titleClassNames: "",
    titleImg: "",
    titleImgClassNames: "",
    titleDesc: "",
    titleDescImgClassNames: "",
    bullets: [
      {
        icon: "",
        title: "",
        titleClassNames: "",
        description: "",
        descriptionClassNames: "",
      },
    ],
  };
  let dataprops = props.data;
  return (
    // image is on left section
    <section>
      <div
        className={`    min-h-[442px] py-7 w-full bg-[#004AAD] flex
            flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row
            ${
              dataprops.backgroundColor
                ? `bg-[${dataprops.backgroundColor}]`
                : ""
            }
            ${dataprops.minHeight ? `min-h-[${dataprops.minHeight}]` : ""}
            ${
              dataprops.isImageRight
                ? "xl:flex-row-reverse lg:flex-row-reverse md:flex-row-reverse sm:flex-row-reverse"
                : ""
            }
            `}
      >
        <div className="flex flex-1 justify-center items-center">
          <img
            src={dataprops.image.src}
            className={`${
              dataprops.image.height ? `h-[${dataprops.image.height}]` : ""
            }   w-auto`}
          />
        </div>
        <div
          className={`flex flex-1 justify-center flex-col
          pt-2 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0 pl-4 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0
          ${dataprops.isImageRight ? " items-end" : "items-start"}`}
        >
          <div className="
          flex flex-col w-full xl:w-[90%] lg:w-[80%] md:w-[80%] sm:w-full">
            <span
              className={`hidden xl:flex lg:flex md:flex sm:flex 
              font-[Raleway] font-[700] text-base xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] flex items-center mb-3
          ${dataprops.titleClassNames ? dataprops.titleClassNames : ""}`}
            >
              <img
                src={dataprops.titleImg}
                className={`h-[40px] w-[40px] mr-2 ${
                  dataprops.titleImgClassNames
                    ? dataprops.titleImgClassNames
                    : ""
                }`}
              />
              {dataprops.title}
            </span>
            <span
              className={`mt-2 block xl:hidden lg:hidden md:hidden sm:hidden 
              font-[Raleway] font-[700] text-base xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] flex items-center mb-3
          ${dataprops.titleClassNames ? dataprops.titleClassNames.replace(/\s*leading\s*/, ' ').replace(/\s*text-\s*/, ' ') : ""} leading-[21px]`}
            >
              <img
                src={dataprops.titleImg}
                className={`h-[40px] w-[40px] mr-2 ${
                  dataprops.titleImgClassNames
                    ? dataprops.titleImgClassNames
                    : ""
                }`}
              />
              {dataprops.title}
            </span>

            {dataprops.titleDesc ? (
              <span
                className={`text-white text-[12px] font-[400] font-[Roboto] py-2 ${
                  dataprops.titleDescImgClassNames
                    ? dataprops.titleDescImgClassNames
                    : ""
                }`}
              >
                {dataprops.titleDesc}
              </span>
            ) : null}

            <div className="flex w-full flex-col bullettts">
              <div className="">
                {dataprops.bullets
                  ? dataprops.bullets.map((item) => (
                      <div className="flex py-2 ">
                        <div className=" w-6 items-center flex justify-start">
                          <img src={item.icon} className="h-3 w-auto" />
                        </div>
                        <div className="leading-[18px] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                          <span
                            className={`text-[15px] font-[700] font-[Roboto] ${item.titleClassNames || ""}`}
                          >
                            {item.title}
                          </span>
                          <span
                            className={` text-[14px] font-[400] font-[Roboto] ${
                              item.descriptionClassNames
                                ? item.descriptionClassNames
                                : ""
                            } `}
                          >
                            {item.description}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleContentComponentWithBulletList;
