

let businessTypes = {
    "groups": [
      {
        "key": "Food and Beverage",
        "name": "Food and Beverage"
      },
      {
        "key": "Retail",
        "name": "Retail"
      },
      {
        "key": "Services",
        "name": "Services"
      }
    ],
    "businessTypesByGroup": {
      "Food and Beverage": [
        {
          "key": "Coffee Shop / Café",
          "name": "Coffee Shop / Café"
        },
        {
          "key": "Fastfood / Restaurant",
          "name": "Fastfood / Restaurant"
        },
        {
          "key": "Food Services - Catering",
          "name": "Food Services - Catering"
        },
        {
          "key": "Other Specialty Food Stores",
          "name": "Other Specialty Food Stores"
        }
      ],
      "Retail": [
        {
          "key": "Arts / Crafts / Novelty",
          "name": "Arts / Crafts / Novelty"
        },
        {
          "key": "Bike / Motor Vehicles Supplies and Parts",
          "name": "Bike / Motor Vehicles Supplies and Parts"
        },
        {
          "key": "Clothing / Footwear Store",
          "name": "Clothing / Footwear Store"
        },
        {
          "key": "Construction and Hardware Equipment and Supplies",
          "name": "Construction and Hardware Equipment and Supplies"
        },
        {
          "key": "Department / General Merchandise / Retail",
          "name": "Department / General Merchandise / Retail"
        },
        {
          "key": "Goods and Grocery Store",
          "name": "Goods and Grocery Store"
        },
        {
          "key": "Leisure and Entertainment",
          "name": "Leisure and Entertainment"
        },
        {
          "key": "Manufacturing",
          "name": "Manufacturing"
        },
        {
          "key": "Other Retail Stores",
          "name": "Other Retail Stores"
        }
      ],
      "Services": [
        {
          "key": "Accounting, Auditing and Book keeping",
          "name": "Accounting, Auditing and Book keeping"
        },
        {
          "key": "Beauty and Cosmetic Services",
          "name": "Beauty and Cosmetic Services"
        },
        {
          "key": "Cleaning / Washing Services",
          "name": "Cleaning / Washing Services"
        },
        {
          "key": "Computer, Data Processing, Design, Information & Comm Services",
          "name": "Computer, Data Processing, Design, Information & Comm Services"
        },
        {
          "key": "Healthcare and Medical Services",
          "name": "Healthcare and Medical Services"
        },
        {
          "key": "Metal, Electrical, Plumbling and Heating",
          "name": "Metal, Electrical, Plumbling and Heating"
        },
        {
          "key": "Other Professional or Specialized Services",
          "name": "Other Professional or Specialized Services"
        },
        {
          "key": "Transportation and storage",
          "name": "Transportation and storage"
        },
        {
          "key": "Water Supply & Sewerage, Waste Management and Remediation Activities",
          "name": "Water Supply & Sewerage, Waste Management and Remediation Activities"
        }
      ]
    },
    "allTypes": [
      {
        "key": "Accounting, Auditing and Book keeping",
        "name": "Accounting, Auditing and Book keeping"
      },
      {
        "key": "Arts / Crafts / Novelty",
        "name": "Arts / Crafts / Novelty"
      },
      {
        "key": "Beauty and Cosmetic Services",
        "name": "Beauty and Cosmetic Services"
      },
      {
        "key": "Bike / Motor Vehicles Supplies and Parts",
        "name": "Bike / Motor Vehicles Supplies and Parts"
      },
      {
        "key": "Cleaning / Washing Services",
        "name": "Cleaning / Washing Services"
      },
      {
        "key": "Clothing / Footwear Store",
        "name": "Clothing / Footwear Store"
      },
      {
        "key": "Coffee Shop / Café",
        "name": "Coffee Shop / Café"
      },
      {
        "key": "Computer, Data Processing, Design, Information & Comm Services",
        "name": "Computer, Data Processing, Design, Information & Comm Services"
      },
      {
        "key": "Construction and Hardware Equipment and Supplies",
        "name": "Construction and Hardware Equipment and Supplies"
      },
      {
        "key": "Department / General Merchandise / Retail",
        "name": "Department / General Merchandise / Retail"
      },
      {
        "key": "Fastfood / Restaurant",
        "name": "Fastfood / Restaurant"
      },
      {
        "key": "Food Services - Catering",
        "name": "Food Services - Catering"
      },
      {
        "key": "Goods and Grocery Store",
        "name": "Goods and Grocery Store"
      },
      {
        "key": "Healthcare and Medical Services",
        "name": "Healthcare and Medical Services"
      },
      {
        "key": "Leisure and Entertainment",
        "name": "Leisure and Entertainment"
      },
      {
        "key": "Manufacturing",
        "name": "Manufacturing"
      },
      {
        "key": "Metal, Electrical, Plumbling and Heating",
        "name": "Metal, Electrical, Plumbling and Heating"
      },
      {
        "key": "Other Professional or Specialized Services",
        "name": "Other Professional or Specialized Services"
      },
      {
        "key": "Other Retail Stores",
        "name": "Other Retail Stores"
      },
      {
        "key": "Other Specialty Food Stores",
        "name": "Other Specialty Food Stores"
      },
      {
        "key": "Transportation and storage",
        "name": "Transportation and storage"
      },
      {
        "key": "Water Supply & Sewerage, Waste Management and Remediation Activities",
        "name": "Water Supply & Sewerage, Waste Management and Remediation Activities"
      }
    ]
  }

export default businessTypes;
