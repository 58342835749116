import howTOAddTOCartItems from "../assets/howTOAddTOCartItems.png";
import dizLogPaymentPOS from "../assets/dizLogPaymentPOS.png";
import fnBOnBoarding from "../assets/fnBOnBoarding.png";
import howTOaddItemsToCart from "../assets/howTOaddItemsToCart.png";
import howToaddProductCheckout from "../assets/howToaddProductCheckout.png";
import howTOcreateSIngleItemFnB from "../assets/howTOcreateSIngleItemFnB.png";
import howToCreateNewCat from "../assets/howToCreateNewCat.png";
import onboardingFnB from "../assets/onboardingFnB.png";
import processServiceApointment from "../assets/processServiceApointment.png";
import bulkuploadsProductsServices from "../assets/bulkuploadsProductsServices.png";
import createCatServiceBusiness from "../assets/createCatServiceBusiness.png";
import onBoardingProcessServiceBusiness from "../assets/onBoardingProcessServiceBusiness.png";
import howToCreateSIngleItemService from "../assets/howToCreateSIngleItemService.png";
import howTOTrackOrderStatus from "../assets/howTOTrackOrderStatus.png";
import howTOopenAndCLoseRegister from "../assets/howTOopenAndCLoseRegister.png";
import howToCreateCashRegister from "../assets/howToCreateCashRegister.png";
import howToSetUpReceipt from "../assets/howToSetUpReceipt.png";
import howToEnablePartialPayments from "../assets/howToEnablePartialPayments.png";
import enablePaymentMethods from "../assets/enablePaymentMethods.png";
import trasnferStockToLOcations from "../assets/trasnferStockToLOcations.png";
import page3_1 from "../assets/page3_1.png";
import page3_2 from "../assets/page3_2.png";
import page3_4 from "../assets/page3_4.png";
import page3_5 from "../assets/page3_5.png";
import page3_7 from "../assets/page3_7.png";
import page3_8 from "../assets/page3_8.png";
import page3_9 from "../assets/page3_9.png";
import page3_10 from "../assets/page3_10.png";
import page3_11 from "../assets/page3_11.png";
import page3_6 from "../assets/page3_6.png";
import page4_1 from "../assets/page4_1.png";
import page4_2 from "../assets/page4_2.png";
import page4_3 from "../assets/page4_3.png";
import page4_4 from "../assets/page4_4.png";
import page4_5 from "../assets/page4_5.png";
import page4_6 from "../assets/page4_6.png";
import page4_7 from "../assets/page4_7.png";
import page4_8 from "../assets/page4_8.png";
import page4_9 from "../assets/page4_9.png";
import page4_10 from "../assets/page4_10.png";

let videoSupportList = [
  {
    title:
      "Onboarding Process for Service Business with Dizlog All-in-one POS - WEB",
    description:
      "Simplify the onboarding process for your service business with the Dizlog All-in-one POS system. Our intuitive platform is specifically designed to streamline operations and optimize efficiency from day one. With a user-friendly interface and robust features tailored for service-based businesses, you can effortlessly manage appointments, track employee schedules, process payments, and generate detailed reports. Our dedicated onboarding team will guide you through each step, ensuring a smooth transition and providing comprehensive training to maximize the benefits of Dizlog POS. Say goodbye to manual processes and embrace a seamless onboarding experience that empowers your service business to thrive.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/_hIyJN1X604" title="Onboarding Process for Service Business with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: fnBOnBoarding,
  },
  {
    title: "How to Enable Dizlog Offline Payment on POS",
    description:
      "Enabling Dizlog offline payment on your POS is a straightforward process that ensures uninterrupted transactions, even when an internet connection is unavailable. With offline payment capabilities, you can continue to serve your customers and accept payments seamlessly, regardless of connectivity issues. This convenient functionality guarantees a smooth payment experience for both you and your customers, providing peace of mind and maintaining business continuity.",
    embedCode: `<iframe width="1214" height="520" src="https://www.youtube.com/embed/sXEwY04hqSQ" title="How to Enable Dizlog Offline Payment on POS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: dizLogPaymentPOS,
  },
  {
    title:
      "How to Add items to Cart and Checkout with Dizlog All-in-one POS - WEB",
    description:
      "Adding items to your cart and proceeding to checkout with Dizlog All-in-one POS - WEB is a seamless process that enhances the efficiency of your business operations. Utilizing the user-friendly interface, simply navigate to the product catalog and browse through the available items. Once you have found the desired product, click on it to add it to your cart. You can adjust quantities and apply any applicable discounts or promotions. Once your cart is complete, proceed to the checkout section, where you can review the items, select payment options, and provide necessary customer details. With just a few clicks, you can finalize the transaction and generate an electronic receipt for your records. Dizlog All-in-one POS - WEB empowers you to swiftly and effortlessly manage the entire shopping experience, enhancing customer satisfaction and streamlining your business operations.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/D-ra9sZDyJM" title="How to Add items to Cart and Checkout with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howTOAddTOCartItems,
  },
  {
    title:
      "How to Track Order Status in Order Manager with Dizlog All-in-one POS!- WEB",
    description:
      "Tracking the status of your orders becomes a breeze with Dizlog All-in-one POS - WEB's Order Manager feature. This powerful tool enables you to efficiently monitor and manage the progress of your orders in real-time. Simply access the Order Manager section within the POS system and locate the specific order you wish to track. The intuitive interface provides clear and detailed information about the order, including the current status, fulfillment progress, and estimated delivery or completion date. You can also view any notes or updates associated with the order. This level of visibility empowers you to keep your customers informed about their order status, proactively address any issues that may arise, and ensure timely delivery or service. ",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/rEUCUSu978w" title="How to Track Order Status in Order Manager with Dizlog All-in-one POS!- WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howTOaddItemsToCart,
  },
  {
    title:
      "How to Add Products to Cart and Checkout in Dizlog All-in-one POS - WEB",
    description:
      "Adding products to your cart and proceeding to checkout in Dizlog All-in-one POS - WEB is a simple and efficient process that enhances the shopping experience for both you and your customers. Using the intuitive interface, navigate to the product catalog and browse through the available items. Once you have found the desired product, click on it to add it to your cart. Once your cart is complete, proceed to the checkout section, where you can review the selected items, select payment options, and provide necessary customer details such as shipping address or contact information. ",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/Q9wMABI0gIA" title="How to Add Products to Cart and Checkout in Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToaddProductCheckout,
  },
  {
    title:
      "How to Create Single Item for F & B Service with Dizlog All-in-one POS - WEB",
    description:
      "Creating a single item for food and beverage (F&B) service using Dizlog All-in-one POS - WEB is a straightforward process that streamlines menu management and enhances order accuracy. With the intuitive interface, accessing the menu management section allows you to easily add new items to your F&B offerings. Simply input the item's name, description, price, and any relevant modifiers or options. You can also upload an enticing image to showcase the item visually. Additionally, you have the flexibility to assign the item to specific categories or sections within your menu. This organization ensures easy navigation for both your staff and customers. Once the single item is created, it becomes readily available for selection during the ordering process, making it effortless to add to a customer's cart or bill. Dizlog All-in-one POS - WEB empowers F&B establishments to efficiently manage their menu items, optimize order accuracy, and provide a seamless dining experience.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/rBCIB7LjAxA" title="How to Create Single Item for F &amp; B Service with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howTOcreateSIngleItemFnB,
  },
  {
    title:
      "How to Create New Category for F & B Service with Dizlog All-in-one POS - WEB",
    description:
      "With the user-friendly interface, accessing the menu management section allows you to effortlessly add a new category. Simply provide a name for the category and assign any relevant items to it. This allows you to group similar F&B offerings together, such as appetizers, main courses, desserts, or beverages. You can also customize the display order of the categories to match your preferred menu layout. This categorization enables seamless navigation for both your staff and customers, making it easier to locate and select items from the menu. With Dizlog All-in-one POS - WEB, creating new categories for F&B service is a breeze, allowing you to streamline your menu organization and provide a more efficient and enjoyable dining experience.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/MsrMtBiHsaw" title="How to Create New Category for F &amp; B Service with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToCreateNewCat,
  },
  {
    title:
      "Onboarding Process for F & B Service with Dizlog All-in-one POS - WEB",
    description:
      "Streamline the onboarding process for your food and beverage (F&B) service with Dizlog All-in-one POS - WEB. Our comprehensive platform simplifies the transition and optimizes efficiency from the start. With a user-friendly interface and tailored features for F&B establishments, you can effortlessly set up your menu, manage inventory, process orders, and track sales. Our dedicated onboarding team will guide you through each step, ensuring a smooth integration of the POS system into your operations. They will provide comprehensive training to your staff, enabling them to utilize the full potential of Dizlog All-in-one POS - WEB. From configuring menu items and modifiers to customizing table layouts and managing reservations, our platform equips you with the tools to streamline your F&B service. Embrace a seamless onboarding experience with Dizlog All-in-one POS - WEB and enhance the efficiency and success of your F&B establishment.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/ZC4YMSBTmVU" title="Onboarding Process for F &amp; B Service with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: onboardingFnB,
  },
  {
    title:
      "How to Process Service Appointment with Dizlog All-in-one POS - WEB",
    description:
      "Efficiently process service appointments with ease using Dizlog All-in-one POS - WEB. With its intuitive interface and powerful features, managing service appointments becomes a seamless task. Begin by accessing the appointment management section, where you can view and schedule appointments for your services. Simply select the desired date and time slot, choose the service, and assign it to the appropriate staff member. You can also add customer details, notes, and any additional requirements for the appointment. Dizlog All-in-one POS - WEB allows you to track and update the status of each appointment, ensuring a smooth flow of operations. When the appointment is completed, you can effortlessly generate an invoice, process payment, and provide a receipt to the customer. This comprehensive solution simplifies the service appointment process, optimizing your business operations and enhancing customer satisfaction.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/x28HA-_eXyk" title="How to Process Service Appointment with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: processServiceApointment,
  },
  {
    title:
      "How to do Bulk Uploads for Products and Services with Dizlog All-in-one POS - WEB",
    description:
      "Simplify the process of adding a large number of products and services to your Dizlog All-in-one POS - WEB system with the convenient bulk upload feature. This time-saving tool allows you to streamline your operations by uploading multiple items at once. Begin by organizing your product and service data in a compatible format such as CSV (Comma Separated Values) or Excel. Include essential details like names, descriptions, prices, and any variations or options. Next, navigate to the bulk upload section within the POS system and follow the step-by-step instructions to select and upload your prepared file. Dizlog All-in-one POS - WEB will swiftly process the information, creating new items in your product and service catalog. This efficient method ensures accuracy and saves you from the manual entry of individual items.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/u4lYj7-Y-P8" title="How to do Bulk Uploads for Products and Services  with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: bulkuploadsProductsServices,
  },
  {
    title:
      "How to Create New Category for Service Business with Dizlog All-in-one POS - WEB",
    description:
      "Effortlessly organize your service business offerings by creating new categories with Dizlog All-in-one POS - WEB. This powerful feature simplifies menu management and enhances the customer experience. Begin by accessing the menu settings within the POS system and select the option to create a new category. This categorization streamlines the selection process and allows for efficient service browsing. With Dizlog All-in-one POS - WEB, creating new categories for your service business is a breeze, enabling you to provide a seamless and user-friendly experience to your customers.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/OYtz5Jkj9sI" title="How to Create New Category for  Service Business with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: createCatServiceBusiness,
  },
  {
    title:
      "Onboarding Process for Service Business with Dizlog All-in-one POS - WEB",
    description:
      "Our comprehensive platform simplifies the transition and empowers your business to thrive from day one. With a user-friendly interface and tailored features for service-based businesses, you can effortlessly set up your menu, manage appointments, process payments, and track performance. Our dedicated onboarding team will guide you through each step, ensuring a seamless integration of the POS system into your operations. They will provide comprehensive training to your staff, enabling them to utilize the full potential of Dizlog All-in-one POS - WEB. From configuring services and pricing to customizing appointment scheduling and generating reports, our platform equips you with the tools to streamline your service business. Embrace a seamless onboarding experience with Dizlog All-in-one POS - WEB and unlock the efficiency and success of your service business.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/_hIyJN1X604" title="Onboarding Process for Service Business with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: onBoardingProcessServiceBusiness,
  },
  {
    title:
      "How to Create Single Item for Service with Dizlog All-in-one POS - WEB",
    description:
      "Creating a single item for your service-based business using Dizlog All-in-one POS - WEB is a simple and efficient process. With the intuitive interface, access the item management section within the POS system. Begin by providing a name and description for the service you want to create. Specify the duration, price, and any additional details or options that apply. You can also upload an image to visually represent the service. Once you have entered all the necessary information, save the item, and it will be added to your service catalog. This allows you to easily select and offer the service to your customers during the checkout process. Dizlog All-in-one POS - WEB empowers service-based businesses to create and manage their service offerings effortlessly, streamlining operations and enhancing customer satisfaction.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/mAm5t1NhGHo" title="How to Create Single Item for Service with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToCreateSIngleItemService,
  },
  {
    title:
      "How to Track Order Status in Order Manager with Dizlog All-in-one POS!- WEB",
    description:
      "With Dizlog All-in-one POS, manually updating product prices and applying manual discounts during checkout is a breeze. To update a product price, access the item management section in the POS system and locate the specific product. Simply edit the price field with the new desired value and save the changes. This ensures accurate and up-to-date pricing for your products.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/rEUCUSu978w" title="How to Track Order Status in Order Manager with Dizlog All-in-one POS!- WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howTOTrackOrderStatus,
  },
  {
    title:
      "How to Open and Close Cash Register with Dizlog All-in-one POS - WEB",
    description:
      "Throughout the day, the system automatically tracks and records all transactions, including sales, returns, and voids. At the end of the day or shift, return to the cash register management section and select the option to close the cash register. The system will calculate the total sales, cash on hand, and any discrepancies. You can review and reconcile the numbers, ensuring that your cash register is accurately balanced.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/PR_lfOR9wjs" title="How to Open and Close Cash Register with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howTOopenAndCLoseRegister,
  },
  {
    title: "How to Create New Cash Register with Dizlog All-in-one POS - WEB",
    description:
      "Managing your cash registers with Dizlog All-in-one POS - WEB is effortless. To create a new cash register, log in to your account and navigate to the cash register management section. Here, you will find the option to create a new cash register. Simply provide the necessary details such as the register name, location, and any specific notes or instructions. You can also assign the cash register to a specific employee or shift if needed. Once the information is entered, save the settings, and your new cash register is ready to be used. Dizlog All-in-one POS - WEB empowers you to easily create and manage multiple cash registers, enabling efficient cash handling and maintaining organized financial records for your business.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/nlBJbteS50Q" title="How to Create New Cash Register with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToCreateCashRegister,
  },
  {
    title:
      "How to Set up Receipt Display Settings with Dizlog All-in-one POS - WEB",
    description:
      "Customize your receipt display settings with Dizlog All-in-one POS - WEB to create a professional and tailored customer experience. Setting up receipt display preferences is a straightforward process that allows you to showcase relevant information and branding. Simply access the settings menu within the POS system and navigate to the receipt display section. Additionally, you have the flexibility to adjust the layout and formatting of the receipt to match your branding and style.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/dMX1hRKlxUY" title="How to Set up Receipt Display Settings with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToSetUpReceipt,
  },
  {
    title: "How to Enable Partial Payments for Dizlog All-in-one POS - WEB",
    description:
      "Enabling partial payments in Dizlog All-in-one POS - WEB is a convenient feature that allows flexibility in payment transactions. To enable partial payments, access the settings menu within the POS system and navigate to the payment options section. Here, you can toggle on the option to accept partial payments. Once enabled, during the checkout process, you can offer customers the choice to make a partial payment instead of paying the full amount upfront. This is particularly useful for situations where customers may not have the complete payment available or wish to split the payment across different methods. The system will automatically calculate the remaining balance, and you can proceed with completing the transaction.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/zGxo22KJkaM" title="How to Enable Partial Payments for Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: howToEnablePartialPayments,
  },
  {
    title: "How to Enable Payment Methods for Dizlog All-In-One POS- WEB",
    description:
      "Efficiently process service appointments with ease using Dizlog All-in-one POS - WEB. With its intuitive interface and powerful features, managing service appointments becomes a seamless task. Begin by accessing the appointment management section, where you can view and schedule appointments for your services. Simply select the desired date and time slot, choose the service, and assign it to the appropriate staff member. You can also add customer details, notes, and any additional requirements for the appointment. Dizlog All-in-one POS - WEB allows you to track and update the status of each appointment, ensuring a smooth flow of operations. When the appointment is completed, you can effortlessly generate an invoice, process payment, and provide a receipt to the customer. This comprehensive solution simplifies the service appointment process, optimizing your business operations and enhancing customer satisfaction.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/WNCcx888s5M" title="How to Enable Payment Methods for Dizlog All-In-One POS- WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: enablePaymentMethods,
  },
  {
    title:
      "How to Transfer Stocks from One Location to Another with Dizlog All-in-one POS - WEB",
    description:
      "With this powerful feature, managing inventory across multiple locations becomes a seamless process. This ensures accurate inventory management and enables you to keep track of stock movements across your different locations. With Dizlog All-in-one POS - WEB, transferring stocks between locations becomes efficient, enabling you to maintain optimal stock levels and meet the demands of your business seamlessly.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/1R1UV0M0IDc" title="How to Transfer Stocks from One Location to Another with Dizlog All-in-one POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: trasnferStockToLOcations,
  },
  {
    title:
      "How to Adjust, Manage Stocks for Retail Business with Dizlog All-In-One POS - WEB",
    description:
      "This comprehensive platform provides robust features to streamline your inventory management process. To begin, access the inventory management section within the POS system. Here, you can easily view and track your current stock levels for each product. To make adjustments, simply select the specific item and update the quantity on hand based on stock received, sold, or any other changes. Dizlog All-In-One POS - WEB automatically calculates the updated stock levels, ensuring accurate inventory records.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/v1PaQHaKB8c" title="How to Adjust, Manage Stocks for Retail Business with Dizlog All-In-One POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_1,
  },
  {
    title:
      "How to Upload Bulk Products and Services with Dizlog All-In-One POS - WEB",
    description:
      "With the bulk upload feature, you can efficiently expand your offerings and keep your inventory up to date. Dizlog All-In-One POS - WEB simplifies the process of uploading bulk products and services, allowing you to manage and organize your inventory effortlessly. Embrace this powerful tool and streamline your operations for a more efficient and productive business.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/Rm7BLdydogo" title="How to Upload Bulk Products and Services with Dizlog All-In-One POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_2,
  },
  {
    title:
      "How to Add Single Item for Retail Business with Dizlog All-in-One POS - WEB",
    description:
      "By utilizing Dizlog All-in-One POS - WEB, adding a single item to your retail business becomes a streamlined process. Enjoy simplified inventory management and optimize your retail operations for improved efficiency and customer satisfaction.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/wXWwpVqp6R0" title="How to Add Single Item for Retail Business with Dizlog All-in-One POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_4,
  },
  {
    title:
      "How to Create New Category for Retail Business with Dizlog All-in-One POS - WEB",
    description:
      "Once the category is created, you can effortlessly assign products to it. By grouping related items together, you enhance the browsing experience for your customers and facilitate easier navigation through your inventory. Dizlog All-in-One POS - WEB provides the flexibility to create multiple categories, ensuring a comprehensive and organized catalog. With Dizlog All-in-One POS - WEB, creating new categories for your retail business is a breeze, enabling you to streamline inventory management and provide a more intuitive shopping experience for your customers. Take control of your product organization and optimize your retail operations with ease.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/GUY_HdmBszo" title="How to Create New Category for Retail Business with Dizlog All-in-One POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_5,
  },
  {
    title: "Onboarding Process for Retail with Dizlog All-in-One POS - WEB",
    description:
      "The onboarding process for retail businesses with Dizlog All-in-One POS - WEB is seamless and efficient, ensuring a smooth transition to a new point-of-sale system. From the moment you sign up, Dizlog provides comprehensive guidance and support to get your retail operations up and running in no time.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/EP38kC3j9Pg" title="Onboarding Process for Retail with Dizlog All-in-One POS - WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_6,
  },
  {
    title: "Best Small Business Application!",
    description:
      "This application offers a comprehensive suite of tools designed to streamline and optimize key business processes, including inventory management, sales tracking, customer relationship management, invoicing, and financial reporting. Its intuitive design ensures that even non-technical users can easily navigate and utilize its features effectively. What sets DizLog apart is its scalability, allowing small businesses to start with the basic features and expand as their needs grow. Moreover, it offers seamless integration with other essential business tools, such as accounting software and e-commerce platforms, providing a cohesive ecosystem for efficient operations. With excellent customer support and regular updates, DizLog remains at the forefront of small business applications, empowering entrepreneurs to focus on what they do best while maximizing productivity and profitability.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/3bk6C1kuLN4" title="Best Small Business Application!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_7,
  },
  {
    title:
      "How to Make Item Returnable and How to Process Refunds for Returnable and Non Returnable Items",
    description:
      "To ensure a smooth and efficient process for making items returnable and processing refunds, DizLog App provides convenient features. To make an item returnable, sellers can simply enable the 'Returnable' option while listing the product. This allows buyers to have the flexibility of returning the item within a specified timeframe. Upon receiving a return request, sellers can use the DizLog App to process refunds for both returnable and non-returnable items. For returnable items, sellers can initiate a refund through the app by specifying the reason for return and the preferred method of reimbursement. DizLog App streamlines this process by automatically generating a return label, making it hassle-free for sellers and buyers alike.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/mVQbrUvM720" title="How to Make Item Returnable and How to Process Refunds for Returnable and Non Returnable Items" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_8,
  },
  {
    title: "Exciting New Features To Grow Your Business With DizLog!",
    description:
      "DizLog is constantly evolving to provide exciting new features that can help businesses thrive and grow. With its latest updates, DizLog offers a wide range of tools designed to enhance business operations and boost productivity. One of the standout features is the advanced analytics dashboard, which provides detailed insights into sales, customer behavior, and market trends. By harnessing this valuable data, businesses can make informed decisions, optimize strategies, and identify new growth opportunities. Another exciting addition is the customizable storefront feature, allowing businesses to create a unique and engaging online presence.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/7t-p71FnYO8" title="DizLog New Feature: How Suppliers can use DizLog B2B" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_9,
  },
  {
    title:
      "Allow Customers to Pay in Installment for their Purchases with DizLog Partial Payments",
    description:
      "DizLog Partial Payments is an innovative feature that revolutionizes the way customers can make purchases by allowing them to pay in installments. With this exciting addition, businesses can offer their customers greater flexibility and convenience, making high-value or large purchases more accessible. By splitting the total cost into manageable installments, DizLog empowers customers to budget their expenses and enjoy the products they desire without straining their finances. The process is simple and user-friendly, as customers can select the Partial Payments option during checkout and choose the desired installment plan that suits their needs. With DizLog Partial Payments, businesses can attract a wider customer base, increase conversion rates, and ultimately foster long-term relationships with satisfied customers.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/Vv4LG79u09k" title="Allow Customers to Pay in Installment for their Purchases with DigLog Partial Payments" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_10,
  },
  {
    title: "Selling Made Easier with DizLog's New and Improved Point of Sale",
    description:
      "The system is equipped with a wide range of advanced functionalities that streamline the entire sales process. From inventory management to real-time transaction tracking, the POS system provides businesses with comprehensive tools to effectively manage their sales operations. Additionally, DizLog's POS seamlessly integrates with various payment gateways, allowing businesses to accept multiple payment methods and ensuring a hassle-free checkout experience for customers. The system also supports loyalty programs and discounts, empowering businesses to reward their loyal customers and attract new ones. Furthermore, the POS system generates insightful reports and analytics, enabling businesses to gain valuable insights into their sales performance, best-selling products, and customer preferences.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/t1wzdVYuIWE" title="Selling Made Easier with DigLog&#39;s New and Improved Point of Sale #pointofsale" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page3_11,
  },
  {
    title: "Remarkable Online Shopping Experience with DizLog Online Store",
    description:
      "The store's sleek design showcases products in an appealing and organized manner, making it easy for customers to explore and find what they need. DizLog's advanced search and filtering options enable customers to refine their search based on specific criteria, saving them time and effort. The online store also offers detailed product descriptions, high-quality images, and customer reviews, providing valuable information to assist customers in making informed purchasing decisions. Moreover, DizLog's secure payment gateway guarantees a safe and smooth checkout process, inspiring trust and confidence in customers. For businesses, the online store provides powerful inventory management tools, order tracking, and customer data analytics, allowing them to efficiently manage operations and optimize their marketing strategies.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/y7xNm9_GGwo" title="Remarkable Online Shopping Experience with DigLog Online Store #onlineshopping" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_1,
  },
  {
    title: "You Can Sell Different Variants of Your Products with DizLog",
    description:
      "Whether it's different sizes, colors, styles, or any other customizable aspect, DizLog's intuitive interface makes it simple to create and manage product variants. Sellers can easily add and organize different options, providing customers with a wide range of choices. Each variant can have its own pricing, stock availability, and unique attributes, enabling businesses to cater to diverse customer preferences seamlessly. Furthermore, DizLog's inventory management system ensures accurate tracking and updates across all product variants, preventing overselling and improving customer satisfaction. With DizLog, businesses can effortlessly present their products in a way that appeals to a broader customer base, driving sales and enhancing the overall shopping experience.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/QI7OlXF4v2U" title="You Can Sell Different Variants of Your Products with DigLog #sell #variants" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_2,
  },
  {
    title: "How to Add an Employee Using the HRMS Tool in the Web App",
    description:
      "DizLog App allows for customization, enabling administrators to set permissions and access levels for each employee. Additionally, the app offers the ability to upload important documents like employment contracts, identification proofs, and certifications, ensuring all necessary records are securely stored and easily accessible. Once the required information is entered, administrators can save the employee's profile, and the new employee will be added to the HRMS system. With DizLog App's HRMS tool, businesses can efficiently manage their workforce, streamline employee onboarding, and maintain accurate records, ultimately fostering a more organized and productive work environment.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/bKRvM2QfXcI" title="How to Add an Employee Using the HRMS Tool in the Web App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_3,
  },
  {
    title: "How To Transfer Inventory Across Multiple Locations",
    description:
      "Transferring inventory across multiple locations is a seamless process with the DizLog App. To initiate a transfer, users can log in to the app and navigate to the inventory management section. Within the tool, they will find an option specifically for inventory transfers. By selecting this option, users can choose the source location from which they want to transfer the inventory and the destination location where they want it to be moved. They can then enter the details of the items being transferred, such as product names, quantities, and any additional notes. The app also provides a convenient barcode scanning feature, allowing users to scan the products' barcodes for accuracy and efficiency. Once the transfer request is confirmed, the app generates a transfer order, which can be printed or electronically shared with the relevant personnel involved in the transfer process.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/g2iEgoRCOsw" title="How To Transfer Inventory Across Multiple Locations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_4,
  },
  {
    title: "How to Process Refund Using DizLog POS",
    description:
      "Processing refunds using the DizLog POS (Point of Sale) system is a straightforward and efficient process. When a customer requests a refund, authorized personnel can access the DizLog POS system and navigate to the refund section. The DizLog POS system automatically calculates and processes the refund amount based on the selected items and refund method.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/mVQbrUvM720" title="How to Make Item Returnable and How to Process Refunds for Returnable and Non Returnable Items" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_5,
  },
  {
    title: "How to Create a Contactless Ordering QR Code with DizLog POS",
    description:
      "Creating a contactless ordering QR code with the DizLog POS system is a simple and convenient process. The DizLog POS empowers businesses to offer their customers a safe and efficient way to place orders using their own devices. To create a contactless ordering QR code, users can access the DizLog POS system and navigate to the QR code generation feature. This contactless ordering process enhances convenience, minimizes physical contact, and promotes a safe and streamlined dining experience for customers.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/8wJtVkmNDQ0" title="Save Big with Lifetime Subscription to our User-Friendly Inventory + POS System #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_6,
  },
  {
    title: "How to Make an Item Returnable Using DizLog POS",
    description:
      "By making items returnable, businesses can enhance customer satisfaction and instill confidence in their purchase decisions. The DizLog POS system simplifies the process of making items returnable, empowering sellers to provide excellent customer service and uphold a customer-centric approach to their business operations. Making an item returnable using the DizLog POS (Point of Sale) system is a straightforward process that ensures a seamless customer experience.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/mVQbrUvM720" title="How to Make Item Returnable and How to Process Refunds for Returnable and Non Returnable Items" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_7,
  },
  {
    title: "How to View your Order History from DizLog POS Web App",
    description:
      "This allows you to easily search for specific transactions or analyze patterns in your sales history. Additionally, you can click on individual orders to view more details, such as order date, items purchased, quantities, and total amounts. DizLog POS Web App provides a user-friendly and intuitive interface, making it convenient to review and analyze your order history for better insights into your business performance.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/rEUCUSu978w" title="How to Track Order Status in Order Manager with Dizlog All-in-one POS!- WEB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_8,
  },
  {
    title: "How to Manage Orders from DizLog POS Web App",
    description:
      "Managing orders from the DizLog POS web app is an efficient and streamlined process that ensures smooth operations for businesses. With this web-based platform, businesses can easily handle their orders and provide excellent customer service. To effectively manage orders, start by logging into the DizLog POS web app and navigating to the Orders section. Here, you can view all incoming orders, including details like customer information, order items, and payment status. Prioritize orders based on urgency and fulfill them accordingly.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/5ImIi5IVO5k" title="DigLog 101: How to track and manage customers&#39; order in the web app" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_9,
  },
  {
    title: "How To Sell Digital Products On DizLog POS",
    description:
      "Selling digital products on DizLog POS is a convenient and efficient way to offer your digital goods to customers. o attract customers, consider adding appealing product images or illustrations. Customize your product listings by setting discounts or promotional offers, if applicable. With DizLog POS, you can accept various payment methods, including credit cards, digital wallets, and other popular online payment options. Once a customer makes a purchase, the system will automatically deliver the digital product to them, either through a download link or by email. DizLog POS also provides you with real-time analytics and reports to track your digital product sales, customer engagement, and revenue. Selling digital products on DizLog POS is a seamless and efficient process that allows you to reach a wider audience and monetize your digital creations effectively.",
    embedCode: `<iframe width="1092" height="614" src="https://www.youtube.com/embed/JZHVXlUo1sM" title="Sell Your Digital Products in an Online Store and Manage Orders Effortlessly with DigLog #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    image: page4_10,
  },
];

export default videoSupportList;
