import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import fastfoodRestaur from "../assets/fastfoodRestaur.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import inventDash from "../assets/inventDash.png";
import KDS from "../assets/KDS.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import orderMgmtFastfood from "../assets/orderMgmtFastfood.png";
import cust from "../assets/cust.png";
import updateCus from "../assets/updateCus.png";
import dinningIcon from "../assets/dinningIcon.png";
import arrowUP from "../assets/arrowUP.png";
import reportSales from "../assets/reportSales.png";
import kitchenIcon from "../assets/kitchenIcon.png";
import orderMgmt from "../assets/orderMgmt.png";
import tableIcon from "../assets/tableIcon.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import micaelaFlorendo from "../assets/micaelaFlorendo.png";
import andrewSotto from "../assets/andrewSotto.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function FastFoodAndRestaurant() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "479px",
      image: {
        src: updateCus,
        height: "319px",
      },
      title: t("Enhance the Dining Experience"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dinningIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Boost Customer Loyalty"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Encourage repeat visits and reward customers with discount and promotions."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Build a Dedicated Customer Base"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Cultivate a pool of loyal visitors who regularly choose your restaurant for their dining needs."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalize Service with Customer Notes"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Capture and remember individual preferences to provide exceptional and tailored service experiences."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "450px",
      image: {
        src: orderMgmtFastfood,
        height: "284px",
      },
      title: t("Streamline Your Ordering Processes"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Order Processing"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Simplify the order processing workflow with Dizlog POS. From capturing customer orders to sending them to the kitchen or barista, the system optimizes the order fulfillment process, reducing errors and minimizing wait times."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Order History and Reporting"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Access comprehensive order history and generate detailed reports to gain valuable insights into your sales performance. Dizlog POS provides analytics and reporting tools that allow you to track order trends, identify popular menu items, and make data-driven decisions to drive profitability."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "572px",
      image: {
        src: reportSales,
        height: "325.84px",
      },
      title: t("Customize and Structure Your Sales Process"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: arrowUP,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Enhanced Menu Customization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Tailor your menu to meet Your specific requirements and organize items by Categories."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Item Customization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Easily add toppings, modifiers, and add-ons to enhance customer choices and Preferences."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Versatile Dining Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create flexible dining options such as dine-In, takeout, and delivery to cater to varied customer needs."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Seamless Discount and Note Management"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Apply discounts, taxes, and add notes to items or orders for accurate and efficient sales processing."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "526px",
      image: {
        src: cust,
        height: "256.95px",
      },
      title: t("Efficient Employee Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Track Sales performance"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Gain insights into individual sales performance to make data-driven decisions"
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Secure Access"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain data security by managing employee access to sensitive information and functions."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Time Management"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline time tracking with clock in/out functionality and automated total hours calculation."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Enhanced Cashier Register"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure accountability by giving each employee own cash register with PIN code for additional security."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "##004AAD",
      minHeight: "536.44px",
      image: {
        src: inventDash,
        height: "376.44px",
      },
      title: t("Manage your Inventory"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Purchase Orders"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Streamline stock replenishment with quick and easy purchase orders for fresh products."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Ingredient Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Effectively manage stock of produced items and ingredients using composite item production."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Multi-Location Support"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Seamlessly transfer goods between your different locations."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Stock Management"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Stock Maintain accurate stock levels through regular counting and stock correction"
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "412.12px",
      image: {
        src: KDS,
        height: "252.12px",
      },
      title: t("Reduced Wait Times With Kitchen Printers/Display"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: kitchenIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Connect Kitchen Printers or Kitchen Displays"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS accelerates the order flow, enabling the kitchen staff to prepare orders promptly and reduce overall wait times."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Shortens customer wait times for orders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Faster order processing and kitchen communication result in reduced waiting times for customers, leading to higher satisfaction levels."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "412.69px",
      image: {
        src: orderMgmt,
        height: "252.69px",
      },
      title: t("Table Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: tableIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Efficient table assignments and order routing"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t("Efficient table assignments and order routing"),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Improves overall dining experience and service speed"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "With smooth table management, cafes can provide better service, leading to improved customer satisfaction and repeat business."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“The efficiency it brings to our order management is outstanding. With DizLog POS, our staff can take orders swiftly and accurately using tablets or smartphones. This not only reduces wait times for our customers but also minimizes errors in the ordering process. It has made our fast-food service truly fast!”"
      ),
      name: t("MIcaella Florendo"),
      designation: t("Fastfood Shop Owner"),
      imageSrc: micaelaFlorendo,
    },
    {
      content: t(
        "“DizLog POS has proven to be an invaluable tool in managing our inventory. The real-time tracking allows us to monitor stock levels, ensure timely restocking, and prevent any shortages. We no longer have to worry about running out of key ingredients or supplies during peak hours, which has significantly improved our overall service.”"
      ),
      name: t("Andrew Sotto"),
      designation: t("Fastfood Shop Owner"),
      imageSrc: andrewSotto,
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div
            className="min-h-[366px] bg-[#FEF7EC] flex 
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-end   justify-center">
              <img src={fastfoodRestaur} className="h-[337.44px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
                  
                  text-[#00215D]"
                >
                  {t("DizLog for Fastfood and Beverage Shops")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    " DizLog POS is a powerful asset for Fastfood and Beverage shops, empowering them to provide efficient service, streamline operations, and thrive in the competitive food industry. The system's sales analytics provide valuable insights into popular menu items, sales trends, and customer preferences, enabling data-driven decisions to optimize the menu and marketing strategies."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default FastFoodAndRestaurant;
