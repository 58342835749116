import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const NavigationButtons = ({className}) => {
  const { t } = useTranslation('common')

  const data = [
    {
      title: t('All'),
      href: '/',
    },
    {
      title: t('Restaurant'),
      href: '/restaurant'
    },
    {
      title: t('Retail'),
      href: '/retail'
    },
    {
      title: t('Beauty'),
      href: '/beauty'
    },
    {
      title: t('Services'),
      href: '/services'
    },
    {
      title: t('Accounting'),
      href: '/accounting'
    }
  ]

  return (
    <div className={clsx('flex flex-row flex-wrap gap-1 mt-8 mb-8 justify-center lg:justify-start', className)}>
      {data.map((item, index) => {
        return (
          <Link to={item.href}>
            <button 
              key={index} 
              className={clsx(
                "px-3 py-1 border-solid border-1 border-[#00215D] rounded-full font-semibold hover:bg-[#FEEDD3] transition-all duration-300 ease-in-out", 
                window.location.pathname === item.href ? "bg-[#00215D] text-white hover:text-[#00215D]" : "text-[#00215D]"
              )}
            >
              {item.title}
            </button>
          </Link>
        )
      })}
    </div>
  )
}

export default NavigationButtons