import circleBluePlusWhite from "../assets/circleBluePlusWhite.png";
import CircleBlueMinusWhite from "../assets/CircleBlueMinusWhite.png";
import React, { useState } from "react";
import parse from 'html-react-parser';
import clsx from "clsx";

const FrequentlyAskedQuestionsComponent = ({askedQUestions, className}) =>{

  // const askedQUestions = [
  //   {
  //     question: "Is DizLog POS really free?",
  //     answer: "Dizlog POS is Free at the first 14 days from your sign up date. To enjoy Dizlog POS further, check out our pricing plans here. https://dizlog.com/pricing",
  //     active: false,
  //   },
  // ]
    const [copyaskedQUestions,setaskedQUestions] = useState(askedQUestions || [])

    const onClickQUestion = (index) =>{
        let questions = [...copyaskedQUestions]
        questions.map((item,idx) =>{
            if(index == idx){
                if(item.active){
                    item.active = false
                }else{
                    item.active = true
                }
            }else{
                item.active = false
            }
            return item
        })
        setaskedQUestions(questions)
    }
return (
    <section id="section12">
    <div className={clsx("min-h-[514px] flex flex-col py-7 w-full bg-[#F6F6FF] justify-center items-center", className)}>
      <span
        className="font-[Raleway] 
      text-2xl xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px]
      text-[#00215D] font-[700]"
      >
        Frequently Asked Questions
      </span>
      <div
        className=" 
      w-[95%] xl:w-[80%]  lg:w-[80%]  md:w-[80%]  sm:w-[80%]  
      mt-4 flex flex-col"
      >
        {copyaskedQUestions?.map((item,idx) => (
            <>
          <div 
            onClick={() => onClickQUestion(idx)} 
            className={
              clsx(
                "cursor-pointer bg-indigo flex flex-col justify-center pl-4 pr-2 w-full min-h-[60px] border-x border-t border-b-0 border-[#516793]", 
                copyaskedQUestions.length === idx+1 && "!border-b",
                item.active && "border-2"
              )
            }
          >
            <div className="flex w-full items-center">
              <span
                className="w-full font-[Raleway] py-2
              text-sm xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px]
              font-[700] text-[#00215D]"
              >
                {item.question}
              </span>
              <img
                src={item.active ? CircleBlueMinusWhite : circleBluePlusWhite}
                className="w-[20px] h-[20px]"
              />
            </div>
            {item.active ? <div className="py-2 font-[Roboto] font-[700] text-[13px] leading-[18px] text-[#6B6B6B]">{parse(item.answer)}</div>
            : null}
          </div>
          </>
        ))}
      </div>
    </div>
  </section>
)

}

export default FrequentlyAskedQuestionsComponent