import React, { useState } from "react";
import "./SignUpLink.css";
import { useTranslation } from "react-i18next";

function SignUpLink({ alternative }) {
  const { t } = useTranslation();

  const onSignUp = () => {
    window.location.assign('https://lddy.no/192jw');
  }

  return (
      <div className={`${alternative ? "signup-button-alt" : "signup-button"} cursor-pointer`} onClick={onSignUp}>
        {t('header.signUp')}
      </div>
  );
}

export default SignUpLink;
