import React from "react";
import i18n from "../i18n";

//import components
import Header from "../components/Header";
import Footer from "../components/Footer";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import ReceiveAssistance from "../components/sections/ReceiveAssistance";
import TwoColumnTable from "../components/sections/TwoColumnTable";
import BigImageBannerSection from "../components/sections/BigImageBannerSection";
import ThreeBigCards from "../components/sections/ThreeBigCardsSection";

//import images
import ThreeBigCards1 from "../assets/threebigcards_services_1.png";
import ThreeBigCards2 from "../assets/threebigcards_services_2.png";
import ThreeBigCards3 from "../assets/threebigcards_services_3.png";
import BigImageBannerServices from "../assets/bigimagebanner_services.png";
import BigImageBannerIcon1 from "../assets/bigimageicon_beauty_1.png";
import BigImageBannerIcon2 from "../assets/bigimageicon_beauty_2.png";
import BigImageBannerIcon3 from "../assets/bigimageicon_beauty_3.png";
import BannerSection from "../components/BannerSection";
import serviceBannerSrc from "../assets/servrice-banner.png";

const Services = () => {
  const { t } = i18n;

  const askedQUestions = [
    {
      question: t("Is DizLog POS really free?"),
      answer: t(
        "Dizlog POS is Free at the first 14 days from your sign up date. To enjoy Dizlog POS further, check out our pricing plans here. <a href='https://dizlog.com/pricing' target='_blank' className='text-orange-600'>https://dizlog.com/pricing</a>"
      ),
      active: false,
    },
    {
      question: t("How to use DizLog POS system?"),
      answer: t(
        "To use Dizlog POS System.<br/><br/>1.Open Web App or Download the Dizlog App.<br/>2.Sign in or Create a new Account.<br/>3.Open POS feature and start processing orders<br/>If you are using Mobile version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app</a><br/> If you are using Web App version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app</a>"
      ),
      active: false,
    },
    {
      question: t("Does DizLog work offline?"),
      answer: t(
        "YES! Offline Web POS is a feature that allows merchants to continue operating their point-of-sale system, even when there is no internet connection available.<br/><br/>With Offline Web POS, you can continue to process cash transactions and keep your business running smoothly, without interruption. Click Here to learn how to use Dizlog POS offline. <a href='https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets</a>"
      ),
      active: false,
    },
    {
      question: t("What kind of hardware can work with DizLog?"),
      answer: t(
        "Dizlog software is compatible with a wide range of hardware devices, including computers, smartphones, and tablets. Whether you prefer to work on a desktop computer, stay productive on your phone, or enjoy the flexibility of a tablet, our software seamlessly adapts to your preferred device. With Dizlog, you can leverage the power of our software solution on any hardware device that suits your needs, providing you with the freedom to work and manage your business efficiently wherever you are.<br/><br/>Open WebApp thru - <a className='text-orange-600 ' href='https://web.dizlog.com/' target='_blank'>https://web.dizlog.com/</a><br/>or download the app via playstore, appstore or appgallery"
      ),
      active: false,
    },
    {
      question: t("Does DizLog have the functionality of loyalty cards?"),
      answer: t(
        "Yes, with Dizlog Loyalty Program We can encourage customers to return to our business and make Repeat purchases. This program offers Rewards and incentives to customers who make purchases or engage with our Business in certain ways.<br/><br/>Click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards</a>"
      ),
      active: false,
    },
  ];

  const threeBigCardsData = {
    title: t("Simplify Operations in your Service-Based Business"),
    items: [
      {
        image: ThreeBigCards1,
        text: t(
          "Sync your service-focused business with hardware and an Appointments POS system."
        ),
      },
      {
        image: ThreeBigCards2,
        text: t(
          "Efficiently handle services with a single transaction for each booking."
        ),
      },
      {
        image: ThreeBigCards3,
        text: t(
          "Directly interact with clients via your POS system to manage appointments."
        ),
      },
    ],
  };

  const bigImageBannerData = {
    title: t("Harness Smart Inventory Solutions for your Business"),
    description: t(
      "Dizlog is delighted to introduce a remarkable POS software custom-built for service-oriented businesses, offering a comprehensive range of potent features meticulously crafted to enhance and propel business growth."
    ),
    image: BigImageBannerServices,
    buttons: [
      {
        icon: BigImageBannerIcon1,
        text: t("Appointment Scheduling"),
      },
      {
        icon: BigImageBannerIcon2,
        text: t("Integrated Service Menu"),
      },
      {
        icon: BigImageBannerIcon3,
        text: t("Confirmations and Reminder"),
      },
    ],
  };

  const twoColumnTableData = {
    title: t("Maximize your Operational Efficiency"),
    description: t(
      "Effectively coordinate and streamline operations across various locations, sales channels, and staff to enhance efficiency and profitability."
    ),
    headers: [t("The objective of your business"), t("Solving it with ")],
    rows: [
      {
        objective: t("Oversee the Cash Flow"),
        solution: [
          t(
            "Access your earnings instantly with Automatically allocate a portion of each sale for taxes or savings."
          ),
          t("Receive loan offers based on your  card sales."),
        ],
      },
      {
        objective: t("Supervise your Staff"),
        solution: [
          t(
            "Effectively oversee your staff and their schedules across multiple locations through a unified platform."
          ),
          t(
            "Save time with customizable employee commission tracking and integration with  Payroll."
          ),
        ],
      },
      {
        objective: t("Sophisticated Analytics"),
        solution: [
          t(
            "Utilize integrated data synchronization to assess your business's performance."
          ),
          t(
            "Access easily understandable intelligent reports to guide your future actions."
          ),
        ],
      },
      {
        objective: t("Expand Income Streams"),
        solution: [
          t("Broaden your revenue sources by exploring new avenues."),
          t(
            "Monitor profit margins through inventory management and reporting functionalities."
          ),
          t(
            "Sync orders, items, and inventory seamlessly from your POS to your website."
          ),
        ],
      },
    ],
  };

  return (
    <>
      <Header i18n={i18n} />
      <BannerSection
        title={t("Services")}
        imgSrc={serviceBannerSrc}
        description={t(
          "DizLog POS is a powerful solution for service businesses like plumbing, cleaning, carpentry, and electrical services, simplifying job scheduling, invoicing, and customer management. Its user-friendly interface allows for easy booking and quick payment processing, enhancing client satisfaction. Real-time tracking helps manage tools and materials efficiently, while detailed reporting provides insights into your top-performing services, empowering you to make data-driven decisions to grow your business effectively."
        )}
        reqDemoPath={"https://meetings.hubspot.com/support-diglog"}
      />
      <ThreeBigCards hasNav data={threeBigCardsData} />
      <BigImageBannerSection data={bigImageBannerData} />
      <TwoColumnTable data={twoColumnTableData} />
      <ReceiveAssistance />
      <FrequentlyAskedQuestionsComponent askedQUestions={askedQUestions} />
      <Footer />
    </>
  );
};

export default Services;
