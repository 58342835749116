import React from "react";
import i18n from "../i18n";

//import components
import Header from "../components/Header";
import Footer from "../components/Footer";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import ReceiveAssistance from "../components/sections/ReceiveAssistance";
import TwoColumnTable from "../components/sections/TwoColumnTable";
import BigImageBannerSection from "../components/sections/BigImageBannerSection";
import ThreeBigCards from "../components/sections/ThreeBigCardsSection";
import restaurantBannerSrc from "../assets/restaurant-banner@3x.png";

//import images
import ThreeBigCards1 from "../assets/threebigcards_restaurant_1.png";
import ThreeBigCards2 from "../assets/threebigcards_restaurant_2.png";
import ThreeBigCards3 from "../assets/threebigcards_restaurant_3.png";
import BigImageBannerRestaurant from "../assets/bigimagebanner_restaurant.png";
import BigImageBannerIcon1 from "../assets/bigimageicon_restaurant_1.png";
import BigImageBannerIcon2 from "../assets/bigimageicon_restaurant_2.png";
import BigImageBannerIcon3 from "../assets/bigimageicon_restaurant_3.png";
import BigImageBannerIcon4 from "../assets/bigimageicon_restaurant_4.png";
import BigImageBannerIcon5 from "../assets/bigimageicon_restaurant_5.png";
import TypesList from "../components/sections/TypesList";
import BannerSection from "../components/BannerSection";
import NavigationButtons from "../components/NavigationButtons";

const Restaurant = () => {
  const { t } = i18n;

  const askedQUestions = [
    {
      question: t("Is DizLog POS really free?"),
      answer: t(
        "Dizlog POS is Free at the first 14 days from your sign up date. To enjoy Dizlog POS further, check out our pricing plans here. <a href='https://dizlog.com/pricing' target='_blank' className='text-orange-600'>https://dizlog.com/pricing</a>"
      ),
      active: false,
    },
    {
      question: t("How to use DizLog POS system?"),
      answer: t(
        "To use Dizlog POS System.<br/><br/>1.Open Web App or Download the Dizlog App.<br/>2.Sign in or Create a new Account.<br/>3.Open POS feature and start processing orders<br/>If you are using Mobile version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app</a><br/> If you are using Web App version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app</a>"
      ),
      active: false,
    },
    {
      question: t("Does DizLog work offline?"),
      answer: t(
        "YES! Offline Web POS is a feature that allows merchants to continue operating their point-of-sale system, even when there is no internet connection available.<br/><br/>With Offline Web POS, you can continue to process cash transactions and keep your business running smoothly, without interruption. Click Here to learn how to use Dizlog POS offline. <a href='https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets</a>"
      ),
      active: false,
    },
    {
      question: t("What kind of hardware can work with DizLog?"),
      answer: t(
        "Dizlog software is compatible with a wide range of hardware devices, including computers, smartphones, and tablets. Whether you prefer to work on a desktop computer, stay productive on your phone, or enjoy the flexibility of a tablet, our software seamlessly adapts to your preferred device. With Dizlog, you can leverage the power of our software solution on any hardware device that suits your needs, providing you with the freedom to work and manage your business efficiently wherever you are.<br/><br/>Open WebApp thru - <a className='text-orange-600 ' href='https://web.dizlog.com/' target='_blank'>https://web.dizlog.com/</a><br/>or download the app via playstore, appstore or appgallery"
      ),
      active: false,
    },
    {
      question: t("Does DizLog have the functionality of loyalty cards?"),
      answer: t(
        "Yes, with Dizlog Loyalty Program We can encourage customers to return to our business and make Repeat purchases. This program offers Rewards and incentives to customers who make purchases or engage with our Business in certain ways.<br/><br/>Click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards</a>"
      ),
      active: false,
    },
  ];

  const threeBigCardsData = {
    title: t("Achieve a Revolutionary Restaurant Flow"),
    description: t("Discover the Restaurant POS demo"),
    descriptionLink: "/live-demo",
    items: [
      {
        image: ThreeBigCards1,
        text: t(
          "Accept additional orders either in person at the counter or online, and provide options for both pickup and delivery."
        ),
      },
      {
        image: ThreeBigCards2,
        text: t(
          "Receive payments through swiping, dipping, tapping, or scanning QR codes."
        ),
      },
      {
        image: ThreeBigCards3,
        text: t(
          "Handle all orders from various channels in a single location."
        ),
      },
    ],
  };

  const bigImageBannerData = {
    title: t("Enhance your Operational Efficiency."),
    description: t(
      "Effectively manage and streamline operations across various locations, sales channels, and staff to enhance efficiency and boost your financial performance."
    ),
    image: BigImageBannerRestaurant,
    buttons: [
      {
        icon: BigImageBannerIcon1,
        text: t("Order placed online"),
      },
      {
        icon: BigImageBannerIcon2,
        text: t("Order processed through POS"),
      },
      {
        icon: BigImageBannerIcon3,
        text: t("Available goods"),
      },
      {
        icon: BigImageBannerIcon4,
        text: t("Orders sent to KDS"),
      },
      {
        icon: BigImageBannerIcon5,
        text: t("Order for pick-up is prepared"),
      },
    ],
  };

  const twoColumnTableData = {
    title: t("Improve Service Quality Broadly"),
    description: t(
      "When your technology integrates seamlessly, customers have the flexibility to order as they prefer, your staff can work more efficiently, and you can monitor the bottom line effectively."
    ),
    headers: [t("The objective of your business"), t("Solving it with ")],
    rows: [
      {
        objective: t("Handle Cash Flow"),
        solution: [
          t("Access your funds instantly with."),
          t(
            "Automatically allocate a portion of each sale for taxes or savings."
          ),
          t("Receive loan offers tailored to your card sales through."),
        ],
      },
      {
        objective: t("Attract Customers"),
        solution: [
          t(
            "Establish a loyalty program to encourage repeat visits from diners."
          ),
          t("Personalize your rewards according to diners' past visits."),
        ],
      },
      {
        objective: t("Oversee your Staff"),
        solution: [
          t("Manage labor expenses with integrated staff management features."),
          t("Automatically calculate tips and commissions."),
        ],
      },
      {
        objective: t("Expand Revenue Streams"),
        solution: [
          t("Broaden your revenue sources by exploring new avenues."),
          t(
            "Monitor profit margins using inventory management and reporting functions."
          ),
          t(
            "Sync orders, items, and inventory seamlessly from your POS to your website."
          ),
        ],
      },
      {
        objective: t("Sophisticated reporting"),
        solution: [
          t("Access robust data for making informed decisions."),
          t(
            "Enhance menu pricing and inventory ordering strategies leveraging sales trends."
          ),
        ],
      },
    ],
  };

  const typesListData = {
    title: t("Built for all kinds of restaurants."),
    items: [
      {
        title: t("Fast Service"),
        link: "/fast-service",
      },
      {
        title: t("Full Service"),
        link: "/full-service",
      },
      {
        title: t("Fast Casual"),
        link: "/fast-casual",
      },
      {
        title: t("Bars and Beer Makers"),
        link: "/bars-and-beer-makers",
      },
      {
        title: t("Virtual Kitchens"),
        link: "/virtual-kitchens",
      },
      {
        title: t("Cafes"),
        link: "/cafes",
      },
    ],
  };

  return (
    <>
      <Header i18n={i18n} />
      <BannerSection
        title={t("Restaurant")}
        imgSrc={restaurantBannerSrc}
        description={t(
          "DizLog POS is a game-changer for your restaurant business, streamlining operations from order taking to payment processing. With its intuitive interface, staff can quickly manage orders, reducing errors and improving customer service. The system tracks inventory in real-time, helping to minimize waste and ensure stock levels are optimized. Additionally, DizLog POS offers detailed sales reports, providing valuable insights into peak hours, popular dishes, and overall performance, enabling smarter decision-making for growth."
        )}
        reqDemoPath={"https://meetings.hubspot.com/support-diglog"}
      />
      <ThreeBigCards data={threeBigCardsData} />
      <BigImageBannerSection data={bigImageBannerData} />
      <TwoColumnTable data={twoColumnTableData} />
      <TypesList data={typesListData} />
      <ReceiveAssistance />
      <FrequentlyAskedQuestionsComponent askedQUestions={askedQUestions} />
      <Footer />
    </>
  );
};

export default Restaurant;
