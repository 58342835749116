import React from "react";
import "./GetStartedModal.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import DownloadLinks from "./DownloadLinks";

function GetStartedModal({ showModal, toggle }) {
  console.log("get started modal");

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      dialogClassName="custom-modal-style"
      contentClassName="get-started-modal"
      centered
      size="xl"
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody style={{ width: "100%" }}>
        <h3 className="start-selling">Start selling in minutes!</h3>
        <h6 className="try-diglog">
          Use DizLog&nbsp;<span>for free!</span>
        </h6>
        <DownloadLinks center={true} />
      </ModalBody>
    </Modal>
  );
}

export default GetStartedModal;
