import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PartnerProgram.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from "react-modal";
import footer_home from "../assets/Footer_Home@2x.png";
import { useTranslation } from "react-i18next";
import videoSupportGirl from "../assets/videSupportGIrl.png";
import videoSupportList from "../utils/videoSupportsList";
import VideoModalDynamic from "../components/VideoModalDynamic";
import empoweringVideos from "../assets/empoweringVideos.png";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#F6F6FF",
    height: "95%",
    padding: "20px !important",
    width: "95%",
  },
};

Modal.setAppElement("#root");
function VideoSupport() {
  const { t, i18n } = useTranslation();

  let videoSupportLists = videoSupportList;

  const [activeIndexVideoList, setactiveIndexVideoList] = useState(0);
  const [activeVideoSupport, setactiveVideoSupport] = useState({
    embedCode: "",
  });
  const [showModal, setShowModal] = useState(false);
  function separateItems(data, maxItemsPerArray = 10) {
    const separatedArray = [];
    let currentArray = [];

    data.forEach((item) => {
      if (currentArray.length < maxItemsPerArray) {
        currentArray.push(item);
      } else {
        separatedArray.push(currentArray);
        currentArray = [item];
      }
    });

    if (currentArray.length > 0) {
      separatedArray.push(currentArray);
    }

    return separatedArray;
  }
  let maximumVideosPerArray = 10;
  let finalListWithPagination = separateItems(
    videoSupportLists,
    maximumVideosPerArray
  );
  console.log("finalListWithPagination", finalListWithPagination);
  return (
    <>
      <Header i18n={i18n} screen="support" />
      <div className="">
        <section id="">
          <div className="min-h-[460px] bg-[#FEEDD3] flex 
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-center justify-center py-4">
              <div className="w-[90%] xl:w-[62%] lg:w-[62%] md:w-[62%] sm:w-[62%] flex flex-col items-start">
                <span className="font-[Roboto] text-[#F09000] font-[600] text-[20px]">
                  Video Support
                </span>
                <span className="font-[Raleway] text-[#00215D] font-[700] text-[text-[22px]
                xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[35px]
                  leading-[22px] xl:leading-[40px] lg:leading-[40px] md:leading-[40px] sm:leading-[40px] mt-2">
                  Access our Video Support to Gain more Knowledge
                </span>
                <span className="mt-4 font-[Roboto] text-[#6B6B6B] font-[400] text-[14px] leading-[20px] tracking-[0.49px]">
                  Discover the seamless process of getting started with DizLog
                  POS by leveraging the power of our comprehensive video
                  support. Whether you're a seasoned user or a newcomer, our
                  collection of informative videos will guide you through every
                  step of the setup and utilization process. With clear and
                  concise demonstrations, you'll quickly grasp the
                  functionalities and features of DizLog POS, enabling you to
                  streamline your business operations with ease
                </span>
              </div>
            </div>
            <div className="flex flex-1 items-end justify-center">
              <img src={videoSupportGirl} className="w-auto h-[200px] xl:h-[430px] lg:h-[430px] md:h-[430px] sm:h-[430px]" />
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[699.9px] flex items-center justify-center flex-col">
              <span className="text-[#00215D] font-[Raleway] font-[700] 
              text-[22px] xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[35px] px-2">
              Start and Grow your Business with Dizlog All-in-one POS!
              </span>

              <img 
              onClick={() =>{
              setShowModal(true)
              setactiveVideoSupport({
                embedCode:'<iframe width="424" height="238" src="https://www.youtube.com/embed/2qTk_adSRSc" title="Start and Grow your Business with Dizlog All-in-one POS!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
              })
            }}
              src={empoweringVideos} className="mt-4 
              h-[250px] xl:h-[509.9px] lg:h-[509.9px] md:h-[509.9px] sm:h-[509.9px] w-auto cursor-pointer px-2" />
          </div>
        </section>
        <section>
          <div className="py-12 
          px-4 xl:px-24 lg:px-24 md:px-24 sm:px-24 bg-[#F6F6FF]">
            <div className="flex items-center">
              <span className="font-[Raleway] w-full font-[700] text-[35px] text-[#00215D]">
                Videos
              </span>
              {/* <span>SEARCH</span> */}
            </div>
            <div className="flex items-center mt-3
            flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row ">
              <span className="font-[Roboto] w-full font-[700] text-[16px] text-[#6B6B6B]">
                Showing{" "}
                <span className="text-[#00215D]">{maximumVideosPerArray}</span>{" "}
                of {videoSupportLists.length} Videos
              </span>
              <div className="flex">
                {finalListWithPagination.map((item, idx) => (
                  <span
                    onClick={() => {
                      setactiveIndexVideoList(idx);
                    }}
                    className={`rounded-md text-[13px] h-[27.9px] w-[29.13px] font-[700] font-[Roboto]  flex items-center justify-center mr-2 cursor-pointer
                                ${
                                  activeIndexVideoList == idx
                                    ? "bg-[#F09000] text-white "
                                    : "bg-white text-[#878787] border-2 border-[#878787] "
                                }`}
                  >
                    {idx + 1}
                  </span>
                ))}
              </div>
            </div>
            <div className="mt-4">
              {finalListWithPagination[activeIndexVideoList].map(
                (item, idx) => (
                  <div className="min-h-[252px] w-full py-2">
                    <div className="flex flex-1 h-full
                    flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
                      <div
                        className="flex w-[99%] xl:w-[468px] lg:w-[468px] md:w-[468px] sm:w-[468px] h-full items-center cursor-pointer"
                        onClick={() => {
                          setactiveVideoSupport(item);
                          setShowModal(true);
                        }}
                      >
                        <img src={item.image} className="h-[252px] w-auto mb-3" />
                      </div>
                      <div className="flex flex-1 flex-col items-start justify-center">
                        <span className="font-[Raleway] text-[#00215D] font-[700] text-[20px]
                        xl:text-[25px] lg:text-[25px] md:text-[25px] sm:text-[25px]
                         leading-[25px] xl:leading-[30px] lg:leading-[30px] md:leading-[30px] sm:leading-[30px]">
                          {item.title}
                        </span>
                        <span className="font-[Roboto] font-[400] text-[13px] leading-[18px] text-[#6B6B6B] mt-2">
                          {item.description}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {showModal && (
              <VideoModalDynamic
                showModal={showModal}
                toggle={() => setShowModal(false)}
                c={activeVideoSupport.embedCode}
              />
            )}
          </div>
        </section>
      </div>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default VideoSupport;
