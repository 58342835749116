import React from "react";
import CTAButtons from "../CTAButtons";
import NavigationButtons from "../NavigationButtons";

const BannerSection = ({ imgSrc, title, description, reqDemoPath }) => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-4xl">
        <NavigationButtons />
      </div>
      <div
        className="min-h-[366px]  flex 
  flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row mx-auto max-w-6xl"
      >
        <div className="flex flex-1 items-end   justify-center">
          <img src={imgSrc} className="h-[337.44px] w-auto" />
        </div>
        <div className="flex flex-1 justify-center items-center py-4">
          <div className="flex flex-col w-[80%]">
            <span
              className="font-[Raleway] font-[700] 
          text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
          leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px]
          
          text-[#00215D]"
            >
              {title}
            </span>
            <span
              className="mt-2 font-[Roboto] font-[400] 
          text-[14px] leading-[19px] text-[#6B6B6B]"
            >
              {description}
            </span>
            <div className="mt-8">
              <CTAButtons requestToDemoPath={reqDemoPath} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
