import React from 'react'
import DownloadLinks from '../components/DownloadLinks';
import Title from '../components/Title';
import SimpleForm from './SimpleForm'
import './Banner.css'
import { Trans, useTranslation } from 'react-i18next';
import howCanWeHelp from '../assets/howCanWeHelp.png'


import {selectContentsBasedOnLanguage} from '../utils/dynamicContentsBasedonLanguage'
function Banner({banner, view, search}) {
	const { t } = useTranslation();

	return (
		<section className={`${view}  min-[460px]`}>
			<div className=" flex flex-1 flex-col justify-center">
				<div className='p-4 xl:p-12 lg:p-12 md:p-12 sm:p-12 flex flex-col justify-center'>
				{/* <Title logo={banner.titleLogo} title={banner.titleText}/> */}
				<h1 className='font-[Roboto] font-[600] text-[20px] text-[#F09000]'>
				{t("Frequesntly asked Questions")}</h1>
					<h1 className='font-[Raleway] font-[700] text-[35px] text-[#00215D] my-2'>
					<Trans>
					{t("How can we Help?")}
					</Trans>
					</h1>
					<span className='w-full xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-[90%] font-[Roboto] font-[400] text-[14px] text-[#6B6B6B]'>
					{t("At Dizlog, we are dedicated to providing exceptional support and assistance to our valued customers. Whether you have questions about our products, need technical guidance, or require personalized solutions for your business, we are here to help. Our knowledgeable team is committed to understanding your unique needs and providing you with the right resources and solutions to overcome any challenges you may face. Explore the various ways we can assist you and let us be your trusted partner on your journey to success.")}

					</span>
				<p className='leading-4 font-normal' style={{fontFamily:"Roboto",color:"#686868CC"}}>{banner.description}</p>
				{/* {banner.showDownloadLinks && <DownloadLinks />} */}
				{banner.showForm && <SimpleForm placeholder={t('Search questions, keywords, topic')} button={t('search')} search={search}/>}
				</div>
			</div>
			<div className="flex flex-1">
				<img src={banner.image} alt="intro-img"/>
			</div>
		</section>
	)
}

export default Banner
