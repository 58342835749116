import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PartnerProgram.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from "react-modal";
import footer_home from "../assets/Footer_Home@2x.png";
import { Trans, useTranslation } from "react-i18next";
import videoSupportGirl from "../assets/videSupportGIrl.png";
import videoSupportList from "../utils/videoSupportsList";
import VideoModalDynamic from "../components/VideoModalDynamic";
import SignUpLink from "../components/SignUpLink";
import DownloadLinks from "../components/DownloadLinks";
import Swiper from "swiper";
import b2b from "../assets/b2b.png";
import longAwaited from "../assets/longAwaited.png";
import unmatch from "../assets/unmatch.png";
import unleash from "../assets/unleash.png";
import hassleTOols from "../assets/hassleTOols.png";
import netPaymentTrms from "../assets/netPaymentTrms.png";
import priceListts from "../assets/priceListts.png";
import companyProf from "../assets/companyProf.png";
import inventDsh from "../assets/inventDsh.png";
import selfServeProtal from "../assets/selfServeProtal.png";
import cusAccs from "../assets/cusAccs.png";
import customStoreTheme from "../assets/customStoreTheme.png";
import cusCentric from "../assets/cusCentric.png";
import unifiedPOS from "../assets/unifiedPOS.png";
import realtimeMon from "../assets/realtimeMon.png";
import autInvoicing from "../assets/autInvoicing.png";
import secPayment from "../assets/secPayment.png";
import advSales from "../assets/advSales.png";
import roleSpec from "../assets/roleSpec.png";
import empAtt from "../assets/empAtt.png";
import cusBranding from "../assets/cusBranding.png";
import compExpense from "../assets/compExpense.png";
import supplierRelations from "../assets/supplierRelations.png";
import cusRel from "../assets/cusRel.png";
import autStock from "../assets/autStock.png";
import perMetrics from "../assets/perMetrics.png";
import dataBack from "../assets/dataBack.png";
import globalCurr from "../assets/globalCurr.png";
import taxCompliance from "../assets/taxCompliance.png";
import intBarcode from "../assets/intBarcode.png";
import empScheduling from "../assets/empScheduling.png";
import accurateCashAndCredit from "../assets/accurateCashAndCredit.png";
import multiiLang from "../assets/multiiLang.png";
import interactiveData from "../assets/interactiveData.png";
import promDisc from "../assets/promDisc.png";
import mobilesalesInv from "../assets/mobilesalesInv.png";
import intLoyalty from "../assets/intLoyalty.png";
import multiChannel from "../assets/multiChannel.png";
import shieldLoadinmg from "../assets/shieldLoadinmg.png";
import consUpdated from "../assets/consUpdated.png";
import TestimonialsComponent from "./TestimonialsComponent";
import joanCastillo from "../assets/joanCastillo.png";
import davidArellano from "../assets/davidArellano.png";
import josephSebatisan from "../assets/josephSebatisan.png";
import b2bPrev from "../assets/b2bPrev.png";
import compPOS from "../assets/compPOS.png";
import scaleInteg from "../assets/scaleInteg.png";
import multiLocc from "../assets/multiLocc.png";
import cuzUI from "../assets/cuzUI.png";
import realtimeLytics from "../assets/realtimeLytics.png";
import secureCompliant from "../assets/secureCompliant.png";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import ModalComponent from "../components/ModalComponent";
import gallery1 from "../assets/gallery1.png";
import gallery2 from "../assets/gallery2.png";
import gallery3 from "../assets/gallery3.png";
import gallery4 from "../assets/gallery4.png";
import leftArrowWithBox from "../assets/leftArrowWithBox.png";
import rightArrowWithBox from "../assets/rightArrowWithBox.png";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#F6F6FF",
    height: "95%",
    padding: "20px !important",
    width: "95%",
  },
};

Modal.setAppElement("#root");
function B2bEcommerce() {
  const { t, i18n } = useTranslation();

  let comprehensiveList = [
    {
      title: t("Supplier Profile Management"),
      img: companyProf,
      desc: t(`Effectively manage supplier information and communications.`),
    },
    {
      title: t(`Customer Centric Database`),
      img: cusCentric,
      desc: t(`Maintain comprehensive customer profiles, interactions, and preferences.`),
    },
    {
      title: t(`Unified Point of Sale (POS)`),
      img: unifiedPOS,
      desc: t(`Offer a seamless checkout experience across various channels.`),
    },
    {
      title: t(`Real-time Inventory Monitoring`),
      img: realtimeMon,
      desc: t(`Keep track of stock levels to prevent stockouts and optimize supply`),
    },
    {
      title: t(`Automated Invoicing System`),
      img: autInvoicing,
      desc: t(`Streamline billing processes with automated invoice generation and delivery.`),
    },
    {
      title: t(`Secure Payment Gateways`),
      img: secPayment,
      desc: t(`Ensure secure and convenient payment options for customers`),
    },
    {
      title: t(`Advanced Sales Analytics`),
      img: advSales,
      desc: t(`Gain insights into sales trends and customer behavior for data-driven decisions.`),
    },
    {
      title: t(`Role-specific Access Controls`),
      img: roleSpec,
      desc: t(`Maintain data security by assigning role-based access to employees.`),
    },
    {
      title: t(`Employee Attendance Tracking`),
      img: empAtt,
      desc: t(`Monitor employee attendance for accurate payroll processing.`),
    },
    {
      title: t(`Custom Branding for Invoices`),
      img: cusBranding,
      desc: t(`Present a professional image with customized invoice templates.`),
    },
    {
      title: t(`Comprehensive Expense Management`),
      img: compExpense,
      desc: t(`Track and categorize expenses for better financial control.`),
    },
    {
      title: t(`Supplier Relations Management`),
      img: supplierRelations,
      desc: t(`Efficiently manage supplier interactions and negotiations.`),
    },
    {
      title: t(`Customer Relationship Management (CRM)`),
      img: cusRel,
      desc: t(`Build strong customer relationships through detailed tracking.`),
    },
    {
      title: t(`Automated Stock Reordering`),
      img: autStock,
      desc: t(`Receive alerts and automate restocking to maintain optimal inventory levels.`),
    },
    {
      title: t(`Performance Metrics Dashboard`),
      img: perMetrics,
      desc: t(`Evaluate employee performance using measurable metrics.`),
    },
    {
      title: t(`Data Backup and Security`),
      img: dataBack,
      desc: t(`Protect critical business data with automated backup and robust security measures.`),
    },
    {
      title: t(`Global Currency Support`),
      img: globalCurr,
      desc: t(`Handle multiple currencies for international transactions.`),
    },
    {
      title: t(`Tax Compliance Tools`),
      img: taxCompliance,
      desc: t(`Stay compliant with tax regulations and simplify reporting.`),
    },
    {
      title: t(`Integrated Barcode Scanning`),
      img: intBarcode,
      desc: t(`Speed up inventory management and sales processes.`),
    },
    {
      title: t(`Intuitive Employee Scheduling`),
      img: empScheduling,
      desc: t(`Efficiently create and manage staff schedules.`),
    },
    {
      title: t(`Accurate Cash and Credit Reconciliation`),
      img: accurateCashAndCredit,
      desc: t(`Ensure accurate financial records with easy reconciliation.`),
    },
    {
      title: t(`Multi-language Customer Support`),
      img: multiiLang,
      desc: t(`Serve diverse customer needs with multiple language support.`),
    },
    {
      title: t(`Promotions and Discounts Management`),
      img: promDisc,
      desc: t(`Attract and retain customers with effective promotions.`),
    },
    {
      title: t(`Interactive Data Visualizations`),
      img: interactiveData,
      desc: t(`Visualize data trends for better decision-making.`),
    },
    {
      title: t(`Mobile Sales and Inventory Access`),
      img: mobilesalesInv,
      desc: t(`Stay connected on the go with mobile access to data.`),
    },
    {
      title: t(`Integrated Loyalty Program`),
      img: intLoyalty,
      desc: t(`Reward and retain loyal customers with a built-in loyalty program.`),
    },
    {
      title: t(`Multi-Channel Online Store Management`),
      img: multiChannel,
      desc: t(`Seamlessly manage sales across various online platforms.`),
    },
  ];

  const [seeMore, setSeeMore] = useState(false);
  const displayComp = () => {
    if (!seeMore) {
      return comprehensiveList.slice(0, 8);
    }
    return comprehensiveList;
  };

  const testimonialsData = [
    {
      image: joanCastillo,
      name: t("Joan Castillo"),
      position: t("Owner, AquaGlow Spa"),
      contentTitle:
        t("“B2B DizLogPlus has been an absolute game-changer for our business!”"),
      rating: 5,
      content: t(`This tool has streamlined our entire logistics and supply chain management process, making it efficient and effective like never before. The user-friendly interface allows our team to easily track and manage shipments, inventory, and deliveries, providing real-time updates that have significantly reduced the risk of delays and errors. With DizLogPlus, we have gained complete visibility and control over our logistics operations, enabling us to optimize routes and allocate resources more strategically.`),
    },
    {
      image: davidArellano,
      name: t("David Arellano"),
      position: t("Owner, FreshBake Delights"),
      contentTitle: `“We highly recommend DizLogPlus to any business seeking to optimize its logistics”`,
      content: t(`What sets B2B DizLogPlus apart is their exceptional customer support. From the onboarding process to ongoing assistance, their team has been incredibly responsive and helpful, addressing any queries or concerns promptly. They have been attentive to our unique business needs, customizing the tool to suit our requirements perfectly. Our experience with B2B DizLogPlus has exceeded our expectations, and we can confidently say that it has become an indispensable asset to our daily operations.`),
      rating: 5,
    },
    {
      image: josephSebatisan,
      name: t("Joseph Sebastian"),
      position: t("Owner, The Flower Nook"),
      contentTitle:
        t("“DizLogPlus has had a profound positive impact on our restaurant business”"),
      rating: 5,
      content: t(`This exceptional logistics management solution has revolutionized the way we handle our supply chain, making it more efficient, organized, and reliable. Since implementing DizLogPlus, we have seen a remarkable improvement in our inventory management process. The tool's intuitive interface allows us to track our ingredients, food supplies, and other essentials seamlessly. We can now maintain optimal stock levels, ensuring that we never run out of crucial items and avoiding unnecessary overstocking.`),
    },
  ];

  const askedQUestions = [
    {
      question: "What is B2B on DizLog?",
      answer:
        "B2B on DizLog refers to the business-to-business aspect of the DizLog platform. It means that DizLog offers specialized features and tools tailored specifically for businesses to conduct transactions, share information, and collaborate with other businesses, rather than serving individual consumers. This B2B focus enables seamless interactions between suppliers, manufacturers, distributors, and other entities within the supply chain, streamlining processes and enhancing efficiency in a business-to-business environment.",
      active: false,
    },
    {
      question: "How does it work?",
      answer: `DizLogPlus is a B2B product that likely functions as a comprehensive business management platform, designed to streamline various aspects of business operations. Here's a general overview of how it might work:
  
      <br/>     <br/>
      <strong>User Interface</strong>
      <br/>
      DizLogPlus likely offers a user-friendly interface that allows businesses to access and navigate through its various features effortlessly.
     
      <br/>     <br/>
      <strong>Customization</strong>
      <br/>
      Businesses can tailor the platform to their unique needs, setting up preferences and configurations to match their specific requirements.
     
      <br/>     <br/>
      <strong>POS and Inventory Management</strong>
      <br/>
      DizLogPlus would likely include advanced Point of Sale (POS) capabilities, enabling businesses to process sales transactions efficiently. It should also incorporate inventory management tools to track stock levels, manage stockouts, and automate reordering.
     
      <br/>     <br/>
      <strong>Order Fulfillment</strong>
      <br/>
      The platform might support order processing and fulfillment, making it easier for businesses to handle customer orders, track shipments, and manage returns.
      CRM and Customer Management: DizLogPlus may offer customer relationship
     
      <br/>     <br/>
      <strong>Real-time Analytics</strong>
      <br/>
      The product is likely to provide sophisticated reporting and analytics tools that give businesses real-time insights into their sales, inventory, and performance. This enables data-driven decision-making and helps identify growth opportunities.
     
      <br/>     <br/>
      <strong>Multi-Location Support</strong>
      <br/>
      If applicable, DizLogPlus might offer centralized management capabilities for businesses with multiple locations, allowing them to oversee operations and consolidate data from various sites.
     
     
      <br/>     <br/>
      <strong>Integration and Scalability</strong>
      <br/>
      DizLogPlus should seamlessly integrate with other systems and third-party applications, enhancing its usefulness and adaptability to different business setups and sizes.
     
      <br/>     <br/>
      <strong>Data Security</strong>
      <br/>
      As a crucial aspect of any business platform, DizLogPlus would likely prioritize data security to protect sensitive business information and comply with industry standards and regulations.
     
      `,
      active: false,
    },
    {
      question: "What features does B2B ecommerce solution have?",
      answer:
        "DizLogPlus B2B e-commerce solution boasts a diverse range of powerful features tailored to meet the unique demands of business-to-business interactions. The platform offers comprehensive Point of Sale (POS) functionality, empowering businesses to process sales transactions seamlessly. With advanced inventory management tools, DizLogPlus facilitates efficient stock tracking and automated reordering to avoid stockouts. The platform supports streamlined order fulfillment processes, enabling businesses to handle customer orders and monitor shipments effortlessly. Moreover, the integrated Customer Relationship Management (CRM) features help companies maintain valuable customer data, implement loyalty programs, and enhance customer retention. Real-time analytics and reporting provide businesses with data-driven insights into sales performance, inventory levels, and growth opportunities. DizLogPlus also facilitates centralized management for multi-location businesses, consolidating operations and data from various sites. With a focus on security, the solution ensures that sensitive information is safeguarded, complying with industry standards and regulations. Additionally, DizLogPlus boasts seamless integration with other systems and third-party applications, promoting scalability and adaptability to businesses of all sizes.",
      acttive: false,
    },
    {
      question: "What other features are coming?",
      answer:
        "DizLogPlus is continuously evolving to meet the ever-changing needs of businesses. The development team is working diligently to introduce a range of exciting new features that will further enhance the platform's capabilities. Among the upcoming additions are advanced marketing and sales tools, enabling businesses to craft targeted campaigns and promotions to reach their desired audience effectively. Additionally, DizLogPlus is set to offer enhanced analytics and reporting functionalities, providing businesses with even deeper insights into their operations and market trends. Improved integrations with third-party applications are on the horizon, fostering seamless connections with other essential tools in the business ecosystem. The platform is also gearing up to introduce AI-powered automation, streamlining repetitive tasks and optimizing workflows for increased efficiency. As DizLogPlus embraces cutting-edge technologies and user feedback, users can look forward to a future-proof B2B e-commerce solution that empowers businesses to thrive in the dynamic digital landscape.",
      acttive: false,
    },
    {
      question: "Can I integrate my ERP with DizLogPlus?",
      answer:
        "Absolutely! DizLogPlus is designed with seamless integration capabilities, allowing you to integrate your existing ERP (Enterprise Resource Planning) system with the platform. This integration facilitates the smooth flow of data between the two systems, streamlining your business operations and enhancing efficiency. By connecting your ERP with DizLogPlus, you can centralize and synchronize essential data, such as inventory levels, sales orders, customer information, and financial data, enabling real-time updates and accurate insights. This integration empowers you to harness the power of both systems, optimizing workflows, and gaining a comprehensive view of your business processes. Whether you use an in-house ERP or a third-party solution, DizLogPlus provides the flexibility and compatibility to make integration a seamless and advantageous experience.",
      acttive: false,
    },
    {
      question: "How can DTC businesses use a B2B platform?",
      answer:
        "Direct-to-Consumer (DTC) businesses can leverage a B2B platform like DizLogPlus to expand their reach and foster stronger relationships with wholesalers, retailers, and distributors. By utilizing a B2B platform, DTC businesses can offer wholesale accounts, enabling them to sell their products in bulk to other businesses. The platform provides DTC businesses with the necessary tools to manage bulk orders, negotiate pricing, and set specific terms for their B2B customers. Additionally, DTC businesses can benefit from advanced inventory management features to ensure they can meet the demands of their B2B partners efficiently. With a B2B platform in place, DTC businesses can streamline the ordering process, maintain accurate records, and strengthen communication with their wholesale customers, ultimately fostering growth and success in the B2B market segment.",
      acttive: false,
    },
    {
      question: "Is B2B on DizLogPlus included in my plan?",
      answer:
        "To find out if B2B features are included in your plan, I recommend checking the official website of DizLogPlus or contacting their customer support. They will be able to provide you with accurate and up-to-date information regarding the features available in your plan and any additional options or upgrades that may be available to you.",
      acttive: false,
    },
    {
      question:
        "How do you choose the best B2B ecommerce solution for your business?",
      answer:
        "Selecting the best B2B e-commerce solution for your business demands a methodical approach. Begin by understanding your business's specific needs, including order volume, product types, and integration requirements with existing systems. Scalability is crucial, ensuring the platform can grow alongside your business. Look for customization options to tailor the solution to your unique processes. Seamless integration with ERP and CRM systems is vital to avoid data silos. Prioritize data security and compliance to safeguard sensitive information. A user-friendly interface and mobile accessibility enhance usability. Real-time analytics and reporting empower data-driven decisions. Evaluate customer support, training resources, and overall cost to ensure a successful implementation and ROI. Reading user reviews and seeking references can offer valuable insights into other businesses' experiences. Finally, take advantage of free trials or demos to explore the platform firsthand before making a final decision.",
      acttive: false,
    },
  ];
  const [showModal, setShowModal] = useState(false);

  const handleShowModalGallery = () => {
    console.log("Play");
    setShowModal(!showModal);
  };
  const galleryImages = [
    {
      images: [gallery1, gallery2, gallery3, gallery4],
    },
  ];
  const [activeGalerryImageIdx, setactiveGalerryImageIdx] = useState(0);
  const [activeGalleryImage, setactiveGalleryImage] = useState(gallery4);
  return (
    <>
      <Header i18n={i18n} screen="support" />
      <div className="">
        <section>
          <div
            className="min-h-[600px] bg-[#004AAD] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className=" flex flex-1 flex-col justify-center items-center">
              <div className=" w-[90%] xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-4/5 flex flex-col">
                <div className=" flex flex-col w-full items-start py-4">
                  <span
                    className="font-medium 
                text-[20px] xl:text-base md:text-base lg:text-base sm:text-base font-[Roboto] text-white mb-2 text-center"
                  >
                    {t("B2B e-commerce")}
                  </span>
                  <span className="font-bold text-[20px] xl:text-4xl lg:text-4xl md:text-4xl sm:text-2xl font-[Raleway] text-white">
                   {t("B2B Harnesses the Potential of DizLogPlus")}
                  </span>
                  <span className="font-normal text-sm font-[Roboto] text-white py-3">
                      <span>
                        {t("Reach both your wholesale and DTC customers seamlessly with an integrated platform and store.")}
                      </span>
                  </span>
                </div>

                {window.location.pathname.startsWith("/ms") ||
                window.location.hostname.endsWith(".my") ? (
                  <SignUpLink />
                ) : (
                  <DownloadLinks />
                )}
              </div>
            </div>
            <div className=" flex flex-1 items-center justify-center py-4">
              <img
                src={b2b}
                className="h-[200px] xl:h-[376.61px] lg:h-[376.61px] md:h-[376.61px] sm:h-[376.61px] w-auto"
              />
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[430px] bg-[#DEC6B0] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-end justify-center pt-2">
              <img
                src={longAwaited}
                className="h-[200px] xl:h-[400px] lg:h-[400px] md:h-[400px] sm:h-[400px] w-auto"
              />
            </div>
            <div className="flex flex-1 items-center py-4 justify-center">
              <div className=" w-[90%] xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-4/5 flex flex-col">
                <div className=" flex flex-col w-full ">
                  <span
                    className="font-medium text-center block xl:hidden lg:hidden md:hidden sm:hidden
                text-sm xl:text-[16px] md:text-base lg:text-base sm:text-base font-[Roboto] font-[500] text-[#00215D] mb-2 "
                  >
                    {t("B2B on DizLogPlus")}
                  </span>
                  <span
                    className="hidden xl:block lg:block md:block sm:block font-medium 
                text-sm xl:text-[16px] md:text-base lg:text-base sm:text-base font-[Roboto] font-[500] text-[#00215D] mb-2 "
                  >
                    {t("B2B on DizLogPlus")}
                  </span>
                  <span className="block xl:hidden lg:hidden md:hidden sm:hidden font-bold leading-[25px] text-center  text-sm xl:text-4xl lg:text-4xl md:text-4xl sm:text-2xl font-[Raleway] text-[#00215D]">
                      {t("Your long-awaited B2B E-commerce Platform has Arrived")}
                  </span>
                  <span className="hidden xl:block lg:block md:block sm:block font-bold leading-[48px]   text-sm xl:text-4xl lg:text-4xl md:text-4xl sm:text-2xl font-[Raleway] text-[#00215D]">
                      {t("Your long-awaited B2B E-commerce Platform has Arrived")}
                  </span>
                  <span className="font-normal text-[14px] font-[Roboto] text-[#6B6B6B] py-3 leading-[18px] font-[400]">
                      <span>
                        {t("Embrace the future of B2B e-commerce with DizLog Plus and unlock unprecedented opportunities for growth and success. This powerful platform empowers you to effortlessly sell to wholesale clients while simultaneously catering to your direct customers, providing a comprehensive and efficient approach to managing your business.")}
                      </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[430px] bg-[#FEEDD3] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1  justify-center items-center py-4">
              <div className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                <span
                  className="text-[#00215D] mt-2 text-[16px] font-[500] font-[Roboto]
                            leading-[18px]"
                >
                  {t("All-in-one commerce")}
                </span>
                <span
                  className="text-[#00215D] mt-2 text-[20px] xl:text-[39px] lg:text-[39px] md:text-[39px] sm:text-[39px] font-[700] font-[Raleway]
                            leading-[25px] xl:leading-[45.79px] lg:leading-[45.79px] md:leading-[45.79px] sm:leading-[45.79px]"
                >
                  {t("Experience Unmatched Flexibility with DizlogPlus")}
                </span>
                <span
                  className="text-[#6B6B6B] w-full xl:w-[70%] lg:w-[70%] md:w-[70%] sm:w-[70%] mt-2 text-[14px] font-[400] font-[Roboto]
                            leading-[18px]"
                >
                  {t("Our all-in-one platform empowers your business with customizable workflows, personalized pricing models, and real-time inventory tracking. Adapt effortlessly to industry demands, collaborate seamlessly with partners, and make data-driven decisions through advanced analytics. Say goodbye to rigid systems and hello to the future of B2B transactions with DizlogPlus, your ultimate flexible solution.")}
                </span>
              </div>
            </div>
            <div className="flex flex-1 items-end justify-center">
              <img
                src={unmatch}
                className="h-[200px] xl:h-[380px] lg:h-[380px] md:h-[380px] sm:h-[380px] w-auto"
              />
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[430px] bg-[#FFFFFF] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-end justify-center pt-2">
              <img
                src={unleash}
                className="h-[200px] xl:h-[412px] lg:h-[412px] md:h-[412px] sm:h-[412px] w-auto"
              />
            </div>
            <div className="flex flex-1 items-center justify-center py-4">
              <div className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                <span
                  className="text-[#00215D] mt-2 text-[16px] font-[500] font-[Roboto]
                            leading-[18px]"
                >
                  {t("Power of DizLogPlus")}
                </span>
                <span
                  className="text-[#00215D] mt-2 text-[20px] xl:text-[39px] lg:text-[39px] md:text-[39px] sm:text-[39px] font-[700] font-[Raleway]
                            leading-[25px] xl:leading-[45.79px] lg:leading-[45.79px] md:leading-[45.79px] sm:leading-[45.79px]"
                >
                  {t("Unleash the Potential of Customization")}
                </span>
                <span
                  className="text-[#6B6B6B] w-full xl:w-[70%] lg:w-[70%] md:w-[70%] sm:w-[70%] mt-2 text-[14px] font-[400] font-[Roboto]
                            leading-[18px]"
                >
                  {t("B2B integration on DizLogPlus seamlessly incorporates the crucial wholesale functionalities directly into the core of DizLog, eliminating the necessity for applications or coding. Experience the full potential of DizLogPlus with a tailored purchasing journey featuring customizable store themes, net payment terms, discounts, and a simplified checkout process.")}
                </span>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[926px] bg-[#004AAD] px-6 ">
            <div className="flex flex-col items-center py-12">
              <span
                className="font-[Raleway] text-center font-[700] text-white leading-[25px] 
              xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px]"
              >
                {t("All the wholesale features you need. In one place.")}
              </span>
              <span
                className="font-[Roboto] w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] text-center font-[400] text-white leading-[18px] text-[14px]
              mt-4 xl:mt-2 lg:mt-2 md:mt-2 sm:mt-2"
              >
                {t("Farewell to complexities as DizLogPlus streamlines the checkout process, ensuring a smooth and hassle-free transaction for your clients. Embrace the power of personalization and unlock unparalleled opportunities for growth and success with DizLog Plus B2B e-commerce platform.")}
              </span>
            </div>
            <div className="flex flex-1 flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row px-2">
              <div className="flex flex-1 flex-col items-center justify-center">
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={companyProf} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Company Profiles")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Create and showcase your company profile to fellow Dizlog merchants, fostering networking, credibility, and potential B2B partnerships in a dynamic and interconnected marketplace.")}
                    </span>
                  </div>
                </div>
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={priceListts} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Price Lists")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Designed to optimize B2B transactions, empowering enterprises to fine-tune pricing strategies, accommodate bulk orders, and enhance collaboration with partners through personalized pricing structures.")}
                    </span>
                  </div>
                </div>
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={netPaymentTrms} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Net Payment Terms")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Enables businesses to establish tailored payment schedules for each client, fostering smoother financial interactions and strengthening rapport within the corporate ecosystem.")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col items-center justify-center">
                <img
                  src={inventDsh}
                  className="h-[250px] xl:h-[453px] lg:h-[453px] md:h-[453px] sm:h-[453px] w-[398px]"
                />
              </div>
              <div className="flex flex-1 flex-col items-center justify-center">
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={customStoreTheme} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Custom Store Theme")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Elevating the B2B e-commerce experience, empowering businesses to craft unique digital storefronts that resonate with their brand identity.")}
                    </span>
                  </div>
                </div>
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={cusAccs} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Customer Accounts")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Ensures efficient order tracking, personalized service, and seamless communication, bolstering long-term relationships and optimizing the B2B customer experience.")}
                    </span>
                  </div>
                </div>
                <div className="mb-2 p-3 min-h-[192px] bg-white rounded-md w-[330px] flex flex-col">
                  <div className="flex flex-col items-center">
                    <img src={selfServeProtal} className="h-[62px] w-[62px]" />
                    <span className="font-[Raleway] font-[700] text-[16px] text-[#00215D]">
                      {t("Self Serve Portal")}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="font-[Roboto] text-center font-[400] text-[14px] text-[#6B6B6B] leading-[18px] items-center">
                      {t("Streamlines procurement processes, enhances convenience, and fosters a self-driven, efficient avenue for businesses to interact and transact seamlessly within a controlled digital environment.")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="py-6 min-h-[848px] bg-[#F6F6FF]">
            <div className="flex flex-col items-center py-12">
              <span
                className="w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] font-[Raleway] text-center font-[700] text-[#00215D] 
              leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px]
              "
              >
                {t("Need more? Here's a comprehensive list of all the features available in DizLogPlus for you.")}
              </span>
              <span className="font-[Roboto] mt-2 w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] mt-2 text-center font-[400] text-[#6B6B6B] leading-[18px] text-[14px]">
                {t("Need more functionality? No problem! We've assembled a comprehensive list of all the cutting-edge features that DizLogPlus has to offer, ensuring that you have everything at your fingertips to streamline your tasks, boost productivity, and elevate your experience.")}
              </span>
            </div>
            <div className="flex w-full justify-center">
              <div
                className="px-2
              w-full xl:w-[67%] lg:w-[85%] md:w-[98%] sm:w-full
              "
              >
                <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-8 xl:gap-2 lg:gap-2 md:gap-2 sm:gap-2">
                  {displayComp().map((item) => (
                    <div className="mb-2 p-3 shadow-md h-[176px] bg-white rounded-md relative w-full flex flex-col mb-4">
                      <img
                        src={item.img}
                        className="h-[62px] w-[62px] relative "
                        style={{
                          left: "35%",
                          top: "-55px",
                        }}
                      />
                      <div className="flex flex-col absolute mt-4 pr-3">
                        <div className="flex flex-col items-center">
                          <span className="font-[Raleway] font-[700] text-center text-[16px] text-[#00215D]">
                            {item.title}
                          </span>
                        </div>
                        <div className="flex items-center mt-2">
                          <span className="font-[Roboto] text-center font-[400] text-[13px] text-[#6B6B6B] leading-[16px] items-center">
                            {item.desc}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="flex w-full justify-center cursor-pointer"
              onClick={() => setSeeMore(!seeMore)}
            >
              <span className="text-[#F09000] font-[Roboto] font-[600] text-[15px]">
                {t("See")} {seeMore ? t("Less") : t("More")}
              </span>
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[290px] bg-[#FEEDD3] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row py-4"
          >
            <div className="flex flex-1 items-center justify-end mx-2 mt-2 ">
              <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] bg-white shadow-sm rounded-md min-h-[150px] flex">
                <div className="w-12 xl:w-20 lg:w-20 md:w-20 sm:w-20 flex items-center justify-center">
                  <img
                    src={shieldLoadinmg}
                    className="h-[30px] xl:h-[62px] lg:h-[62px] md:h-[62px] sm:h-[62px] w-[30px]
                  xl:w-[62px] lg:w-[62px] md:w-[62px] sm:w-[62px]"
                  />
                </div>
                <div className="flex flex-col flex-1 justify-center">
                  <span className="text-[#00215D] font-[Raleway] font-[700] text-[17px] ">
                    {t("Pay once, Lifetime Updates")}
                  </span>
                  <span className="text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] pr-4">
                    {t("With our commitment to innovation, a one-time payment ensures you'll benefit from lifelong updates, continuously enhancing your business journey. Join us today and forever experience the power of seamless B2B interactions.")}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-1 items-center justify-start mx-2 mt-2   ">
              <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] bg-white shadow-sm rounded-md min-h-[150px] flex">
                <div className="w-12 xl:w-20 lg:w-20 md:w-20 sm:w-20 flex items-center justify-center">
                  <img
                    src={consUpdated}
                    className="h-[30px] xl:h-[62px] lg:h-[62px] md:h-[62px] sm:h-[62px] w-[30px]
                  xl:w-[62px] lg:w-[62px] md:w-[62px] sm:w-[62px]"
                  />
                </div>
                <div className="flex flex-col flex-1 justify-center">
                  <span className="text-[#00215D] font-[Raleway] font-[700] text-[17px] ">
                    {t("Constantly Updated and Supported")}
                  </span>
                  <span className="text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] pr-4">
                    {t("Our development team strives to enhance the product’s performance while prioritizing the integration of new features tailored to the unique needs of each user. Our dedicated support team remains vigilant, ensuring prompt resolution of all user inquiries and requests.")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[548px] bg-[#004AAD]">
            <TestimonialsComponent testimonials={testimonialsData} />
          </div>
        </section>
        <section className="">
          <div
            className="bg-[#DEC6B0] min-h-[490px] flex
            flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-center justify-center">
              <div className="w-[98%] xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] flex flex-col">
                <span
                  className="text-[#00215D] mt-2 text-[16px] font-[500] font-[Roboto]
                            leading-[18px]"
                >
                  {t("Seamless ERP integration")}
                </span>
                <span
                  className="text-[#00215D] mt-2 text-[20px] xl:text-[39px] lg:text-[39px] md:text-[39px] sm:text-[39px] font-[700] font-[Raleway]
                            leading-[25.79px] xl:leading-[45.79px] lg:leading-[45.79px] md:leading-[45.79px] sm:leading-[45.79px]"
                >
                  {t("Integrate your Tools without the Hassle.")}
                </span>
                <span
                  className="text-[#6B6B6B] mt-3 text-[14px] font-[400] font-[Roboto]
                            leading-[20px]"
                >
                  {t("Less friction, more ease. DizLogPlus is partnering with ERP providers like NetSuite, Acumatica, and Brightpearl—so you can run your business faster.")}
                </span>
                <div className="flex py-2 mt-3">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Enable seamless flow of all your business data to your admin by integrating your ERP using advanced APIs and partner integrations.")}
                    </span>
                  </div>
                </div>
                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Effortlessly handle your B2B customers, products, price lists, and inventory without the need for manual data entry.")}
                    </span>
                  </div>
                </div>
                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-full xl:w-[95%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex flex-col">
                    <span
                      className={` text-[14px] font-[400] font-[Roboto] text-[#6B6B6B]`}
                    >
                      {t("Achieve efficiency and simplicity by managing your entire e-commerce business from a centralized platform.")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-1 items-end justify-center">
              <img
                src={hassleTOols}
                className="h-[200px] xl:h-[412px] lg:h-[412px] md:h-[412px] sm:h-[412px] w-auto"
              />
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[430px] bg-[#F09000] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1">
              <img src={b2bPrev} className="w-auto h-full" />
            </div>
            <div
              className="flex flex-1 flex-col justify-center
            items-center xl:items-start lg:items-start md:items-start sm:items-start py-4"
            >
              <span className="text-[#FFFFFF] font-[500] font-[Roboto] text-[17px] ">
                {t("Live Preview")}
              </span>
              <span className="w-[80%] text-[#FFFFFF] mt-2 leading-[48px] font-[700] font-[Raleway] text-[42px] ">
                {t("Want to see DizLogPlus B2B at work?")}
              </span>
              <span className="w-[80%] text-[#FFFFFF] mt-2 leading-[18px] font-[400] font-[Roboto] text-[16px] ">
                {t("Experience the live preview and explore the various options and features available to enhance your e-commerce store.")}
              </span>
              <Link to="/live-demo">
                <button
                  className="outline-none border border-white w-[200px] h-[46px] mt-4 font-[Roboto]
                font-[700]  text-[15px] text-white"
                >
                  {t("LIVE PREVIEW")}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[880px] bg-[#004AAD] ">
            <div className="flex flex-col items-center w-full pt-4">
              <span className="font-[Raleway] font-[700] text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] text-white">
                {t("DizLogPlus B2B Highlights")}
              </span>
              <span className="font-[Roboto] font-[400] text-[16px] text-white mt-2">
                {t("Take a look at DizLog B2B major features previews")}
              </span>
            </div>
            <div
              className="flex flex-1 mt-4
            flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
            >
              <div className="flex flex-1 items-end justify-end w-full flex-col mr-2">
                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Comprehensive POS")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("Tailored for businesses with advanced POS features - sales processing, inventory management, order fulfillment, and CRM.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={compPOS} className="h-full w-full" />
                  </div>
                </div>

                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Scalability and Integration")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("From small enterprises to large corporations, DizLogPlus POS B2B accommodates all sizes, seamlessly integrating with existing systems for efficiency.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={scaleInteg} className="h-full w-full" />
                  </div>
                </div>

                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Multi-Location Management")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("Centrally manage multi-location businesses with DizLogPlus POS B2B, overseeing operations from a single dashboard.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={multiLocc} className="h-full w-full" />
                  </div>
                </div>
              </div>

              <div className="flex flex-1 items-start justify-center w-full flex-col ml-0 xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2">
                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Customizable User Interface")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("Customize POS interface for unique business needs, streamline workflows, and boost employee productivity with our product.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={cuzUI} className="h-full w-full" />
                  </div>
                </div>

                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Real-time Analytics")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("Real-time insights from advanced reports empower data-driven decisions, helping businesses spot trends and growth opportunities.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={realtimeLytics} className="h-full w-full" />
                  </div>
                </div>

                <div className="w-full xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] flex rounded-md bg-white min-h-[188px] p-3 mb-3">
                  <div className="flex flex-1 flex-col justify-center">
                    <span className="font-[Raleway] text-[17px] text-[#00215D] font-[700]">
                      {t("Secure and Compliant")}
                    </span>
                    <span className="font-[Roboto] text-[13px] text-[#6B6B6B] font-[400]">
                      {t("Data security is paramount; our product ensures compliance with industry standards, safeguarding sensitive information and meeting regulatory requirements.")}
                    </span>
                  </div>
                  <div className="flex flex-1">
                    <img src={secureCompliant} className="h-full w-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {showModal && (
            <ModalComponent
              showModal={showModal}
              toggle={handleShowModalGallery}
              contents={
                <div className="h-full w-full flex items-center justify-center">
                  <img
                    src={activeGalleryImage}
                    className="h-[424.5px] w-auto"
                  />
                </div>
              }
            />
          )}
          <div className="p-4 min-h-[426.09px] bg-[#FEF7EC] flex flex-col items-center justify-center">
            <span
              className="font-[Raleway] font-[700] text-[#00215D] text-[42px]
                        "
            >
              {t("Gallery")}
            </span>
            <span
              className="font-[Roboto] font-[400] text-[#6B6B6B] text-[14px] mt-4 text-center
                        "
            >
              {t("Browse gallery to have a little preview of DizLogPlus B2B features")}
            </span>

            <div className="mt-4 flex w-[85%]">
              <div className="flex items-center justify-center p-2">
                <img src={leftArrowWithBox} className="h-[30px] w-[30px]" />
              </div>
              <div
                className="w-full flex items-center justify-center
              flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
              >
                {galleryImages[activeGalerryImageIdx].images.map((item) => (
                  <img
                    src={item}
                    className="h-[140px]  p-2 w-full cursor-pointer"
                    onClick={() => {
                      setactiveGalleryImage(item);
                      handleShowModalGallery();
                    }}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center p-2">
                <img src={rightArrowWithBox} className="h-[30px] w-[30px]" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="min-h-[691px] bg-[#F6F6FF]">
            <FrequentlyAskedQuestionsComponent
              askedQUestions={askedQUestions}
            />
          </div>
        </section>
      </div>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default B2bEcommerce;
