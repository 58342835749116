import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import proffesionalService from "../assets/proffesionalService (1).png";
import utilitiesServices from "../assets/utilitiesServices.png";
import healthWelness from "../assets/healthWelness.png";
import consultingServices from "../assets/consultingServices.png";
import softwareServices from "../assets/softwareServices.png";

import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import receipts from "../assets/receipts.png";
import expandRentalService from "../assets/expandRentalService.png";
import rentalPropertyBusiness from "../assets/rentalPropertyBusiness.png";
import partySuppliesRental from "../assets/partySuppliesRental.png";
import weddingEffectRental from "../assets/weddingEffectRental.png";
import furnitureRental from "../assets/furnitureRental.png";
import vehicleRental from "../assets/vehicleRental.png";
import clothesRental from "../assets/clothesRental.png";
import storageRental from "../assets/storageRental.png";
import sportsHuntingRental from "../assets/sportsHuntingRental.png";
import techRental from "../assets/techRental.png";
import constructionRental from "../assets/constructionRental.png";

import displayRentalIcon from "../assets/displayRentalIcon.png";
import rentalPageEstore from "../assets/rentalPageEstore.png";
import monitorRentalFeesIcon from "../assets/monitorRentalFeesIcon.png";
import stockCatalogpage from "../assets/stockCatalogpage.png";
import keepTrackOfRentsICon from "../assets/keepTrackOfRentsICon.png";
import orderManageRentalPage from "../assets/orderManageRentalPage.png";

import { selectContentsBasedOnLanguage } from "../utils/dynamicContentsBasedonLanguage";
import SignUpLink from "../components/SignUpLink";
import DownloadLinks from "../components/DownloadLinks";
function RentalServices() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div
            className="min-h-[400px]  bg-[#FEEDD3] flex w-full items-center bg-opacity-50
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 items-end justify-center h-full">
              <img
                src={expandRentalService}
                className="w-[641.66px] h-auto mb-[-38px] p-3 xl:p-0 lg:p-0 md:p-0 sm:p-0"
              />
            </div>
            <div className="flex flex-1 flex-col pt-4 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0 pl-4 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0">
              <span className="py-2 font-[Roboto] font-[600] text-[20px] text-[#F09000] leading-[20px]">
                Rental Services
              </span>
              <span className="mb-4 font-[Raleway] font-[700] text-sm xl:text-[35px] lg:text-[35px] md:text-[35px] sm:text-[35px] text-[#00215D] leading-[40px] tracking-[0.2px] w-[80%]">
                Expand Your Rental Business
              </span>

              {window.location.pathname.startsWith("/ms") ||
              window.location.hostname.endsWith(".my") ? (
                <SignUpLink />
              ) : (
                <DownloadLinks />
              )}
            </div>
          </div>
        </section>
        <section>
          <div
            className="h-[2000px] xl:h-[576px] lg:h-[576px] md:h-[576px] sm:h-[576px] bg-red-100 w-full grid 
          grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-5 gap-0"
          >
            <div
              className={`bg-[url(${rentalPropertyBusiness})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Property Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${partySuppliesRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Party Supplies Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${weddingEffectRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Wedding Equipment Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${furnitureRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Furniture Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${vehicleRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Vehicle Rental Services
              </span>
            </div>
            <div
              className={`bg-[url(${clothesRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Clothes Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${storageRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Storage Rental Business
              </span>
            </div>
            <div
              className={`bg-[url(${sportsHuntingRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-full xl:w-[60%] lg:w-[60%] md:w-[60%] sm:w-[60%] pb-3 pl-3">
                Sports & Hunting Equipment Rental
              </span>
            </div>
            <div
              className={`bg-[url(${techRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px]  pb-3 pl-3 tracking-[0.2px]">
                Tech Rental-Computer/Mobile/Tablet/Server
              </span>
            </div>
            <div
              className={`bg-[url(${constructionRental})] bg-cover bg-no-repeat flex items-end justify-start`}
            >
              <span className="font-[Raleway] font-[700] text-[20px] text-white leading-[27px] w-[80%] pb-3 pl-3">
                Construction Equipment Rental Business
              </span>
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[560px] py-7 flex bg-white
          flex-col-reverse xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 justify-center items-center">
              <div className="flex w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] justify-center items-start flex-col leading-[48px]">
                <span
                  className="font-[Raleway] leading-[48px] font-[700] text-xs xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] text-[#00215D] flex items-center 
                mb-2 xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 pt-2 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0"
                >
                  <img
                    src={displayRentalIcon}
                    className="h-auto w-[40px] mr-2"
                  />
                  Display Rental Products in Online Store
                </span>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Online Reservation System
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Provide customers with a user-friendly interface to browse
                      and reserve rental items online, allowing them to select
                      specific dates and times for their rental periods.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Rental Pricing and Packages
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Set flexible pricing options for rental services, such as
                      hourly, daily, or weekly rates, as well as offer
                      customizable packages or discounts for longer rental
                      durations.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Availability Calendar
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Display a real-time availability calendar on your online
                      store, allowing customers to see the rental availability
                      for specific dates and make informed booking decisions.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Online Payments
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Enable secure online payment processing, allowing
                      customers to conveniently pay for their rental services
                      through various payment methods, such as credit cards or
                      digital wallets.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-start items-center pl-6">
              <img src={rentalPageEstore} className="w-[384px] h-[400px]" />
            </div>
          </div>
        </section>
        <section>
          <div
            className="min-h-[442px] py-7 w-full bg-[#004AAD] flex
          flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 justify-center items-center">
              <img src={stockCatalogpage} className="w-[540px] h-auto" />
            </div>
            <div
              className="flex flex-1 justify-center items-start flex-col pt-2 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0
            pl-4 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0"
            >
              <span
                className="font-[Raleway] font-[700] text-base xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px] text-white flex items-center 
              mb-2 xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4"
              >
                <img
                  src={monitorRentalFeesIcon}
                  className="h-[40px] w-[40px] mr-2"
                />
                Monitor Rental Fees
              </span>

              <div className="flex py-2 ">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[80%] flex flex-col">
                  <span className="text-[#F09000] text-[13px] font-[700] font-[Roboto] ">
                    Rental Item Tracking
                  </span>
                  <span className="text-white text-[12px] font-[400] font-[Roboto] ">
                    Efficiently manage your rental inventory by tracking
                    individual items, including their availability, location,
                    and rental history.
                  </span>
                </div>
              </div>

              <div className="flex py-2 ">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[80%] flex flex-col">
                  <span className="text-[#F09000] text-[13px] font-[700] font-[Roboto] ">
                    Rental Duration and Pricing
                  </span>
                  <span className="text-white text-[12px] font-[400] font-[Roboto] ">
                    Set rental durations and pricing options based on your
                    business needs, allowing you to customize rental periods and
                    rates for different items or rental categories.
                  </span>
                </div>
              </div>

              <div className="flex py-2 ">
                <div className=" w-6 items-center flex justify-start">
                  <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                </div>
                <div className="leading-[18px] w-[80%] flex flex-col">
                  <span className="text-[#F09000] text-[13px] font-[700] font-[Roboto] ">
                    Integration with POS and Online Store
                  </span>
                  <span className="text-white text-[12px] font-[400] font-[Roboto] ">
                    Seamlessly integrate your inventory management system with
                    your Point of Sale (POS) and online store, enabling
                    real-time inventory updates and a synchronized rental
                    experience across channels.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className="py-7 min-h-[490px] bg-[#FEF7EC] w-full flex
          flex-col-reverse xl:flex-row lg:flex-row md:flex-row sm:flex-row"
          >
            <div className="flex flex-1 justify-center items-center">
              <div className="flex w-[90%] xl:w-[80%] lg:w-[80%] md:w-[80%] sm:w-[80%] justify-center items-start flex-col ">
                <span
                  className="font-[Raleway] font-[700] text-base xl:text-[38px] lg:text-[38px] md:text-[38px] sm:text-[38px]
                pt-2 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0 text-[#00215D] flex items-center
                mb-2 xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4"
                >
                  <img
                    src={keepTrackOfRentsICon}
                    className="h-[40px] w-[40px] mr-2"
                  />
                  Keep Track of Rent Payments
                </span>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Rental Order Management
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Streamline the management of rental orders by tracking
                      order status, payment details, pickup and return dates,
                      and customer information in a centralized system.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Notifications and Reminders
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Send automated notifications and reminders to customers
                      regarding upcoming order pickups, return deadlines, and
                      any updates or changes to their rental orders.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Integration with Payment Gateways
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Seamlessly integrate with secure payment gateways to
                      enable online payments, allowing customers to conveniently
                      pay for their rental orders and ensuring a smooth checkout
                      process.
                    </span>
                  </div>
                </div>

                <div className="flex py-2 ">
                  <div className=" w-6 items-center flex justify-start">
                    <img src={orangeCircleCheckWhite} className="h-3 w-auto" />
                  </div>
                  <div className="leading-[18px] w-[90%] flex flex-col">
                    <span className="text-[#00215D] text-[13px] font-[700] font-[Roboto] ">
                      Order History and Reporting
                    </span>
                    <span className="text-[#6B6B6B] text-[13px] font-[400] font-[Roboto] ">
                      Maintain a comprehensive order history, allowing you to
                      access past rental orders, track customer preferences,
                      analyze trends, and generate reports for business insights
                      and decision-making
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-start items-center">
              <img src={orderManageRentalPage} className="w-[540px] h-auto" />
            </div>
          </div>
        </section>
      </div>
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default RentalServices;
