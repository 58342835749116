import React from 'react'
import './Title.css'

function Title({logo, title}) {
	return (
		<div className="tagline">
			<img className='tagline-img' src={logo} alt={title}/>
			<p className="tagline-desc" style={{fontFamily:"Sansation"}}>&nbsp;{title}</p>
		</div>
	)
}

export default Title
