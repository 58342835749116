import React from "react";
import i18n from "../i18n";

//import components
import Header from "../components/Header";
import Footer from "../components/Footer";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import ReceiveAssistance from "../components/sections/ReceiveAssistance";
import TwoColumnTable from "../components/sections/TwoColumnTable";
import BigImageBannerSection from "../components/sections/BigImageBannerSection";
import ThreeBigCards from "../components/sections/ThreeBigCardsSection";

//import assets
import BigImageBanner from "../assets/bigimagebanner_beauty.png";
import BigImageIcon1 from "../assets/bigimageicon_beauty_1.png";
import BigImageIcon2 from "../assets/bigimageicon_beauty_2.png";
import BigImageIcon3 from "../assets/bigimageicon_beauty_3.png";
import ThreeBigCards1 from "../assets/threebigcards_beauty_1.png";
import ThreeBigCards2 from "../assets/threebigcards_beauty_2.png";
import ThreeBigCards3 from "../assets/threebigcards_beauty_3.png";
import beautyBannerSrc from "../assets/beauty-banner@3x.png";
import BannerSection from "../components/BannerSection";

const Beauty = () => {
  const { t } = i18n;

  const askedQUestions = [
    {
      question: t("Is DizLog POS really free?"),
      answer: t(
        "Dizlog POS is Free at the first 14 days from your sign up date. To enjoy Dizlog POS further, check out our pricing plans here. <a href='https://dizlog.com/pricing' target='_blank' className='text-orange-600'>https://dizlog.com/pricing</a>"
      ),
      active: false,
    },
    {
      question: t("How to use DizLog POS system?"),
      answer: t(
        "To use Dizlog POS System.<br/><br/>1.Open Web App or Download the Dizlog App.<br/>2.Sign in or Create a new Account.<br/>3.Open POS feature and start processing orders<br/>If you are using Mobile version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app</a><br/> If you are using Web App version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app</a>"
      ),
      active: false,
    },
    {
      question: t("Does DizLog work offline?"),
      answer: t(
        "YES! Offline Web POS is a feature that allows merchants to continue operating their point-of-sale system, even when there is no internet connection available.<br/><br/>With Offline Web POS, you can continue to process cash transactions and keep your business running smoothly, without interruption. Click Here to learn how to use Dizlog POS offline. <a href='https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets</a>"
      ),
      active: false,
    },
    {
      question: t("What kind of hardware can work with DizLog?"),
      answer: t(
        "Dizlog software is compatible with a wide range of hardware devices, including computers, smartphones, and tablets. Whether you prefer to work on a desktop computer, stay productive on your phone, or enjoy the flexibility of a tablet, our software seamlessly adapts to your preferred device. With Dizlog, you can leverage the power of our software solution on any hardware device that suits your needs, providing you with the freedom to work and manage your business efficiently wherever you are.<br/><br/>Open WebApp thru - <a className='text-orange-600 ' href='https://web.dizlog.com/' target='_blank'>https://web.dizlog.com/</a><br/>or download the app via playstore, appstore or appgallery"
      ),
      active: false,
    },
    {
      question: t("Does DizLog have the functionality of loyalty cards?"),
      answer: t(
        "Yes, with Dizlog Loyalty Program We can encourage customers to return to our business and make Repeat purchases. This program offers Rewards and incentives to customers who make purchases or engage with our Business in certain ways.<br/><br/>Click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards</a>"
      ),
      active: false,
    },
  ];

  const twoColumnTableData = {
    title: t("Maximize your Operational Efficiency"),
    description: t(
      "Effectively coordinate and streamline operations across various locations, sales channels, and staff to enhance efficiency and profitability."
    ),
    headers: [t("Objective"), t("Solution")],
    rows: [
      {
        objective: t("Oversee the Cash Flow"),
        solution: [
          t(
            "Access your earnings instantly with Automatically allocate a portion of each sale for taxes or savings."
          ),
          t("Receive loan offers based on your  card sales."),
        ],
      },
      {
        objective: t("Connect with Clients"),
        solution: [
          t(
            "Increase reservations using marketing tools for email and SMS campaigns."
          ),
          t(
            "Handle customer relationships with a complimentary CRM for storing crucial information."
          ),
        ],
      },
      {
        objective: t("Supervise your Staff"),
        solution: [
          t(
            "Effectively oversee your staff and their schedules across multiple locations through a unified platform."
          ),
          t(
            "Save time with customizable employee commission tracking and integration with  Payroll."
          ),
        ],
      },
      {
        objective: t("Sophisticated Analytics"),
        solution: [
          t(
            "Utilize integrated data synchronization to assess your business's performance."
          ),
          t(
            "Access easily understandable intelligent reports to guide your future actions."
          ),
        ],
      },
      {
        objective: t("Expand Income Streams"),
        solution: [
          t("Broaden your revenue sources by exploring new avenues."),
          t(
            "Monitor profit margins through inventory management and reporting functionalities."
          ),
          t(
            "Sync orders, items, and inventory seamlessly from your POS to your website."
          ),
        ],
      },
    ],
  };

  const bigImageBannerData = {
    title: t("Utilize Intelligent Inventory Tools for your Salon"),
    description: t(
      "Proudly presents an exceptional POS software tailored specifically for beauty salons, equipped with a wide array of powerful features meticulously designed to facilitate and drive growth within your business."
    ),
    buttons: [
      {
        icon: BigImageIcon1,
        text: t("Appointment Scheduling"),
      },
      {
        icon: BigImageIcon2,
        text: t("Integrated Service Menu"),
      },
      {
        icon: BigImageIcon3,
        text: t("Confirmations and Reminder"),
      },
    ],
    image: BigImageBanner,
  };

  const threeBigCardsData = {
    title: t("Level up, Check out, and Keep Progressing"),
    items: [
      {
        image: ThreeBigCards1,
        text: t("Align your operations with hardware and an Appointments POS."),
      },
      {
        image: ThreeBigCards2,
        text: t(
          "Process items and services seamlessly in a single transaction for each booking."
        ),
      },
      {
        image: ThreeBigCards3,
        text: t(
          "Communicate directly with clients through your POS to handle appointments."
        ),
      },
    ],
  };

  return (
    <>
      <Header i18n={i18n} />
      <BannerSection
        title={t("Beauty")}
        imgSrc={beautyBannerSrc}
        description={t(
          "DizLog POS is the perfect tool for beauty businesses like hair salons, nail salons, spas, and barbershops, offering streamlined management of appointments, sales, and inventory. Its intuitive interface ensures smooth booking and checkout processes, enhancing client satisfaction. With real-time inventory tracking, you can efficiently manage stock for beauty products and supplies. Detailed sales analytics provide insights into top services and products, helping you make informed decisions to grow your business."
        )}
        reqDemoPath={"https://meetings.hubspot.com/support-diglog"}
      />
      <ThreeBigCards hasNav data={threeBigCardsData} />
      <BigImageBannerSection data={bigImageBannerData} />
      <TwoColumnTable data={twoColumnTableData} />
      <ReceiveAssistance />
      <FrequentlyAskedQuestionsComponent askedQUestions={askedQUestions} />
      <Footer />
    </>
  );
};

export default Beauty;
