import React from 'react'
import ArrowRight from '../../assets/arrow-right.png'

const TypesList = ({data}) => {
  return (
    <section className='px-4 py-8 bg-[#004AAD] flex flex-col items-center'>
      <div className='w-full max-w-4xl flex flex-col gap-6 text-white'>
        <div className='font-semibold font-[Raleway] text-xl'>
          {data.title}
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-3 gap-3 font-[Roboto]'>
          {data.items.map((item, index) => (
            <div
              key={index}
              className='rounded-md bg-white/10 shadow-sm p-4 text-sm'
            > 
              {item.title} <img src={ArrowRight} alt='arrow-right' className='w-4 h-4 inline' />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TypesList