import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import genMerchan from "../assets/genMerchan.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import optimizeInvent from "../assets/optimizeInvent.png";
import enhancedIcon from "../assets/enhancedIcon.png";
import topItemsMerchandise from "../assets/topItemsMerchandise.png";
import templateSewttings from "../assets/templateSewttings.png";
import phoneTouchIcon from "../assets/phoneTouchIcon.png";
import adjustSTockMerchandise from "../assets/adjustSTockMerchandise.png";
import cust from "../assets/cust.png";
import grocPay from "../assets/grocPay.png";
import estoreMerchandise from "../assets/estoreMerchandise.png";
import shieldIcon from "../assets/shieldIcon.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import olivaMendoza from "../assets/olivaMendoza.png";
import lukeSantos from "../assets/lukeSantos.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function GeneralMerchandise() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "447px",
      image: {
        src: grocPay,
        height: "286.93px",
      },
      title: t("Efficient Point of Sale Transactions"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: optimizeInvent,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Swift and Intuitive Checkout"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog POS for seamless in-store transactions, ensuring efficient checkouts that enhance customer satisfaction and reduce waiting times."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Pricing and Automatic Discounts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS guarantees precise pricing and automatic discount application, minimizing errors and providing a smooth shopping experience for customers."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Versatile Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS supports multiple payment methods, including cash, credit cards, and digital wallets, giving customers flexibility and convenience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "478px",
      image: {
        src: adjustSTockMerchandise,
        height: "318px",
      },
      title: t("Optimized Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Inventory Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Keep constant tabs on your merchandise using Dizlog POS, ensuring up-to-the-moment insights into stock levels to avoid shortages."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Stock Replenishment"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS can automatically trigger reorder alerts based on preset thresholds, guaranteeing timely restocking of popular items and reducing stockouts."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Unified Inventory Control"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain a centralized inventory view across your physical store and online platform through Dizlog POS, preventing discrepancies and optimizing stock levels."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "590px",
      image: {
        src: estoreMerchandise,
        height: "430px",
      },
      title: t("Seamless Online Store Integration"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: phoneTouchIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Catalog"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog POS to create an expansive digital catalog of your merchandise, providing online shoppers with an engaging and user-friendly browsing experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Online Shopping"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS simplifies online ordering, offering customers an intuitive platform to explore, select, and purchase items from your store's virtual shelves."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Consistent Inventory Across Channels"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS ensures continual synchronization between your physical store and online inventory, eradicating disparities and elevating the overall shopping journey."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "475px",
      image: {
        src: templateSewttings,
        height: "231.53px",
      },
      title: t("Simplified Invoicing and Secure Payments"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: shieldIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate polished invoices effortlessly through Dizlog POS, providing customers with accurate records of their purchases and ensuring smooth transactions."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Flexibility"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog POS offers diverse payment options, empowering customers to choose their preferred methods and contributing to a frictionless payment process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Employ Dizlog POS to monitor payment statuses and issue automated reminders, reducing outstanding balances and enhancing financial efficiency."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "##004AAD",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Effective Payroll and Efficient Team Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Staff Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to optimize staff schedules and shifts, ensuring optimal coverage during peak hours and enabling effective workforce management."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog captures precise employee work hours and attendance, simplifying payroll calculations and fostering fair compensation practices."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Analyze employee performance data within Dizlog, empowering informed decisions regarding training, recognition, and career development."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topItemsMerchandise,
        height: "323.38px",
      },
      title: t("Data-driven Insights for Informed Decisions"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Sales Analytics and Emerging Trends"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog analytics to identify sales patterns and emerging merchandise trends, enabling strategic inventory decisions and targeted marketing efforts."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Inventory Reports and Anticipated Demand"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate comprehensive inventory reports and demand forecasts using Dizlog, supporting inventory optimization and ensuring product availability."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Productivity Evaluation"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog data to assess employee productivity and sales performance, facilitating performance reviews and fostering team excellence."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },

    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: cust,
        height: "256.95px",
      },
      title: t("Enhanced Customer Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: enhancedIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Customer Interactions"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage customer data within Dizlog to tailor price list and payment terms"
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Offers"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement loyalty programs through Dizlog to reward frequent customers and incentivize repeat purchases of your artistic products."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];

  let testimonials = [
    {
      content: t(
        "“DizLog POS has been an essential tool for our General Merchandise store. Its efficiency in order management and sales analytics, and has allowed us to provide exceptional service and thrive in the competitive retail market. I highly recommend DizLog POS to any General Merchandise business owner”"
      ),
      name: t("Olivia Mendoza"),
      designation: t("General Merchandise Owner"),
      imageSrc: olivaMendoza,
    },
    {
      content: t(
        "“The inventory management feature has been a lifesaver for our General Merchandise store. With real-time updates on stock levels, we can easily keep track of our inventory, ensuring that popular items are always available and preventing stockouts. This has improved our inventory control and reduced unnecessary costs.”"
      ),
      name: t("Luke Santos"),
      designation: t("General Merchandise Owner"),
      imageSrc: lukeSantos,
    },
  ];

  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row">
            <div className="flex flex-1 items-end justify-center pt-4">
              <img src={genMerchan} className="h-[90%] max-h-[500px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for General Merchandise")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS is an indispensable tool for General Merchandise businesses, offering a versatile and comprehensive solution that can significantly optimize their operations and improve the overall shopping experience. With its user-friendly interface, the system simplifies the checkout process, enabling efficient order processing and quick transactions for customers. Whether the business deals with clothing, electronics, home goods, or a variety of products, DizLog POS streamlines sales and inventory management, providing real-time updates on stock levels to ensure popular items are always available and reducing the risk of stockouts."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default GeneralMerchandise;
