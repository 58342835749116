import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_home from "../assets/Footer_Home@2x.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation, Trans } from "react-i18next";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import orangeCircleCheckWhite from "../assets/orangeCircleCheckWhite.png";
import SingleContentComponentWithBulletList from "../components/SingleContentComponentWithBulletList";
import nailCare from "../assets/nailCare.png";
import bookingICON from "../assets/bookingICON.png";
import checkoutPedicure from "../assets/checkoutPedicure.png";
import appointmentProf from "../assets/appointmentProf.png";
import simpliFiedd from "../assets/simpliFiedd.png";
import streamPayrollICON from "../assets/streamPayrollICON.png";
import payrollSettingsBus from "../assets/payrollSettingsBus.png";
import dataDriverIcon from "../assets/dataDriverIcon.png";
import nailCUstomDisc from "../assets/nailCUstomDisc.png";
import efficientICon from "../assets/efficientICon.png";
import nailCareInvoiec from "../assets/nailCareInvoiec.png";
import nailCaresalonPOS from "../assets/nailCaresalonPOS.png";
import enhancedIcon from "../assets/enhancedIcon.png";
import topitemNail from "../assets/topitemNail.png";
import adjustStockNail from "../assets/adjustStockNail.png";
import LearnHowToUseDizlogComponent from "../components/LearnHowToUseDizlogComponent";
import JasmineTan from "../assets/JasmineTan.png";
import AustinManalo from "../assets/AustinManalo.png";
import CTAButtons from "../components/CTAButtons";
import OurTestimonialsV2 from "../components/OurTestimonialsV2";

function NailCareBeauty() {
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const ourTestimonials = [
    {
      content: t(
        "“As the proud owner of a Nail Care Salon, I can't praise DizLog POS enough for the incredible impact it has had on our business. The appointment management feature has been a game-changer, allowing us to seamlessly schedule and manage client bookings, leading to smoother operations and better client satisfaction.”"
      ),
      name: t("Jasmine Tan"),
      designation: t("Nail Care Salon Owner"),
      imageSrc: JasmineTan,
    },
    {
      content: t(
        "“DizLog POS's real-time analytics have given us valuable insights into our peak hours, popular nail designs, and revenue trends, empowering us to make informed decisions and adjust our services accordingly. DizLog POS has been instrumental in taking our Nail Care Salon to new heights, and I highly recommend it to any salon owner.”"
      ),
      name: t("Austin Manalo"),
      designation: t("Nail Care Salon Owner"),
      imageSrc: AustinManalo,
    },
  ];

  let SubContents = [
    {
      isImageRight: false,
      backgroundColor: "#FFFFFF",
      minHeight: "475px",
      image: {
        src: checkoutPedicure,
        height: "252.66px",
      },
      title: t("Streamlined Salon Operations with Dizlog"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: efficientICon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Effortless Client Checkout"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for seamless and efficient payment processing, allowing clients to complete transactions smoothly and enhancing the overall salon experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Service Pricing and Discounts"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure precise pricing and automated application of discounts with Dizlog, minimizing errors and providing clients with a transparent billing process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Diverse Payment Options"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog supports various payment methods, including cards and digital payments, offering clients flexibility and convenience when settling bills."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: adjustStockNail,
        height: "318px",
      },
      title: t("Optimal Appointment and Inventory Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: appointmentProf,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Appointment Tracking"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to manage appointments in real-time, ensuring efficient scheduling and minimizing wait times for clients."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Product Alerts"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog can generate automated alerts for low stock levels, ensuring timely restocking of nail care products and preventing inventory shortages."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Centralized Inventory Control"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Maintain a centralized view of nail care products across physical and online platforms using Dizlog, reducing overstocking and understocking"
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "475px",
      image: {
        src: nailCaresalonPOS,
        height: "289px",
      },
      title: t("Seamlessly Integrated Online Booking"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: bookingICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Comprehensive Online Services Catalog"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Create a comprehensive online catalog of nail care services with Dizlog, allowing clients to explore and book appointments conveniently."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("User-friendly Online Booking"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog simplifies the online booking process, providing clients with an intuitive platform to select and schedule their desired nail care services."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Real-time Booking Synchronization"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Ensure real-time synchronization of appointments between your salon and online platform using Dizlog, enhancing convenience for clients."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "557.92px",
      image: {
        src: nailCareInvoiec,
        height: "397.92px",
      },
      title: t("Simplified Invoicing and Payment Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: simpliFiedd,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Automated Invoicing"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate accurate and automated invoices with Dizlog, providing clients with clear documentation of services and simplifying the invoicing process."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: "Flexible Payment Options",
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog supports a range of payment methods, allowing clients to pay using their preferred options and enhancing the ease of payment."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Payment Tracking and Reminders"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog to track payment statuses and send automated payment reminders, reducing outstanding balances and improving cash flow."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#004AAD",
      minHeight: "475px",
      image: {
        src: payrollSettingsBus,
        height: "270px",
      },
      title: t("Efficient Payroll and Staff Management"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#FFFFFF]",
      titleImg: streamPayrollICON,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Streamlined Employee Scheduling"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog for optimized staff scheduling, ensuring adequate coverage during peak hours and enhancing salon operations."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Accurate Timekeeping"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Dizlog records employee work hours accurately, simplifying payroll processing and ensuring fair compensation for your nail care staff."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Performance Insights"),
          titleClassNames:
            "text-[#F09000] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Analyze employee performance data within Dizlog to make informed decisions about training, incentives, and career growth opportunities."
          ),
          descriptionClassNames:
            "text-[#FFFFFF] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: true,
      backgroundColor: "#FFFFFF",
      minHeight: "483.38px",
      image: {
        src: topitemNail,
        height: "323.38px",
      },
      title: t("Data-driven Insights for Informed Decisions"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: dataDriverIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Client Service Analytics"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Leverage Dizlog analytics to gain insights into popular nail care services and client preferences, guiding service offerings and marketing strategies."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Inventory Reports and Trends"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Generate detailed inventory reports and identify trends using Dizlog, aiding in inventory management and ensuring product availability."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Employee Productivity Analysis"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize Dizlog data to assess employee productivity and performance, facilitating performance reviews and improving team efficiency."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
    {
      isImageRight: false,
      backgroundColor: "#FEF7EC",
      minHeight: "416.95px",
      image: {
        src: nailCUstomDisc,
        height: "256.95px",
      },
      title: t("Enhanced Client Experience and Engagement"),
      titleClassNames:
        "font-[Raleway] font-[700] text-[29px] leading-[50px] text-[#00215D]",
      titleImg: enhancedIcon,
      titleImgClassNames: "",
      titleDesc: "",
      titleDescImgClassNames: "",
      bullets: [
        {
          icon: orangeCircleCheckWhite,
          title: t("Personalized Client Interactions"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Utilize client data within Dizlog to offer personalized recommendations and tailored services, enhancing the nail care salon experience."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
        {
          icon: orangeCircleCheckWhite,
          title: t("Loyalty Programs and Rewards"),
          titleClassNames:
            "text-[#00215D] font-[Roboto] font-[700] text-[13px] leading-[18px] ",
          description: t(
            "Implement loyalty programs through Dizlog to reward loyal clients, fostering client retention, and increasing engagement."
          ),
          descriptionClassNames:
            "text-[#6B6B6B] font-[Roboto] font-[400] text-[13px] leading-[18px]",
        },
      ],
    },
  ];
  return (
    <>
      <Header i18n={i18n} screen="business-types" />
      <div id="businessRetail">
        <section>
          <div className="min-h-[366px] bg-[#FEF7EC] flex flex flex-col md:flex-row gap-2 md:gap-0">
            <div className="flex flex-1 items-center justify-center">
              <img src={nailCare} className="h-[284px] w-auto" />
            </div>
            <div className="flex flex-1 justify-center items-center py-4">
              <div className="flex flex-col w-[80%]">
                <span
                  className="font-[Raleway] font-[700] 
                  text-[20px] xl:text-[42px] lg:text-[42px] md:text-[42px] sm:text-[42px] 
                  leading-[28px] xl:leading-[48px] lg:leading-[48px] md:leading-[48px] sm:leading-[48px] text-[#00215D]"
                >
                  {t("DizLog for Nail Care Salons")}
                </span>
                <span
                  className="mt-2 font-[Roboto] font-[400] 
                  text-[14px] leading-[19px] text-[#6B6B6B]"
                >
                  {t(
                    "DizLog POS presents itself as an invaluable asset to Nail Care Salons, revolutionizing their daily operations and providing a seamless platform to enhance customer satisfaction and business efficiency. The comprehensive point-of-sale system simplifies appointment management, enabling nail technicians to efficiently schedule and organize client bookings, ensuring smooth service flow and reduced wait times. With DizLog POS, salon owners can effortlessly track inventory levels of nail care products, polishes, and accessories, triggering automated alerts when supplies need replenishment, avoiding stockouts and streamlining the procurement process."
                  )}
                </span>
                <div className="mt-8">
                  <CTAButtons requestToDemoPath="https://meetings.hubspot.com/support-diglog" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {SubContents.map((item) => (
          <SingleContentComponentWithBulletList data={item} />
        ))}
      </div>
      <LearnHowToUseDizlogComponent />
      <OurTestimonialsV2 className="bg-[#F6F6FF]" />
      <Footer footerImage={footer_home} className="z-0" />
    </>
  );
}

export default NailCareBeauty;
