import banner_02 from "../assets/Banner2022_Bakery-min.png";
import banner_03 from "../assets/Banner2022_Consultant-min.png";
import banner_04 from "../assets/Banner2022_Doctor-min.png";
import banner_05 from "../assets/Banner2022_Mechanic-min.png";
import banner_06 from "../assets/Banner2022_Restaurant-min.png";
import banner_07 from "../assets/Banner2022_Retail-min.png";
import banner_08 from "../assets/Banner2022_Salon-min.png";
import banner_09 from "../assets/Banner2022_African-min.png";
import banner_10 from "../assets/Banner2022_Indian-min.png";
import banner_11 from "../assets/Banner2022_Group-min.png";
import BannerHomeResponsive from "../assets/BannerHomeResponsive.png";
import LandingPage_Banner_POS from "../assets/LandingPage_Banner_POS.png";
import homeV2Banner from "../assets/homeV2Banner.png";
import BannerHomeResponsiveAF from "../assets/BannerHomeResponsiveAF.png";
import bakerAF from "../assets/bakerAF.png";
import businessmanAF from "../assets/businessmanAF.png";
import doctorGirlAF from "../assets/doctorGirlAF.png";
import girlWorkerToolAF from "../assets/girlWorkerToolAF.png";
import boyFoodServerAF from "../assets/boyFoodServerAF.png";
import girlFoodServerAF from "../assets/girlFoodServerAF.png";
import menBarberAF from "../assets/menBarberAF.png";
import menChefAF from "../assets/menChefAF.png";
import menBoxDeliverAF from "../assets/menBoxDeliverAF.png";
import groupWorkersAF from "../assets/groupWorkersAF.png";
import boyMoneyAndGirlAF from "../assets/boyMoneyAndGirlAF.png";
import girl_money_boy from "../assets/girl_money_boy-min.png";
import money_boy_footer from "../assets/money_boy_footer-min.png";
import boyGirlMoneyFooter from "../assets/boyGirlMoneyFooter.png";
import invoiceQuotation_image_1 from "../assets/invoiceQuotation_image_1.png";
import menInvoiceAF from "../assets/menInvoiceAF.png";
import products from "../assets/Products_Inventory.png";
import invenoryManagementAF from "../assets/invenoryManagementAF.png";
import WhyDigLog_02 from "../assets/WhyDigLog_02.png";
import inventoryFeatureAF from "../assets/inventoryFeatureAF.png";
import invenotryGirlAF from "../assets/invenotryGirlAF.png";
import BusTypes_Retail_03 from "../assets/BusTypes_Retail_03.png";
import WhyDigLog_05 from "../assets/WhyDigLog_05.png";
import smartInventoryAF from "../assets/smartInventoryAF.png";
import mobilePOSAF from "../assets/mobilePOSAF.png";
import Products_Women from "../assets/Products_Women.png";
import products_mPOS from "../assets/Products_PointOfSale.png";
import Products_Man from "../assets/Products_Man.png";
import productAF from "../assets/productAF.png";
import Products_Image_02 from "../assets/Products_Image_02.png";
import salesGrowthAF from "../assets/salesGrowthAF.png";
import OnlineStore_01 from "../assets/OnlineStore_01.png";
import onlineStoreAF from "../assets/onlineStoreAF.png";
import Products_EStore from "../assets/Products_EStore.png";
import productOnlineStoreAF from "../assets/productOnlineStoreAF.png";
import OnlineStore_02 from "../assets/OnlineStore_02.png";
import checkoutOnlineStoreAF from "../assets/checkoutOnlineStoreAF.png";
import table_food from "../assets/table_food.png";
import sellofflineAF from "../assets/sellofflineAF.png";
import lady_tablet from "../assets/lady_tablet.png";
import syncProductsOnlineStoreAF from "../assets/syncProductsOnlineStoreAF.png";
import Products_HRIS from "../assets/Products_HRIS.png";
import hrmsAF from "../assets/hrmsAF.png";
import Products_Payroll from "../assets/Products_Payroll.png";
import payrollAF from "../assets/payrollAF.png";
import Products_Image_04 from "../assets/Products_Image_04.png";
import limitaccessAF from "../assets/limitaccessAF.png";
import Products_CashFlow from "../assets/Products_CashFlow.png";
import cashCreditBookAF from "../assets/cashCreditBookAF.png";
import cashFlowAF from "../assets/cashFlowAF.png";
import Products_Image_03 from "../assets/Products_Image_03.png";
import Products_SalesAndAnalytics from "../assets/Products_Sales&Analytics.png";
import salesAF from "../assets/salesAF.png";
import businessRetailBoy from "../assets/businessRetailBoy.svg";
import cashRetailAF from "../assets/cashRetailAF.png";
import retailOneAF from "../assets/retailOneAF.png";
import typeRetail_1 from "../assets/typeRetail_1.svg";
import typeRetail_2 from "../assets/typeRetail_2.svg";
import retailTwoAF from "../assets/retailTwoAF.png";
import typeRetail_3 from "../assets/typeRetail_3.svg";
import retailThreeAF from "../assets/retailThreeAF.png";
import typeRetail_4 from "../assets/typeRetail_4.svg";
import retailFourtAF from "../assets/retailFourtAF.png";
import typeRetail_5 from "../assets/typeRetail_5.svg";
import retailFiveAF from "../assets/retailFiveAF.png";
import typeRetail_6 from "../assets/typeRetail_6.svg";
import retailSevenAF from "../assets/retailSevenAF.png";
import typeRetail_7 from "../assets/typeRetail_7.svg";
import retailSixthAF from "../assets/retailSixthAF.png";
import proffesionalServiceGirlImage from "../assets/proffesionalServiceGirlImage.svg";
import proffesionalServiceAF from "../assets/proffesionalServiceAF.png";
import healthServiceAF from "../assets/healthServiceAF.png";
import hospitalBG from "../assets/hospitalBG.png";
import ps_section_3_1 from "../assets/ps_section_3_1.svg";
import doctorProffesionalAF from "../assets/doctorProffesionalAF.png";
import ps_section_3_2 from "../assets/ps_section_3_2.svg";
import nursePSAF from "../assets/nursePSAF.png";
import foodBevarageBoy from "../assets/foodBevarageBoy.svg";
import foodBevarageAF from "../assets/foodBevarageAF.png";
import fb_1 from "../assets/f&b_1.svg";
import fb_2 from "../assets/f&b_2.svg";
import fb_3 from "../assets/f&b_3.png";
import fb_4 from "../assets/f&b_4.svg";
import fb1AF from "../assets/fb1AF.png";
import fb3AF from "../assets/fb3AF.png";
import fb2AF from "../assets/fb2AF.png";
import fbAF4 from "../assets/fbAF4.png";
import man_fb from "../assets/man_fb.svg";
import FbWomanAF from "../assets/FbWomanAF.png";
import virtualProductGirl from "../assets/virtualProductGirl.svg";
import digitalProductsAF from "../assets/digitalProductsAF.png";
import girldRightHandRaise from "../assets/girldRightHandRaise.svg";
import whyDiglogMenBakeAF from "../assets/whyDiglogMenBakeAF.png";
import girlWithMotorcycle from "../assets/girlWithMotorcycle.svg";
import menDeliverAF from "../assets/menDeliverAF.png";
import girlLaptopFlower from "../assets/girlLaptopFlower.svg";
import boyLaptopAF from "../assets/boyLaptopAF.png";
import fivePerson from "../assets/fivePerson.svg";
import fivepersonAF from "../assets/fivepersonAF.png";
import moneyBoyFooterAF from "../assets/moneyBoyFooterAF.png";
import four_people from "../assets/four_people.png";
import fourPeopleAF from "../assets/fourPeopleAF.png";
import commission_girl_boy from "../assets/commission_girl_boy.png";
import commisionGirlBoyAF from "../assets/commisionGirlBoyAF.png";
import boy_girl_with_money from "../assets/boy_girl_with_money.png";
import girlBoyBakeAF from "../assets/girlBoyBakeAF.png";
import money_on_hand from "../assets/money_on_hand.png";
import moneyBankAF from "../assets/moneyBankAF.png";
import GetHelp_01 from "../assets/GetHelp_01.png";
import faqAF from "../assets/faqAF.png";
import logistics from "../assets/logistics.svg";
import logisticsAF from "../assets/logisticsAF.png";
import laptopCreditCard from "../assets/laptopCreditCard.svg";
import creditCardAF from "../assets/creditCardAF.png";
import girlBoyCard from "../assets/girlBoyCard.svg";
import girlBoyPaymentsAF from "../assets/girlBoyPaymentsAF.png";
import girlMegaphone from "../assets/girlMegaphone.png";
import boyMegaPhoneAF from "../assets/boyMegaPhoneAF.png";
import girlThinking from "../assets/girlThinking.png";
import boyThinkingAF from "../assets/boyThinkingAF.png";

const languageDynamicContents = [
  {
    page: "home",
    homesections: [
      {
        section: "swiper",
        items: [
          {
            key: "en",
            contents: [
              homeV2Banner,
              homeV2Banner,
              // BannerHomeResponsive,
              // banner_02,
              // banner_03,
              // banner_03,
              // banner_04,
              // banner_05,
              // banner_06,
              // banner_07,
              // banner_08,
              // banner_09,
              // banner_10,
              // banner_11,
            ],
          },
          {
            key: "af",
            contents: [
              BannerHomeResponsiveAF,
              bakerAF,
              businessmanAF,
              doctorGirlAF,
              girlWorkerToolAF,
              boyFoodServerAF,
              girlFoodServerAF,
              menBarberAF,
              menChefAF,
              menBoxDeliverAF,
              groupWorkersAF,
            ],
          },
        ],
      },
      {
        section: "home_section_3",
        items: [
          {
            key: "en",
            contents: [girl_money_boy],
          },
          {
            key: "af",
            contents: [boyMoneyAndGirlAF],
            classnames: "xl:h-[65%]",
          },
        ],
      },
      {
        section: "before_footer",
        items: [
          {
            key: "en",
            contents: [money_boy_footer],
          },
          {
            key: "af",
            contents: [boyGirlMoneyFooter],
          },
        ],
      },
    ],
  },
  {
    page: "invoicing_quoations",
    homesections: [
      {
        section: "first",
        items: [
          {
            key: "en",
            contents: [invoiceQuotation_image_1],
            classnames: "",
          },
          {
            key: "af",
            contents: [menInvoiceAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "inventory_management",
    homesections: [
      {
        section: "first_section",
        items: [
          {
            key: "en",
            contents: [products],
            classnames: "",
          },
          {
            key: "af",
            contents: [invenoryManagementAF],
            classnames: "",
          },
        ],
      },
      {
        section: "second_section",
        items: [
          {
            key: "en",
            contents: [WhyDigLog_02],
            classnames: "",
          },
          {
            key: "af",
            contents: [inventoryFeatureAF],
            classnames: "",
          },
        ],
      },
      {
        section: "third_section",
        items: [
          {
            key: "en",
            contents: [BusTypes_Retail_03],
            classnames: "",
          },
          {
            key: "af",
            contents: [invenotryGirlAF],
            classnames: "",
          },
        ],
      },
      {
        section: "fourth_section",
        items: [
          {
            key: "en",
            contents: [WhyDigLog_05],
            classnames: "",
          },
          {
            key: "af",
            contents: [smartInventoryAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "pos",
    homesections: [
      {
        section: "first_section",
        items: [
          {
            key: "en",
            contents: [Products_Women],
            classnames: "",
          },
          {
            key: "af",
            contents: [invenotryGirlAF],
            classnames: "",
          },
        ],
      },
      {
        section: "second_section",
        items: [
          {
            key: "en",
            contents: [products_mPOS],
            classnames: "",
          },
          {
            key: "af",
            contents: [mobilePOSAF],
            classnames: "",
          },
        ],
      },
      {
        section: "third_section",
        items: [
          {
            key: "en",
            contents: [products_mPOS],
            classnames: "",
          },
          {
            key: "af",
            contents: [productAF],
            classnames: "",
          },
        ],
      },
      {
        section: "fourth_section",
        items: [
          {
            key: "en",
            contents: [Products_Image_02],
            classnames: "",
          },
          {
            key: "af",
            contents: [salesGrowthAF],
            classnames: "mt-2",
          },
        ],
      },
    ],
  },
  {
    page: "onlineStore",
    homesections: [
      {
        section: "firstSection",
        items: [
          {
            key: "en",
            contents: [OnlineStore_01],
            classnames: "",
          },
          {
            key: "af",
            contents: [onlineStoreAF],
            classnames: "",
          },
        ],
      },
      {
        section: "secondSection",
        items: [
          {
            key: "en",
            contents: [Products_EStore],
            classnames: "",
          },
          {
            key: "af",
            contents: [productOnlineStoreAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSection",
        items: [
          {
            key: "en",
            contents: [OnlineStore_02],
            classnames: "",
          },
          {
            key: "af",
            contents: [checkoutOnlineStoreAF],
            classnames: "",
          },
        ],
      },
      {
        section: "fourthSectionFirstHalf",
        items: [
          {
            key: "en",
            contents: [table_food],
            classnames: "",
          },
          {
            key: "af",
            contents: [sellofflineAF],
            classnames: "",
          },
        ],
      },
      {
        section: "fourthSectionSecondHalf",
        items: [
          {
            key: "en",
            contents: [lady_tablet],
            classnames: "",
          },
          {
            key: "af",
            contents: [syncProductsOnlineStoreAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "employeePayrollManagement",
    homesections: [
      {
        section: "firstSection",
        items: [
          {
            key: "en",
            contents: [Products_HRIS],
            classnames: "",
          },
          {
            key: "af",
            contents: [hrmsAF],
            classnames: "",
          },
        ],
      },
      {
        section: "secondSection",
        items: [
          {
            key: "en",
            contents: [Products_Payroll],
            classnames: "",
          },
          {
            key: "af",
            contents: [payrollAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSection",
        items: [
          {
            key: "en",
            contents: [Products_Image_04],
            classnames: "",
          },
          {
            key: "af",
            contents: [limitaccessAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "cashCreditBook",
    homesections: [
      {
        section: "firstSection",
        items: [
          {
            key: "en",
            contents: [Products_CashFlow],
            classnames: "",
          },
          {
            key: "af",
            contents: [cashCreditBookAF],
            classnames: "",
          },
        ],
      },
      {
        section: "secondSection",
        items: [
          {
            key: "en",
            contents: [Products_Image_03],
            classnames: "",
          },
          {
            key: "af",
            contents: [cashFlowAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSection",
        items: [
          {
            key: "en",
            contents: [Products_SalesAndAnalytics],
            classnames: "",
          },
          {
            key: "af",
            contents: [salesAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "businessRetail",
    homesections: [
      {
        section: "firstSection",
        items: [
          {
            key: "en",
            contents: [businessRetailBoy],
            classnames: "",
          },
          {
            key: "af",
            contents: [cashRetailAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionOne",
        items: [
          {
            key: "en",
            contents: [typeRetail_1],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailOneAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionTwo",
        items: [
          {
            key: "en",
            contents: [typeRetail_2],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailTwoAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionThree",
        items: [
          {
            key: "en",
            contents: [typeRetail_3],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailThreeAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionFourth",
        items: [
          {
            key: "en",
            contents: [typeRetail_4],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailFourtAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionFifth",
        items: [
          {
            key: "en",
            contents: [typeRetail_5],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailFiveAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionSixth",
        items: [
          {
            key: "en",
            contents: [typeRetail_6],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailSevenAF],
            classnames: "",
          },
        ],
      },
      {
        section: "thirdSectionSeven",
        items: [
          {
            key: "en",
            contents: [typeRetail_7],
            classnames: "",
          },
          {
            key: "af",
            contents: [retailSixthAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "proffesionalService",
    homesections: [
      {
        section: "firstSection",
        items: [
          {
            key: "en",
            contents: [proffesionalServiceGirlImage],
            classnames: "",
          },
          {
            key: "af",
            contents: [proffesionalServiceAF],
            classnames: "",
          },
        ],
      },
      {
        section: "secondSection",
        items: [
          {
            key: "en",
            contents: [hospitalBG],
            classnames: "",
          },
          {
            key: "af",
            contents: [healthServiceAF],
            classnames: "h-12",
          },
        ],
      },
      {
        section: "thirdSection",
        items: [
          {
            key: "en",
            contents: [ps_section_3_1],
            classnames: "",
          },
          {
            key: "af",
            contents: [doctorProffesionalAF],
            classnames: "h-[95%]",
          },
        ],
      },
      {
        section: "thirdSectionTwo",
        items: [
          {
            key: "en",
            contents: [ps_section_3_2],
            classnames: "",
          },
          {
            key: "af",
            contents: [nursePSAF],
            classnames: "h-[95%]",
          },
        ],
      },
    ],
  },
  {
    page: "foodBevarages",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [foodBevarageBoy],
            classnames: "",
          },
          {
            key: "af",
            contents: [foodBevarageAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwoOne",
        items: [
          {
            key: "en",
            contents: [fb_1],
            classnames: "",
          },
          {
            key: "af",
            contents: [fb1AF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwoThree",
        items: [
          {
            key: "en",
            contents: [fb_3],
            classnames: "",
          },
          {
            key: "af",
            contents: [fb3AF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwoTwo",
        items: [
          {
            key: "en",
            contents: [fb_2],
            classnames: "",
          },
          {
            key: "af",
            contents: [fb2AF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwoFour",
        items: [
          {
            key: "en",
            contents: [fb_4],
            classnames: "",
          },
          {
            key: "af",
            contents: [fbAF4],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionThree",
        items: [
          {
            key: "en",
            contents: [man_fb],
            classnames: "",
          },
          {
            key: "af",
            contents: [FbWomanAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "digitalProducts",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [virtualProductGirl],
            classnames: "",
          },
          {
            key: "af",
            contents: [digitalProductsAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "whyDiglog",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [girldRightHandRaise],
            classnames: "",
          },
          {
            key: "af",
            contents: [whyDiglogMenBakeAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwo",
        items: [
          {
            key: "en",
            contents: [girlWithMotorcycle],
            classnames: "",
          },
          {
            key: "af",
            contents: [menDeliverAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionThree",
        items: [
          {
            key: "en",
            contents: [girlLaptopFlower],
            classnames: "",
          },
          {
            key: "af",
            contents: [boyLaptopAF],
            classnames: "",
          },
        ],
      },
      {
        section: "fivePersons",
        items: [
          {
            key: "en",
            contents: [fivePerson],
            classnames: "",
          },
          {
            key: "af",
            contents: [fivepersonAF],
            classnames: "",
          },
        ],
      },
      {
        section: "beforeFooter",
        items: [
          {
            key: "en",
            contents: [money_boy_footer],
            classnames: "",
          },
          {
            key: "af",
            contents: [moneyBoyFooterAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "partnerProgram",
    homesections: [
      {
        section: "sectionTwo",
        items: [
          {
            key: "en",
            contents: [four_people],
            classnames: "",
          },
          {
            key: "af",
            contents: [fourPeopleAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionThree",
        items: [
          {
            key: "en",
            contents: [commission_girl_boy],
            classnames: "",
          },
          {
            key: "af",
            contents: [commisionGirlBoyAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionFour",
        items: [
          {
            key: "en",
            contents: [boy_girl_with_money],
            classnames: "",
          },
          {
            key: "af",
            contents: [girlBoyBakeAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionFive",
        items: [
          {
            key: "en",
            contents: [money_on_hand],
            classnames: "",
          },
          {
            key: "af",
            contents: [moneyBankAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "getHelp",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [GetHelp_01],
            classnames: "",
          },
          {
            key: "af",
            contents: [faqAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "logisticsPartner",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [logistics],
            classnames: "",
          },
          {
            key: "af",
            contents: [logisticsAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "paymentPartners",
    homesections: [
      {
        section: "sectionOne",
        items: [
          {
            key: "en",
            contents: [laptopCreditCard],
            classnames: "",
          },
          {
            key: "af",
            contents: [creditCardAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionTwo",
        items: [
          {
            key: "en",
            contents: [girlBoyCard],
            classnames: "",
          },
          {
            key: "af",
            contents: [girlBoyPaymentsAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "lifetimeDeals",
    homesections: [
      {
        section: "modal2",
        items: [
          {
            key: "en",
            contents: [girlMegaphone],
            classnames: "",
          },
          {
            key: "af",
            contents: [boyMegaPhoneAF],
            classnames: "",
          },
        ],
      },
      {
        section: "sectionFour",
        items: [
          {
            key: "en",
            contents: [girlThinking],
            classnames: "",
          },
          {
            key: "af",
            contents: [boyThinkingAF],
            classnames: "",
          },
        ],
      },
    ],
  },
  {
    page: "pricing",
    homesections: [
      {
        section: "features",
        items: [
          {
            key: "en",
            contents: [
              "Inventory + POS + eStore",
              "Invoicing & Quotations - manage customer / supplier quotations & invoices",
              "Employee Management",
              "Cash & Credit book - track daily sale, purchases and credit transactions",
              "eStore - default ecommerce website",
              "Multiple locations inventory & Sales management",
              "Products & Services",
              "Roles -  manage staff member access to Inventory, POS and employee management functions",
              "24/7 support - support available when and where its required, beside self-help resources available",
              "Sales channels - Sell in-store, Online and on Social media",
              "Inventory locations - Assign inventory to retail stores, warehouses, or wherever you store products.",
              "Create custom domain or use existing",
              "Multiple Payment methods - availability varies by country",
              "Promotions & discount management",
              "Reports & Dashboards",
              "Third-party calculated shipping rates",
              "Use on Mobile (IOS & Android) or Web app (Tablet, desktop)",
              "Employee Self service portal",
              "Delivery Services - availability varies by country",
              "Shift management",
              "Time tracking & attendance management",
              "Employee payroll mgmt - varies by country",
              "Employee approvals management",
              "Multi-language support on eStore",
              "Customer management",
              "Supplier Management",
            ],
            classnames: "",
          },
          {
            key: "af",
            contents: [
              "Inventory & catalog management for finished Products, Services, Digital products & F&B",
              "POS - sell-in-person",
              "Online Store - custom ecommerce website",
              "Invoicing & Quotations - manage customer / supplier quotations & invoices",
              "Employee Management - manage shifts, time, payroll & approval",
              "Cash & Credit book - track daily sale, purchases and credit transactions",
              "Manage inventory, sales, purchases across different locations",
              "Support different type of businesses - Retail, Professional services, Rental services, Digital products, F&B, and more",
              "Guided user onboarding & support",
              "User Roles mgmt -  manage staff member access all functions",
              "Allow partial payments",
              "24/7 live support, self-help with 100s of how-to videos and articles and AI enabled Chat bot",
              "Sales channels - Sell in-person, Online or on Social media",
              "Create custom domain or use existing",
              "Multiple Payment methods supported - varies by country",
              "Data privacy & security built across all layers",
              "Promotions & discount management",
              "Realtime Reports & Dashboards",
              "Third-party calculated shipping rates",
              "Use on any device - Mobile (IOS & Android) or Web app (Tablet, desktop)",
              "Employee Self service portal",
              "Last mile delivery Services - availability varies by country",
              "Shift management ",
              "Time tracking & attendance management",
              "Employee payroll mgmt - varies by country",
              "Employee approvals management",
              "Multi-language support for Merchant app and Online store",
              "Customer management",
            ],
            classnames: "",
          },
        ],
      },
      {
        section: "allInOneFeatures",
        items: [
          {
            key: "en",
            contents: [
              {
                name: "Inventory + POS + eStore",
                value: true,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management",
                value: true,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "eStore - default ecommerce website",
                value: true,
              },
              {
                name: "Multiple locations inventory & Sales management",
                value: "Unlimited",
              },
              {
                name: "Products & Services",
                value: "Unlimited",
              },
              {
                name: "Roles -  manage staff member access to Inventory, POS and employee management functions",
                value: "Unlimited",
              },
              {
                name: "24/7 support - support available when and where its required, beside self-help resources available",
                value: true,
              },
              {
                name: "Sales channels - Sell in-store, Online and on Social media",
                value: true,
              },
              {
                name: "Inventory locations - Assign inventory to retail stores, warehouses, or wherever you store products.",
                value: "Unlimited",
              },
              {
                name: "Create custom domain or use existing",
                value: true,
              },
              {
                name: "Multiple Payment methods - availability varies by country",
                value: true,
              },
              {
                name: "Promotions & discount management",
                value: true,
              },
              {
                name: "Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: true,
              },
              {
                name: "Use on Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Delivery Services - availability varies by country",
                value: true,
              },
              {
                name: "Shift management",
                value: true,
              },
              {
                name: "Time tracking & attendance management",
                value: true,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: true,
              },
              {
                name: "Employee approvals management",
                value: true,
              },
              {
                name: "Multi-language support on eStore",
                value: true,
              },
              {
                name: "Customer management",
                value: true,
              },
              {
                name: "Supplier Management",
                value: true,
              },
            ],
            classnames: "",
          },
          {
            key: "af",
            contents: [
              {
                name: "Inventory & catalog management for finished Products, Services, Digital products & F&B",
                value: true,
              },
              {
                name: "POS - sell-in-person",
                value: true,
              },
              {
                name: "Online Store - custom ecommerce website ",
                value: true,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management - manage shifts, time, payroll & approval",
                value: true,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "Manage inventory, sales, purchases across different locations",
                value: "Unlimited",
              },
              {
                name: "Support different type of businesses - Retail, Professional services, Rental services, Digital products, F&B, and more",
                value: true,
              },
              {
                name: "Guided user onboarding & support",
                value: true,
              },
              {
                name: "User Roles mgmt -  manage staff member access all functions",
                value: true,
              },
              {
                name: "Allow partial payments",
                value: true,
              },
              {
                name: "24/7 live support, self-help with 100s of how-to videos and articles and AI enabled Chat bot",
                value: true,
              },
              {
                name: "Sales channels - Sell in-person, Online or on Social media",
                value: true,
              },
              {
                name: "Create custom domain or use existing",
                value: true,
              },
              {
                name: "Multiple Payment methods supported - varies by country",
                value: true,
              },
              {
                name: "Data privacy & security built across all layers",
                value: true,
              },
              {
                name: "Promotions & discount management",
                value: true,
              },
              {
                name: "Realtime Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: true,
              },
              {
                name: "Use on any device - Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Last mile delivery Services - availability varies by country",
                value: true,
              },
              {
                name: "Shift management",
                value: true,
              },
              {
                name: "Time tracking & attendance management",
                value: true,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: true,
              },
              {
                name: "Employee approvals management",
                value: true,
              },
              {
                name: "Multi-language support for Merchant app and Online store",
                value: true,
              },
              {
                name: "Customer management",
                value: true,
              },
            ],
            classnames: "",
          },
        ],
      },
      {
        section: "InventoryFeatures",
        items: [
          {
            key: "en",
            contents: [
              {
                name: "Inventory + POS + eStore",
                value: true,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management",
                value: false,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "eStore - default ecommerce website",
                value: true,
              },
              {
                name: "Multiple locations inventory & Sales management",
                value: "Unlimited",
              },
              {
                name: "Products & Services",
                value: "Unlimited",
              },
              {
                name: "Roles -  manage staff member access to Inventory, POS and employee management functions",
                value: "Unlimited",
              },
              {
                name: "24/7 support - support available when and where its required, beside self-help resources available",
                value: true,
              },
              {
                name: "Sales channels - Sell in-store, Online and on Social media",
                value: true,
              },
              {
                name: "Inventory locations - Assign inventory to retail stores, warehouses, or wherever you store products.",
                value: "Unlimited",
              },
              {
                name: "Create custom domain or use existing",
                value: true,
              },
              {
                name: "Multiple Payment methods - availability varies by country",
                value: true,
              },
              {
                name: "Promotions & discount management",
                value: true,
              },
              {
                name: "Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: true,
              },
              {
                name: "Use on Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Delivery Services - availability varies by country",
                value: true,
              },
              {
                name: "Shift management",
                value: false,
              },
              {
                name: "Time tracking & attendance management",
                value: false,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: false,
              },
              {
                name: "Employee approvals management",
                value: false,
              },
              {
                name: "Multi-language support on eStore",
                value: true,
              },
              {
                name: "Customer management",
                value: true,
              },
              {
                name: "Supplier Management",
                value: true,
              },
            ],
            classnames: "",
          },
          {
            key: "af",
            contents: [
              {
                name: "Inventory & catalog management for finished Products, Services, Digital products & F&B",
                value: true,
              },
              {
                name: "POS - sell-in-person",
                value: true,
              },
              {
                name: "Online Store - custom ecommerce website ",
                value: true,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management - manage shifts, time, payroll & approval",
                value: false,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "Manage inventory, sales, purchases across different locations",
                value: "Unlimited",
              },
              {
                name: "Support different type of businesses - Retail, Professional services, Rental services, Digital products, F&B, and more",
                value: true,
              },
              {
                name: "Guided user onboarding & support",
                value: true,
              },
              {
                name: "User Roles mgmt -  manage staff member access all functions",
                value: true,
              },
              {
                name: "Allow partial payments",
                value: true,
              },
              {
                name: "24/7 live support, self-help with 100s of how-to videos and articles and AI enabled Chat bot",
                value: true,
              },
              {
                name: "Sales channels - Sell in-person, Online or on Social media",
                value: true,
              },
              {
                name: "Create custom domain or use existing",
                value: true,
              },
              {
                name: "Multiple Payment methods supported - varies by country",
                value: true,
              },
              {
                name: "Data privacy & security built across all layers",
                value: true,
              },
              {
                name: "Promotions & discount management",
                value: true,
              },
              {
                name: "Realtime Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: true,
              },
              {
                name: "Use on any device - Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Last mile delivery Services - availability varies by country",
                value: true,
              },
              {
                name: "Shift management",
                value: false,
              },
              {
                name: "Time tracking & attendance management",
                value: false,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: false,
              },
              {
                name: "Employee approvals management",
                value: false,
              },
              {
                name: "Multi-language support for Merchant app and Online store",
                value: true,
              },
              {
                name: "Customer management",
                value: true,
              },
            ],
            classnames: "",
          },
        ],
      },
      {
        section: "HRMSFeatures",
        items: [
          {
            key: "en",
            contents: [
              {
                name: "Inventory + POS + eStore",
                value: false,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management",
                value: false,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "eStore - default ecommerce website",
                value: false,
              },
              {
                name: "Multiple locations inventory & Sales management",
                value: false,
              },
              {
                name: "Products & Services",
                value: false,
              },
              {
                name: "Roles -  manage staff member access to Inventory, POS and employee management functions",
                value: "Unlimited",
              },
              {
                name: "24/7 support - support available when and where its required, beside self-help resources available",
                value: true,
              },
              {
                name: "Sales channels - Sell in-store, Online and on Social media",
                value: true,
              },
              {
                name: "Inventory locations - Assign inventory to retail stores, warehouses, or wherever you store products.",
                value: false,
              },
              {
                name: "Create custom domain or use existing",
                value: false,
              },
              {
                name: "Multiple Payment methods - availability varies by country",
                value: false,
              },
              {
                name: "Promotions & discount management",
                value: false,
              },
              {
                name: "Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: false,
              },
              {
                name: "Use on Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Delivery Services - availability varies by country",
                value: false,
              },
              {
                name: "Shift management",
                value: true,
              },
              {
                name: "Time tracking & attendance management",
                value: true,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: true,
              },
              {
                name: "Employee approvals management",
                value: true,
              },
              {
                name: "Multi-language support on eStore",
                value: false,
              },
              {
                name: "Customer management",
                value: false,
              },
              {
                name: "Supplier Management",
                value: false,
              },
            ],
            classnames: "",
          },
          {
            key: "af",
            contents: [
              {
                name: "Inventory & catalog management for finished Products, Services, Digital products & F&B",
                value: false,
              },
              {
                name: "POS - sell-in-person",
                value: false,
              },
              {
                name: "Online Store - custom ecommerce website ",
                value: false,
              },
              {
                name: "Invoicing & Quotations - manage customer / supplier quotations & invoices",
                value: true,
              },
              {
                name: "Employee Management - manage shifts, time, payroll & approval",
                value: false,
              },
              {
                name: "Cash & Credit book - track daily sale, purchases and credit transactions",
                value: true,
              },
              {
                name: "Manage inventory, sales, purchases across different locations",
                value: "NA",
              },
              {
                name: "Support different type of businesses - Retail, Professional services, Rental services, Digital products, F&B, and more",
                value: true,
              },
              {
                name: "Guided user onboarding & support",
                value: true,
              },
              {
                name: "User Roles mgmt -  manage staff member access all functions",
                value: true,
              },
              {
                name: "Allow partial payments",
                value: false,
              },
              {
                name: "24/7 live support, self-help with 100s of how-to videos and articles and AI enabled Chat bot",
                value: true,
              },
              {
                name: "Sales channels - Sell in-person, Online or on Social media",
                value: false,
              },
              {
                name: "Create custom domain or use existing",
                value: false,
              },
              {
                name: "Multiple Payment methods supported - varies by country",
                value: false,
              },
              {
                name: "Data privacy & security built across all layers",
                value: true,
              },
              {
                name: "Promotions & discount management",
                value: false,
              },
              {
                name: "Realtime Reports & Dashboards",
                value: "Standard",
              },
              {
                name: "Third-party calculated shipping rates",
                value: false,
              },
              {
                name: "Use on any device - Mobile (IOS & Android) or Web app (Tablet, desktop)",
                value: true,
              },
              {
                name: "Employee Self service portal",
                value: true,
              },
              {
                name: "Last mile delivery Services - availability varies by country",
                value: false,
              },
              {
                name: "Shift management",
                value: false,
              },
              {
                name: "Time tracking & attendance management",
                value: false,
              },
              {
                name: "Employee payroll mgmt - varies by country",
                value: false,
              },
              {
                name: "Employee approvals management",
                value: false,
              },
              {
                name: "Multi-language support for Merchant app and Online store",
                value: false,
              },
              {
                name: "Customer management",
                value: false,
              },
            ],
            classnames: "",
          },
          {
            section: "pricingPaypal",
            items: [
              {
                key: "en",
                contents: [
                  "Albania",
                  "Algeria",
                  "Andorra",
                  "Antigua & Barbuda",
                  "Argentina",
                  "Australia",
                  "Austria",
                  "Bahamas",
                  "Bahrain",
                  "Barbados",
                  "Belgium",
                  "Belize",
                  "Bermuda",
                  "Bosnia And Herzegovina",
                  "Botswana",
                  "Brazil",
                  "Bulgaria",
                  "Canada",
                  "Cayman Islands",
                  "Chile",
                  "China",
                  "Colombia",
                  "Costa Rica",
                  "Croatia",
                  "Cyprus",
                  "Czech Republic",
                  "Denmark",
                  "Dominica",
                  "Dominican Republic",
                  "Ecuador",
                  "Egypt",
                  "El Salvador",
                  "Estonia",
                  "Faroe Islands",
                  "Finland",
                  "Fiji",
                  "France",
                  "French Guiana",
                  "French Polynesia",
                  "Georgia",
                  "Germany",
                  "Gibraltar",
                  "Greece",
                  "Greenland",
                  "Grenada",
                  "Guadeloupe",
                  "Guatemala",
                  "Honduras",
                  "Hong Kong SAR China",
                  "Hungary",
                  "Iceland",
                  "India",
                  "Indonesia",
                  "Ireland",
                  "Italy",
                  "Jamaica",
                  "Japan",
                  "Jordan",
                  "Kazakhstan",
                  "Kenya",
                  "Kuwait",
                  "Latvia",
                  "Lesotho",
                  "Liechtenstein",
                  "Lithunia",
                  "Luxembourg",
                  "Malawi",
                  "Malaysia",
                  "Martinique",
                  "Mauritius",
                  "Mexico",
                  "Moldova",
                  "Monaco",
                  "Morocco",
                  "Mozambique",
                  "Netherlands",
                  "New Caledonia",
                  "New Zealand",
                  "Nicaragua",
                  "Norway",
                  "Oman",
                  "Palau",
                  "Panama",
                  "Peru",
                  "Philippines",
                  "Poland",
                  "Portugal",
                  "Qatar",
                  "Reunion",
                  "Romania",
                  "Russia",
                  "San Marino",
                  "Saudi Arabia",
                  "Senegal",
                  "Serbia",
                  "Seychelles",
                  "Singapore",
                  "Slovakia",
                  "Slovenia",
                  "South Africa",
                  "South Korea",
                  "Spain",
                  "St. Kitts And Nevis",
                  "St. Lucia",
                  "Swaziland",
                  "Sweden",
                  "Switzerland",
                  "Taiwan",
                  "Thailand",
                  "Trinidad And Tobago",
                  "Turks And Caicos Islands",
                  "United Arab Emirates",
                  "United Kingdom",
                  "United States",
                  "Uruguay",
                  "Venezuela",
                  "Vietnam",
                ],
                classnames: "",
              },
            ],
          },
        ],
      },
    ],
  },
];

// format
// {
//     page: "",
//     homesections: [
// {
//   section: "swiper",
//   items: [
//     {
//       key: "",
//       contents: [],
//       classnames: "",
//     },
//   ],
// },
//     ],
//   },

// {selectContentsBasedOnLanguage('inventory_management','first_section',activeLanguage).contents.map((content) =>(

//   ))}
export const selectContentsBasedOnLanguage = (
  pageName,
  sectionName,
  languageKey = "en"
) => {
  console.log(`first ${languageKey}`);

  const availableLangs = [
    "en",
    "id",
    "es",
    "fr",
    "ms",
    "jp",
    "cn",
    "vi",
    "hi",
    "bn",
    "af",
  ];
  if (availableLangs.indexOf(languageKey) == -1) {
    languageKey = "en";
  }
  console.log(`after index of ${languageKey}`);
  let ActivelanguageKey = languageKey;
  let indexOfActivePage = null;
  let indexOfActiveSection = null;
  let indexOfActiveItem = null;

  languageDynamicContents.map((lang, idx) => {
    if (lang.page == pageName) {
      indexOfActivePage = idx;
    }
  });

  if (indexOfActivePage != null) {
    languageDynamicContents[indexOfActivePage].homesections.map(
      (lang, idx2) => {
        if (lang.section == sectionName) {
          indexOfActiveSection = idx2;
        }
      }
    );
  }

  if (indexOfActiveSection != null) {
    languageDynamicContents[indexOfActivePage].homesections[
      indexOfActiveSection
    ].items.map((item, idx3) => {
      if (item.key == ActivelanguageKey) {
        indexOfActiveItem = idx3;
      }
    });
  }

  return indexOfActiveItem != null
    ? languageDynamicContents[indexOfActivePage].homesections[
        indexOfActiveSection
      ].items[indexOfActiveItem]
    : languageDynamicContents[indexOfActivePage].homesections[
        indexOfActiveSection
      ].items[0];
};
