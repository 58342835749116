import React from "react";
import ThreeBigCards from "../components/sections/ThreeBigCardsSection";
import BigImageBannerSection from "../components/sections/BigImageBannerSection";
import TwoColumnTable from "../components/sections/TwoColumnTable";
import ReceiveAssistance from "../components/sections/ReceiveAssistance";
import FrequentlyAskedQuestionsComponent from "./FrequentlyAskedQuestionsComponent";
import Footer from "../components/Footer";
import Header from "../components/Header";
import i18n from "../i18n";
import ThreeBigCards1 from "../assets/threebigcards_retail_1.png";
import ThreeBigCards2 from "../assets/threebigcards_retail_2.png";
import ThreeBigCards3 from "../assets/threebigcards_retail_3.png";
import BigImageBannerRetail from "../assets/bigimagebanner_retail.png";
import BigImageBannerIcon1 from "../assets/bigimageicon_retail_1.png";
import BigImageBannerIcon2 from "../assets/bigimageicon_retail_2.png";
import BigImageBannerIcon3 from "../assets/bigimageicon_retail_3.png";
import retailBannerSrc from "../assets/retail-banner.png";
import BannerSection from "../components/BannerSection";

const RetailV2 = () => {
  const { t } = i18n;

  const askedQUestions = [
    {
      question: t("Is DizLog POS really free?"),
      answer: t(
        "Dizlog POS is Free at the first 14 days from your sign up date. To enjoy Dizlog POS further, check out our pricing plans here. <a href='https://dizlog.com/pricing' target='_blank' className='text-orange-600'>https://dizlog.com/pricing</a>"
      ),
      active: false,
    },
    {
      question: t("How to use DizLog POS system?"),
      answer: t(
        "To use Dizlog POS System.<br/><br/>1.Open Web App or Download the Dizlog App.<br/>2.Sign in or Create a new Account.<br/>3.Open POS feature and start processing orders<br/>If you are using Mobile version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7033797-how-to-use-the-pos-in-mobile-app</a><br/> If you are using Web App version click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6886533-how-to-use-the-pos-in-web-app</a>"
      ),
      active: false,
    },
    {
      question: t("Does DizLog work offline?"),
      answer: t(
        "YES! Offline Web POS is a feature that allows merchants to continue operating their point-of-sale system, even when there is no internet connection available.<br/><br/>With Offline Web POS, you can continue to process cash transactions and keep your business running smoothly, without interruption. Click Here to learn how to use Dizlog POS offline. <a href='https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/6933416-how-offline-pos-works-with-mobile-and-tablets</a>"
      ),
      active: false,
    },
    {
      question: t("What kind of hardware can work with DizLog?"),
      answer: t(
        "Dizlog software is compatible with a wide range of hardware devices, including computers, smartphones, and tablets. Whether you prefer to work on a desktop computer, stay productive on your phone, or enjoy the flexibility of a tablet, our software seamlessly adapts to your preferred device. With Dizlog, you can leverage the power of our software solution on any hardware device that suits your needs, providing you with the freedom to work and manage your business efficiently wherever you are.<br/><br/>Open WebApp thru - <a className='text-orange-600 ' href='https://web.dizlog.com/' target='_blank'>https://web.dizlog.com/</a><br/>or download the app via playstore, appstore or appgallery"
      ),
      active: false,
    },
    {
      question: t("Does DizLog have the functionality of loyalty cards?"),
      answer: t(
        "Yes, with Dizlog Loyalty Program We can encourage customers to return to our business and make Repeat purchases. This program offers Rewards and incentives to customers who make purchases or engage with our Business in certain ways.<br/><br/>Click here to learn more. <a href='https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards' target='_blank' className='text-orange-600'>https://helpcenter.dizlog.com/en/articles/7193262-dizlog-loyalty-program-and-rewards</a>"
      ),
      active: false,
    },
  ];

  const threeBigCardsData = {
    title: t("Synchronize Sales across all Platforms."),
    items: [
      {
        image: ThreeBigCards1,
        text: t(
          "Control inventory, sales, and additional functions utilizing hardware and a point-of-sale system tailored for retail."
        ),
      },
      {
        image: ThreeBigCards2,
        text: t(
          "Allow customers to make payments using card, cash, AfterPay, Apple Pay, and other methods."
        ),
      },
      {
        image: ThreeBigCards3,
        text: t(
          "Establish a personalized online storefront for handling orders, pickups, and deliveries."
        ),
      },
    ],
  };

  const twoColumnTableData = {
    title: t("Streamline your Daily Routine"),
    description: t(
      "Efficiently oversee and optimize operations across various locations, sales channels, and staff to enhance productivity and profitability."
    ),
    headers: [t("The objective of your business"), t("Solving it with ")],
    rows: [
      {
        objective: t("Reach Customers"),
        solution: [
          t("Expand your income sources by exploring new avenues."),
          t(
            "Monitor profit margins using inventory management and reporting functions."
          ),
          t(
            "Sync orders, items, and inventory seamlessly from your POS to your website."
          ),
        ],
      },
      {
        objective: t("Connect with Customers"),
        solution: [
          t("Retain loyal patrons and entice fresh clientele."),
          t(
            "Provide versatile payment choices, including Afterpay for immediate purchases with deferred payments."
          ),
        ],
      },
      {
        objective: t("Handle Financial Flow."),
        solution: [
          t("Access your earnings instantly with."),
          t(
            "Automatically allocate a portion of each sale for taxes or savings.Receive loan opportunities based on your card sales."
          ),
        ],
      },
      {
        objective: t("Sophisticated Analytics"),
        solution: [
          t(
            "Utilize integrated data syncing to analyze your business performance."
          ),
          t(
            "Access easily understandable smart reports to guide your strategic decisions."
          ),
        ],
      },
      {
        objective: t("Supervise your Staff"),
        solution: [
          t(
            "Manage labor expenses efficiently through integrated staff management."
          ),
          t("Automatically calculate tips and commissions."),
        ],
      },
    ],
  };

  const bigImageBannerData = {
    title: t("Access Smarter Inventory Tools"),
    description: t(
      "Utilize streamlined data for informed decision-making. Efficiently manage inventory, customize catalogs, and receive real-time stock reports to ensure you never miss a sale."
    ),
    image: BigImageBannerRetail,
    buttons: [
      {
        text: t("The shipment has been delivered"),
        icon: BigImageBannerIcon1,
      },
      {
        text: t("Add to POS 'New Arrivals'"),
        icon: BigImageBannerIcon2,
      },
      {
        text: t("Available items"),
        icon: BigImageBannerIcon3,
      },
    ],
  };

  return (
    <>
      <Header i18n={i18n} />
      <BannerSection
        title={t("Retail")}
        imgSrc={retailBannerSrc}
        description={t(
          "DizLog POS is designed to elevate your retail business by simplifying sales, inventory, and customer management. Its user-friendly interface enables quick transactions, reducing wait times and enhancing the shopping experience. Real-time inventory tracking ensures you always know what’s in stock, preventing overstocking or shortages. Additionally, DizLog POS provides detailed sales analytics, helping you identify trends and make informed decisions to boost profitability and streamline operations."
        )}
        reqDemoPath={"https://meetings.hubspot.com/support-diglog"}
      />

      <ThreeBigCards hasNav data={threeBigCardsData} />
      <BigImageBannerSection data={bigImageBannerData} />
      <TwoColumnTable data={twoColumnTableData} />
      <ReceiveAssistance />
      <FrequentlyAskedQuestionsComponent askedQUestions={askedQUestions} />
      <Footer />
    </>
  );
};

export default RetailV2;
