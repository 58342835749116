import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const detectionOptions = {
    // order and from where user language should be detected
    order: ['path'],
    lookupFromPathIndex: 0,
    caches: [],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: detectionOptions,
        debug: process.env.NODE_ENV === 'development' ? true : false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;