import React from 'react'
import './SimpleForm.css'
import Search from '../assets/Search.svg'
import searchOrange from '../assets/searchOrange.png'

function SimpleForm({placeholder, button, search}) {
  return (
    <form className="w-[290px] xl:w-[371px] lg:w-[371px] md:w-[371px] sm:w-[371px] bg-white border-2 border-[#F09000] h-[46px] rounded-md flex items-center mt-4">
      <input placeholder={placeholder} onChange={(e)=>search(e)} className='w-full flex items-center justify-center pb-1'/>
      <button className="  pr-4 flex justify-center items-center "><img src={searchOrange} alt="search icon" className='h-[19.5px] w-auto' /></button>
    </form>
  )
}

export default SimpleForm
