import React from 'react'
import clsx from 'clsx'

const BigImageBannerSection = ({data}) => {
  return (
    <section className='px-4 py-8 bg-[#DEC6B0] flex flex-col items-center'>
      <div className='max-w-4xl flex flex-col gap-6'>
        <div className='flex flex-col sm:flex-row gap-8'>
          <div className='text-[#00215D] font-bold text-xl w-full md:w-1/2 font-[Raleway]'>
            {data.title}
          </div>
          <div className='w-full md:w-1/2 text-[#6B6B6B] font-normal font-[Roboto] text-xs'>
            {data.description}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row gap-8'>
          <img src={data.image} alt="big-banner" className='w-full md:w-1/2 h-auto' />
          <div className='w-full md:w-1/2 flex flex-col justify-center items-center gap-3'>
            {data.buttons.map((button, index) => (
              <div 
                key={index} 
                className={clsx('flex flex-row gap-2 items-center justify-center w-full lg:w-[70%] rounded-md p-2 drop-shadow-md', index === data.buttons.length-1 ? "bg-[#F09000]" : "bg-white")}
              >
                <img src={button.icon} alt="icon" className='w-6 h-6' />
                <span className={clsx('font-bold font-[Roboto] text-sm', index === data.buttons.length-1 ? "text-white" : "text-primary-900")}>
                  {button.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BigImageBannerSection